import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { lookup } from "mime-types";
import env from "src/core/env";
import { makeId } from "src/core/utilities";

export function uploadFilesToString(files: UploadFile[]) {
  return files
    .map<string | undefined>((file) =>
      file.response && file.status !== "error"
        ? `/file/${file.response[0]?.publicId}${file.response[0]?.fileExtension}`
        : file.url
        ? "/" + file.url.replace(env.API_CDN_URL.replace(/['"]+/g, ""), "")
        : undefined
    )
    .filter((url) => url !== undefined)
    .join(",");
}

export function fileStringsToUploadFile(fileStrings: string): UploadFile[] {
  const cdnUrl = env.API_CDN_URL.replace(/['"]+/g, "");

  return fileStrings
    .split(",")
    .filter((image) => image !== "")
    .map<UploadFile>((fileUrl) => {
      const name = fileUrl.split("/").pop() || "";
      const isStartHttps = fileUrl.startsWith("https:");

      return {
        name,
        uid: makeId(12),
        url: isStartHttps
          ? fileUrl
          : (cdnUrl + fileUrl.replace(cdnUrl + "file/", "")).replace(
              "//file",
              "/file"
            ),
        originFileObj: {
          type: lookup(fileUrl) || "",
        } as RcFile,
        size: 0,
        type: lookup(fileUrl) || "",
        status: "done",
      };
    });
}

export function downloadFile(fileContent: any, fileName: string) {
  const url = window.URL.createObjectURL(fileContent);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function openFile(fileContent: any) {
  const url = window.URL.createObjectURL(fileContent);

  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function joinImageValues(files: UploadFile<any>[]) {
  if (files.length > 0) {
    const imageStrings = uploadFilesToString(files || []).split(",");
    return {
      image1: imageStrings[0] || "",
      image2: imageStrings[1] || "",
      image3: imageStrings[2] || "",
      images: undefined,
    };
  }
}
