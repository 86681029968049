import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { AllParentFieldsResponse } from "src/models/directorys/directory";
import { getAllDeliveryReportRequest } from "src/models/phongs/DeliveryReport";
import { IPlan } from "src/models/phongs/PlanRepost";
import { DeleteRequest } from "src/models/Request";

class PlanReportRepository extends Repository {
  protected basePath = "/api/cms-service/plan-report";

  constructor() {
    super(env.API_CMS_URL);
  }

  getAll(params: getAllDeliveryReportRequest) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "/all",
      params,
    });
  }

  getDetail(id: string) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: `/${id}`,
    });
  }

  createPlanReport(params: IPlan) {
    return this.request({
      method: "POST",
      url: "",
      data: params,
    });
  }

  exportPlanReport(id: string) {
    return this.request({
      method: "POST",
      url: `/${id}/export`,
    });
  }

  deletePlanReport(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `/${param.id}`,
    });
  }

  updatePlanReport(params: IPlan) {
    return this.request({
      method: "PUT",
      url: "",
      data: params,
    });
  }
}
export const planReportRepository = new PlanReportRepository();
