import { useContext } from "react";
import {
  LayoutConfigContext,
  LayoutConfigContextType,
} from "src/layouts/Dashboard";

const useLayoutConfig = () => {
  const context = useContext<LayoutConfigContextType | undefined>(
    LayoutConfigContext
  );

  if (context === undefined) {
    throw new Error("useLayoutConfig must be used in LayoutConfigProvider");
  }

  return context;
};

export default useLayoutConfig;
