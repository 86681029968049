import {
  DownloadOutlined,
  EyeOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Space,
  TablePaginationConfig,
  Tooltip,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, DatePicker, Row, Table } from "src/components";
import DebounceInput from "src/components/DebounceInput";
import {
  OptionType,
  SelectFetchFunc,
  SelectFetchResponse,
} from "src/components/Select";
import Permissions from "src/constants/permessions";
import env from "src/core/env";
import {
  useDidUpdate,
  useLoading,
  useProgressMessage,
  useQuery,
  useToggle,
} from "src/core/hooks";
import { PrivateView } from "src/core/permission/PrivateView";
import usePermissions from "src/core/permission/usePermissions";
import { ReduxObservableModuleReducer } from "src/core/redux/ReduxObservableModule";
import useLayoutConfig from "src/hooks/useLayoutConfig";
import {
  DocumentRequestGetList,
  DocumentResponse,
  ImportRequest,
  ImportResponse,
  ListDocumentRequest,
  ListDocumentResponse,
  ListExportDocument,
} from "src/models/Doucment";
import { statusArchives } from "src/models/Request";
import PreservationOfRecordDocumentFormMenu from "src/pages/screen/PreservationOfRecord/Create/Document/components/FromInMenu";
import PreservationOfRecordDocumentQuickForm from "src/pages/screen/PreservationOfRecord/Create/Document/components/QuickForm";
import { RootReducer } from "src/redux";
import documentModule from "src/redux/modules/document";
import { directorysRepository } from "src/repositories/DirectorysRepository";
import { FULL_DATE } from "src/utilities/dateFormatHelper";
import { downloadFile } from "src/utilities/fileHelpers";
const isRoleAdmin = localStorage.getItem("isRoleAdmin");
const isAdmin = isRoleAdmin !== null;
interface ListPreservationOfRecordDocumentProps {
  storageTimeTypeFilter?: number;
  documentReducer: ReduxObservableModuleReducer;
  profileReducer: ReduxObservableModuleReducer;
  fetchListDocumentRequest: (request: any) => void;
  deleteDocument: (request: string) => void;
  downloadFiles: (request: string) => void;
  importDocument: (request: ImportRequest) => void;
  downloadImportFileTemplate: () => void;
  canEdit?: boolean;
  exportDocument: (payload: ListExportDocument) => void;
}

const mapStateToProps = (state: RootReducer) => ({
  documentReducer: state.document,
  profileReducer: state.profileModule,
});

const mapDispatchToProps = {
  fetchListDocumentRequest: documentModule.actions.documentGetList.start,
  deleteDocument: documentModule.actions.delete.start,
  importDocument: documentModule.actions.import.start,
  downloadImportFileTemplate:
    documentModule.actions.downloadImportFileTemplate.start,
  downloadFiles: documentModule.actions.downloadFiles.start,
  exportDocument: documentModule.actions.export.start,
};

const ListDocumentBased = ({
  fetchListDocumentRequest,
  documentReducer,
  deleteDocument,
  downloadImportFileTemplate,
  downloadFiles,
  // importDocument,
  exportDocument,
  storageTimeTypeFilter,
  canEdit = false,
}: ListPreservationOfRecordDocumentProps) => {
  const { can } = usePermissions();
  const [documentId, setDocumentId] = useState<string>();
  const [modalState, setModalState] = useState<"list" | "form" | "quick-form">(
    "list"
  );
  const { state: modalCreate, toggle: toggleModalCreate } = useToggle();
  // Handle filter form
  const [filterForm] = useForm<
    Omit<ListDocumentRequest, "skipCount" | "maxResultCount">
  >();
  const [preservationOfRecordId] = useState<string>();
  const { changeLayoutConfig } = useLayoutConfig();
  const phongSelected = useSelector(
    (state: RootReducer) => state.phongApp.phongSelected
  );

  // Handle filter data
  const { query, setQuery, setQueryParam } = useQuery<
    DocumentRequestGetList & {
      documentModalState: string;
      documentId: string;
    }
  >({
    documentModalState: "list",
    documentId: "",
    skipCount: "0",
    maxResultCount: env.DEFAULT_PAGE_SIZE.toString(),
    ArchivesIdFilter: phongSelected?.id ? String(phongSelected?.id) : undefined,
  });

  // Handle table pagination
  const [
    tablePagination,
    setTablePagination,
  ] = useState<TablePaginationConfig>();

  const handleTablePaginationChange = useCallback(
    (page: number, pageSize?: number) => {
      const size = pageSize || parseInt(query.maxResultCount);
      setQueryParam("skipCount", (page - 1) * size);
      setQueryParam("maxResultCount", size);
    },
    [query]
  );

  useEffect(() => {
    setTablePagination({
      ...tablePagination,
      current:
        Math.floor(
          parseInt(query.skipCount) /
            (parseInt(query.maxResultCount) || env.DEFAULT_PAGE_SIZE)
        ) + 1,
      pageSize: parseInt(query.maxResultCount),
    });
  }, [query]);

  const handleFilterForm = useCallback(
    (
      _,
      values: Omit<DocumentRequestGetList, "skipCount" | "maxResultCount">
    ) => {
      const tempFromTime = filterForm.getFieldValue("fromTime");
      console.log("tempFromTime", tempFromTime);
      console.log("values.fromTime", values.ArchivesIdFilter);
      setQuery({
        ...query,
        skipCount: "0",
        fromTime:
          values.fromTime && typeof values.fromTime === "object"
            ? values.fromTime.format("YYYY-MM-DD")
            : undefined,
        filter: values.filter,
        ArchivesIdFilter: query.ArchivesIdFilter,
        documentTypeFilter: values.documentTypeFilter
          ? String(values?.documentTypeFilter)
          : undefined,
      });
    },
    [query]
  );

  useEffect(() => {
    setQuery((value) => ({
      ...value,
      ArchivesIdFilter: phongSelected?.id
        ? String(phongSelected?.id)
        : undefined,
    }));
  }, [phongSelected]);

  // useMount(() => {
  //   filterForm.setFieldsValue({
  //     fromTime: query.fromTime && dayjs(query.fromTime),
  //     filter: query.filter,
  //   });
  // });

  // Handle fetch list
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);

  const loading = useMemo(
    () =>
      documentReducer.state ===
      documentModule.reducerStates.documentGetList.processing,
    [documentReducer.state]
  );

  const fetchListDocuments = useCallback(() => {
    const tempDocumentTypeFilter = filterForm.getFieldValue(
      "documentTypeFilter"
    );
    const tempFilter = filterForm.getFieldValue("filter");
    const tempFromTime = filterForm.getFieldValue("fromTime");
    fetchListDocumentRequest({
      fromTime:
        tempFromTime && typeof tempFromTime === "object"
          ? tempFromTime.format("YYYY-MM-DD")
          : undefined,
      filter: tempFilter,
      skipCount: parseInt(query.skipCount),
      maxResultCount: parseInt(query.maxResultCount),
      documentTypeFilter: tempDocumentTypeFilter,
      ArchivesIdFilter: query.ArchivesIdFilter,
      StorageTimeTypeFilter: storageTimeTypeFilter,
    });
  }, [query, storageTimeTypeFilter]);

  const reloadListDocument = useCallback(() => {
    if (parseInt(query.skipCount, 10) === 0) {
      fetchListDocuments();
    } else {
      setQueryParam("skipCount", 0);
    }
  }, [query]);

  useEffect(() => {
    fetchListDocuments();
  }, [query]);

  useDidUpdate(() => {
    if (
      documentReducer.state ===
      documentModule.reducerStates.documentGetList.success
    ) {
      const {
        items,
        totalCount,
      } = documentReducer.data as ListDocumentResponse;
      setDocuments(items);
      setTablePagination({
        ...tablePagination,
        total: totalCount,
      });
    }
  }, [documentReducer.state]);

  // Handle delete document
  const { startLoading, stopLoading } = useLoading();
  const {
    startProgress,
    progressSuccess,
    progressFail,
    destroyProgress,
  } = useProgressMessage();
  const handleDeleteDocument = useCallback((id: string) => {
    deleteDocument(id);
  }, []);

  useDidUpdate(() => {
    switch (documentReducer.state) {
      case documentModule.reducerStates.delete.processing:
        startProgress("Đang xóa mục lục văn bản...");
        startLoading();
        break;
      case documentModule.reducerStates.delete.success:
        progressSuccess("Xóa mục lục văn bản thành công!");
        stopLoading();
        fetchListDocuments();
        break;

      case documentModule.reducerStates.delete.failed:
        destroyProgress();
        stopLoading();
        break;
    }
  }, [documentReducer.state]);

  // Handle download document
  const handleDownloadDocumentFiles = useCallback((id: string) => {
    return () => {
      downloadFiles(id);
    };
  }, []);

  useDidUpdate(() => {
    if (
      documentReducer.state ===
      documentModule.reducerStates.downloadFiles.processing
    ) {
      startProgress("Đang tải file...");
      startLoading();
    } else if (
      documentReducer.state ===
      documentModule.reducerStates.downloadFiles.success
    ) {
      progressSuccess("Tải file thành công!");
      stopLoading();

      downloadFile(
        documentReducer.data.fileContent,
        documentReducer.data.fileName
      );
    } else if (
      documentReducer.state ===
      documentModule.reducerStates.downloadFiles.failed
    ) {
      progressFail("Tải file thất bại!");
      stopLoading();
    }
  }, [documentReducer.state]);

  // Handle export document
  const handleExportDocument = useCallback(() => {
    console.log({
      PreservationOfRecordId: preservationOfRecordId,
      Filter: query.filter,
      FromTime: query.fromTime,
    });
    exportDocument({
      ArchivesIdFilter: query.ArchivesIdFilter,
      PreservationOfRecordId: preservationOfRecordId,
      Filter: query.filter,
      FromTime: query.fromTime,
    });
  }, [preservationOfRecordId, query]);

  useDidUpdate(() => {
    if (
      documentReducer.state === documentModule.reducerStates.export.processing
    ) {
      startProgress("Đang tải file...");
      startLoading();
    } else if (
      documentReducer.state === documentModule.reducerStates.export.success
    ) {
      progressSuccess("Tải file thành công!");
      stopLoading();

      downloadFile(
        documentReducer.data.fileContent,
        documentReducer.data.fileName
      );
    } else if (
      documentReducer.state === documentModule.reducerStates.export.failed
    ) {
      progressFail("Tải file thất bại!");
      stopLoading();
    }
  }, [documentReducer.state]);

  // Handle download import file template
  const handleDownloadImportFileTemplate = useCallback(() => {
    downloadImportFileTemplate();
  }, []);
  //   const handleImport = useCallback(
  //     (info: UploadChangeParam) => {
  //       const typeFile = info.file.name.indexOf(".xlsx");
  //       if (typeFile >= 0) {
  //         importDocument({
  //           file: (info.file as unknown) as File,
  //           preservationOfRecordId: preservationOfRecordId as string,
  //           ownerType: 2,
  //         });
  //       } else {
  //         message.error("Đinh dạng file không hỗ trợ");
  //       }
  //     },
  //     [preservationOfRecordId]
  //   );
  useDidUpdate(() => {
    if (
      documentReducer.state ===
      documentModule.reducerStates.downloadImportFileTemplate.processing
    ) {
      startProgress("Đang tải file...");
      startLoading();
    } else if (
      documentReducer.state ===
      documentModule.reducerStates.downloadImportFileTemplate.success
    ) {
      progressSuccess("Tải file thành công!");
      stopLoading();

      downloadFile(
        documentReducer.data.fileContent,
        documentReducer.data.fileName
      );
    } else if (
      documentReducer.state ===
      documentModule.reducerStates.downloadImportFileTemplate.failed
    ) {
      progressFail("Tải file thất bại!");
      stopLoading();
    }
  }, [documentReducer.state]);

  //   // Handle import
  //   const handleImport = useCallback(
  //     (info: UploadChangeParam) => {
  //       const typeFile = info.file.name.indexOf(".xlsx");
  //       if (typeFile >= 0) {
  //         importDocument({
  //           file: (info.file as unknown) as File,
  //           preservationOfRecordId,
  //           ownerType,
  //         });
  //       } else {
  //         message.error("Đinh dạng file không hỗ trợ");
  //       }
  //     },
  //     [preservationOfRecordId]
  //   );
  const fetchArchiveOptions: SelectFetchFunc = useCallback(
    (filter, page): Promise<SelectFetchResponse> => {
      return directorysRepository
        .getAllSelect({
          filter,
          skipCount: (page - 1) * env.DEFAULT_PAGE_SIZE,
          maxResultCount: env.DEFAULT_PAGE_SIZE,
          StatusFilter: statusArchives.NUMBER_TRUE,
        })
        .toPromise()
        .then(({ data }) => {
          return {
            hasMore:
              (page - 1) * env.DEFAULT_PAGE_SIZE + env.DEFAULT_PAGE_SIZE <
              data.totalCount,
            options: data.items.map<OptionType>((archive) => ({
              value: archive.id,
              label: archive.archives.name,
            })),
          };
        });
    },
    []
  );

  useDidUpdate(() => {
    if (
      documentReducer.state === documentModule.reducerStates.import.processing
    ) {
      startProgress("Đang import...");
      startLoading();
    } else if (
      documentReducer.state === documentModule.reducerStates.import.success
    ) {
      stopLoading();

      const sheet = documentReducer.data as ImportResponse;
      if (sheet.totalFail === 0 && !sheet.message?.length) {
        progressSuccess("Import thành công!");
        reloadListDocument();
        return;
      }

      destroyProgress();
      Modal.info({
        title: "Kết quả import",
        width: 800,
        content: (
          <div className="mt-4">
            <Typography.Title level={4}>{sheet.importType}</Typography.Title>
            {sheet.message && !!sheet.message.length ? (
              <Typography.Paragraph type="danger" strong>
                {sheet.message.map((message) => (
                  <>
                    <Typography.Text>{message}</Typography.Text>
                    <br />
                  </>
                ))}
              </Typography.Paragraph>
            ) : null}
            {sheet.totalFail > 0 && (
              <Table
                dataSource={sheet.result.filter(
                  (result) => result.success === false
                )}
                pagination={{
                  size: "small",
                  hideOnSinglePage: true,
                }}
                striped
                bordered
              >
                <Table.Column
                  title="Dòng"
                  dataIndex="stt"
                  render={(stt: number) => (
                    <Typography.Text strong>{stt}</Typography.Text>
                  )}
                />
                <Table.Column
                  title="Lỗi"
                  dataIndex="error"
                  render={(errors: string[]) => {
                    return (
                      <>
                        {errors.map((error, index) => (
                          <Typography.Paragraph
                            key={`result-error-${index}`}
                            strong
                          >
                            {error}
                          </Typography.Paragraph>
                        ))}
                      </>
                    );
                  }}
                />
              </Table>
            )}
          </div>
        ),
      });
    } else if (
      documentReducer.state === documentModule.reducerStates.import.failed
    ) {
      const error = documentReducer.data;

      if (!error?.request || !error?.request?.response) {
        progressFail("Import thất bại");
      } else {
        destroyProgress();
      }
      stopLoading();
    }
  }, [documentReducer.state]);
  const onCreate = useCallback(() => {
    setDocumentId(undefined);
    setModalState("form");
    toggleModalCreate();
  }, []);
  useEffect(() => {
    changeLayoutConfig({
      headerExtra: (
        <Space className="ant-mobile">
          <PrivateView
            permissions={[
              Permissions.Permission.Document.Create,
              Permissions.Permission.Document.DownloadTempFile,
              Permissions.Permission.Document.ImportFile,
            ]}
          >
            <Dropdown
              overlay={
                <Menu>
                  {can(Permissions.Permission.Document.Create) && (
                    <Menu.Item onClick={onCreate}>Thêm mới văn bản</Menu.Item>
                  )}
                  {/* {can(Permissions.Permission.Document.Create) && (
                      <Menu.Item onClick={onCreate}>Thêm mới văn bản</Menu.Item>
                    )}
                    {can(Permissions.Permission.Document.Create) && (
                      <Menu.Item onClick={onQuickCreate}>
                        Thêm nhanh văn bản
                      </Menu.Item>
                    )} */}
                  {can(Permissions.Permission.Document.DownloadTempFile) && (
                    <Menu.Item onClick={handleDownloadImportFileTemplate}>
                      Tải mẫu import
                    </Menu.Item>
                  )}
                  {/* {can(Permissions.Permission.Document.ImportFile) && (
                    <Menu.Item>
                      <Upload
                        beforeUpload={() => false}
                        showUploadList={false}
                        onChange={handleImport}
                        // accept=".xlsx"
                        // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      >
                        Import văn bản
                      </Upload>
                    </Menu.Item>
                  )} */}
                  {can(Permissions.Permission.Document.DownloadFile) && (
                    <Menu.Item onClick={handleExportDocument}>
                      Export văn bản theo filter
                    </Menu.Item>
                  )}
                  {can(Permissions.Permission.Document.DownloadFile) && (
                    <Menu.Item onClick={toggleModalVisible}>
                      Export văn bản theo số hồ sơ
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomRight"
              trigger={["click"]}
              disabled={false}
            >
              <Button icon={<PlusOutlined />}>Thao tác</Button>
            </Dropdown>
          </PrivateView>
        </Space>
      ),
    });
  }, [query]);

  const { state: modalVisible, toggle: toggleModalVisible } = useToggle();
  const [fromInput, setFromInput] = useState<number>(1);
  const [archivesIdExp, setArchivesIdExp] = useState<string>();
  const [toInput, setToInput] = useState<number>(1);
  const resetFormExport = useCallback(() => {
    setFromInput(1);
    setToInput(1);
  }, [fromInput, toInput]);
  const updateFormExport = useCallback(
    (value1 = 0, value2 = 0) => {
      console.log(value1, value2, toInput, fromInput);
      if (value1 !== 0) {
        if (value1 >= toInput) {
          progressFail("input từ hồ sơ phải nhỏ hơn");
          return;
        }
        setFromInput(value1);
      } else if (value2 !== 0) {
        if (value2 <= fromInput) {
          progressFail("input đến hồ sơ phải lớn hơn");
          return;
        }
        setToInput(value2);
      }
    },
    [fromInput, toInput]
  );
  const onSubmit = useCallback(() => {
    exportDocument({
      ArchivesIdFilter: archivesIdExp,
      FromFileNumber: fromInput,
      ToFileNumber: toInput,
    });
    resetFormExport();
    toggleModalVisible();
  }, [preservationOfRecordId, fromInput, toInput, archivesIdExp]);
  // Form handler
  const closeForm = useCallback(() => {
    setDocumentId(undefined);
    setModalState("list");
    toggleModalCreate();
  }, []);
  const onEdit = useCallback((id: string) => {
    setDocumentId(id);
    setModalState("form");
    toggleModalCreate();
  }, []);

  useEffect(() => {
    setArchivesIdExp(String(phongSelected?.id));
  }, [phongSelected]);

  return (
    <>
      <Modal
        visible={modalCreate}
        title="Quản lý mục lục văn bản"
        width="80%"
        onCancel={() => {
          toggleModalCreate();
        }}
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        {modalState === "quick-form" ? (
          <PreservationOfRecordDocumentQuickForm
            onCancel={closeForm}
            onSuccess={closeForm}
          />
        ) : (
          <PreservationOfRecordDocumentFormMenu
            documentId={documentId}
            onSuccess={closeForm}
            onCancel={closeForm}
            editable={!documentId || canEdit}
            visible={modalCreate}
          />
        )}
      </Modal>
      <Modal
        title={"Chọn khoảng export"}
        visible={modalVisible}
        onOk={onSubmit}
        onCancel={() => {
          resetFormExport();
          toggleModalVisible();
        }}
        width={300}
        style={{ marginRight: 40 }}
      >
        <Form>
          {/* {isAdmin ? (
            <Form.Item label="Chọn phông">
              <Select.Ajax
                onChange={(value) => {
                  setArchivesIdExp(value);
                }}
                fetchFunc={fetchArchiveOptions}
                placeholder="Phông lưu trữ"
                style={{ minWidth: 250 }}
                allowClear
              />
            </Form.Item>
          ) : null} */}

          <Form.Item label="Từ số hồ sơ">
            <InputNumber
              min={1}
              value={fromInput}
              defaultValue={fromInput}
              placeholder="Nhập số hồ sơ"
              onChange={(value) => updateFormExport(value, 0)}
            />
          </Form.Item>
          <Form.Item label="Đến số hồ sơ">
            <InputNumber
              min={1}
              value={toInput}
              defaultValue={toInput}
              placeholder="Nhập số hồ sơ"
              onChange={(value) => updateFormExport(0, value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Form
        form={filterForm}
        onValuesChange={handleFilterForm}
        initialValues={{ fromTime: undefined, filter: undefined }}
      >
        <Row gutter={[16, 0]}>
          <Col xs={24} lg={10}>
            <Form.Item name="filter">
              <DebounceInput
                prefix={<SearchOutlined />}
                placeholder="Tìm kiếm tên, số hiệu, tác giả, trích yếu nội dung"
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Input.Group compact>
              {/* <Form.Item name="documentTypeFilter">
                <Select
                  className="w-250"
                  size="middle"
                  options={preservationOfRecordFileTypes.map((type) => ({
                    value: type,
                    label: getPreservationOfRecordFileTypeText(type),
                  }))}
                  placeholder="Loại tài liệu"
                />
              </Form.Item>
              <Form.Item className="ml-2" name="ArchivesIdFilter">
                <Select.Ajax
                  fetchFunc={fetchArchiveOptions}
                  placeholder="Phông lưu trữ"
                  style={{ minWidth: 250 }}
                  allowClear
                />
              </Form.Item> */}
              <Form.Item name="fromTime">
                <DatePicker className="w-100" format={FULL_DATE} />
              </Form.Item>
            </Input.Group>
          </Col>
        </Row>
      </Form>

      <Table
        dataSource={documents}
        loading={loading}
        pagination={{
          ...tablePagination,
          hideOnSinglePage: true,
          onChange: handleTablePaginationChange,
        }}
        size="small"
      >
        <Table.Column
          title="STT"
          render={(_, __, index) =>
            ((tablePagination?.current || 1) - 1) * env.DEFAULT_PAGE_SIZE +
            index +
            1
          }
        />
        <Table.Column title="Số, ký hiệu" dataIndex={["document", "code"]} />
        <Table.Column
          title="Ngày ban hành"
          dataIndex={["document", "dateIssued"]}
          render={(val: string) => (val ? dayjs(val).format(FULL_DATE) : "")}
        />
        <Table.Column title="Tác giả" dataIndex={["document", "author"]} />
        <Table.Column
          title="Trích yếu nội dung"
          dataIndex={["document", "content"]}
        />
        <Table.Column title="Hộp số" dataIndex="storageBoxName" width={60} />
        <Table.Column title="Hồ sơ số" dataIndex={"fileNumber"} width={120} />
        <Table.Column
          title="Thời gian bảo quản"
          width={"20vw"}
          dataIndex={"storageTimeType"}
          render={(text: string, record: any) => {
            return (
              <div>
                {record?.storageTimeType === 1
                  ? "Bảo quản vĩnh viễn"
                  : "Bảo quản có thời hạn"}
              </div>
            );
          }}
        />
        <Table.Column title="Tờ số" dataIndex={["document", "sheetNumber"]} />
        <Table.Column
          title="Thao tác"
          render={(_, record: DocumentResponse) => {
            return (
              <Space>
                {record.isFileDownload && (
                  <Tooltip title={"Tải file"}>
                    <Button
                      icon={<DownloadOutlined />}
                      type="link"
                      onClick={handleDownloadDocumentFiles(record.document.id)}
                    />
                  </Tooltip>
                )}
                <Tooltip title={canEdit ? "Chỉnh sửa" : "Xem"}>
                  <Button
                    icon={
                      canEdit ? (
                        <FontAwesomeIcon icon={faEdit} />
                      ) : (
                        <EyeOutlined />
                      )
                    }
                    type="link"
                    onClick={() => onEdit(record.document.id)}
                  />
                </Tooltip>
                <Tooltip title="Xóa">
                  <Button.ConfirmDelete
                    modalConfig={{
                      title: "Xóa hồ sơ",
                      content: "Bạn chắc chắn muốn xóa hồ sơ này?",
                      onOk: () => handleDeleteDocument(record.document.id),
                    }}
                    type="link"
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    disabled={false}
                    danger
                  />
                </Tooltip>
              </Space>
            );
          }}
        />
      </Table>
    </>
  );
};
const ListDocument = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListDocumentBased);

export default ListDocument;
