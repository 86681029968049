import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import {
  createFinalReportRequest,
  getAllFinalReportRequest,
  updateFinalReportRequest,
} from "src/models/phongs/FinalReport";
import { DeleteRequest } from "src/models/Request";
import { finalReportRepository } from "src/repositories/FinalReportRepository";

const finalReportModule = createReduxObservableModule(
  {
    addNewCategory: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<createFinalReportRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            finalReportRepository.createFinalReport(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
    getAll: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<getAllFinalReportRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            finalReportRepository.getAll(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
    delete: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DeleteRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            finalReportRepository.deleteFinalReport(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
    update: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<updateFinalReportRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            finalReportRepository.updateFinalReport(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    // getCategoryNames: {
    //   epic: ({
    //     actions$,
    //     actionTypes,
    //     actions,
    //   }: ReduxObservableModuleEpicProps<Action<GetCategoryNamesRequest>>) =>
    //     actions$.pipe(
    //       ofType(actionTypes.start),
    //       mergeMap((action) =>
    //         directorysRepository.getCategoryNames(action.payload).pipe(
    //           map((response) => actions.success(response.data)),
    //           catchError((error) => {
    //             return of(actions.failed(error));
    //           }),
    //           takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
    //         )
    //       )
    //     ),
    // },
  },
  "FINALREPORT"
);

export default finalReportModule;
