import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  AllUserRequest,
  AllUserResponse,
  AddUserRequest,
  GetFilterRequest,
  AllRoleListResponse,
  AllArchivesLookupResponse,
  UserInfoRequest,
  UserInfoResponse,
  ChangePasswordRequest,
} from "src/models/administration/User";
import { ExportFileResponse } from "src/models/report";

class AdministrationRepository extends Repository {
  protected basePath = "api/";

  getAllUser(params: AllUserRequest) {
    return this.request<AllUserResponse>({
      method: "GET",
      url: "auth/account/items",
      params,
    });
  }

  addUser(params: AddUserRequest) {
    return this.request({
      method: "POST",
      url: "auth/account",
      data: params,
    });
  }

  getRoleList(params: GetFilterRequest) {
    return this.request<AllRoleListResponse>({
      method: "GET",
      url: "auth/role/list",
      params,
    });
  }

  getArchivesLookup(params: GetFilterRequest) {
    return this.api.request<AllArchivesLookupResponse>({
      method: "GET",
      url: env.API_CMS_URL + "api/cms-service/common/archives-lookup-by-filter",
      params,
    });
  }

  getUserInfo(params: UserInfoRequest) {
    return this.request<UserInfoResponse>({
      method: "GET",
      url: "auth/account",
      params,
    });
  }

  updateUserInfo(params: AddUserRequest) {
    return this.request({
      method: "PUT",
      url: "auth/account",
      data: params,
    });
  }

  resetPassword(param: UserInfoRequest) {
    return this.request({
      method: "POST",
      url: `auth/account/reset-password/${param.userId}`,
    });
  }

  deleteUserInfo(param: UserInfoRequest) {
    return this.request({
      method: "DELETE",
      url: `auth/account?userId=${param.userId}`,
    });
  }

  lockUser(param: UserInfoRequest) {
    return this.request({
      method: "POST",
      url: `auth/account/lock-user/${param.userId}`,
    });
  }

  unLockUser(param: UserInfoRequest) {
    return this.request({
      method: "POST",
      url: `auth/account/un-lock-user/${param.userId}`,
    });
  }

  exportListUser() {
    return this.request<ExportFileResponse>({
      method: "POST",
      url: "auth/user-excel-exporter/export-file-dto",
    });
  }

  getProfile() {
    return this.request<any>({
      method: "GET",
      url: "auth/account/profile",
    });
  }

  updateProfile(params: AddUserRequest) {
    return this.request({
      method: "PUT",
      url: "auth/account/profile",
      data: params,
    });
  }

  changePassword(params: ChangePasswordRequest) {
    return this.request({
      method: "POST",
      url: "identity/my-profile/change-password",
      data: params,
    });
  }
}

export const administrationRepository = new AdministrationRepository();
