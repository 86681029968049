import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import {
  ContractRequest,
  ExportContractRequest,
  ListContractRequest,
} from "src/models/Contract";
import ContractRepository from "src/repositories/ContractRepository";

export interface ContractReducer {}

const defaultState: ContractReducer = {};

const contractModule = createReduxObservableModule(
  {
    getList: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ListContractRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ContractRepository.get(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    getOne: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ContractRepository.getOne(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    create: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ContractRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ContractRepository.create(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    update: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ContractRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ContractRepository.update(action.payload.id, action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    delete: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ContractRepository.delete(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    export: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ExportContractRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ContractRepository.export(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    exportToView: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ContractRepository.exportToView(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "contract",
  defaultState
);

export default contractModule;
