import { map, mergeMap } from "rxjs/operators";
import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { ListContractRequest } from "src/models/Contract";
import { ListDataRequestFormRequest } from "src/models/DataRequestForm";
import {
  DocumentListRequest,
  DocumentListResponse,
  ExportRequestFormRequest,
  FormListRequest,
  FormListResponseModel,
  GetLoopItemRequest,
  GetLoopItemResponse,
  ReaderRegisterListRequest,
  ReaderRegisterRequestModel,
  ReadersOfRecordListResponse,
  RegistrationListRequest,
} from "src/models/ReadersManagementModel";
import { ExportFileResponse } from "src/models/report";
import { FileTypeRequest } from "src/models/Request";

class ReadersRepository extends Repository {
  protected basePath = "api/";

  constructor() {
    super(env.API_CMS_URL);
  }

  // THE READER
  getAllListReaders(params: ReaderRegisterListRequest) {
    return this.request<ReadersOfRecordListResponse>({
      method: "GET",
      url: "cms-service/the-reader/all",
      params,
    });
  }

  createReader(params: RegistrationListRequest) {
    return this.request({
      method: "POST",
      url: "cms-service/the-reader",
      data: params,
    });
  }

  readOneReader(id: string) {
    return this.request<ReaderRegisterRequestModel>({
      method: "GET",
      url: `cms-service/the-reader/${id}`,
    });
  }

  updateReader(params: RegistrationListRequest) {
    return this.request({
      method: "PUT",
      url: "cms-service/the-reader",
      data: params,
    });
  }

  deleteReader(id: string) {
    return this.request({
      method: "DELETE",
      url: `cms-service/the-reader/${id}`,
    });
  }

  // REQUEST FORM
  getForm(params: DocumentListRequest) {
    return this.request<DocumentListResponse>({
      method: "GET",
      url: "cms-service/request-form/all",
      params,
    });
  }

  createForm(params: FormListRequest) {
    return this.request({
      method: "POST",
      url: "cms-service/request-form",
      data: params,
    });
  }

  readForm(id: string) {
    return this.request<FormListResponseModel>({
      method: "GET",
      url: `cms-service/request-form/${id}`,
    });
  }

  exportForm(request: ExportRequestFormRequest) {
    return this.request<ExportFileResponse>({
      method: "post",
      url: `cms-service/request-form/${request.id}/export`,
      params: request,
    }).pipe(
      mergeMap(({ data }) => {
        return this.api
          .get<Blob>("api/File/DownloadTempFile", data, {
            responseType: "blob",
          })
          .pipe(
            map(({ data: fileData }) => ({
              fileName: data.fileName,
              fileContent: fileData,
            }))
          );
      })
    );
  }

  exportFormToView({
    id,
    typeRequest,
  }: Omit<ExportRequestFormRequest, "fileType">) {
    return this.request<string>({
      method: "post",
      url: `cms-service/request-form/${id}/export-to-view`,
      params: { typeRequest },
    });
  }

  updateForm(params: FormListRequest) {
    return this.request({
      method: "PUT",
      url: "cms-service/request-form",
      data: params,
    });
  }

  deleteForm(id: string) {
    return this.request({
      method: "DELETE",
      url: `cms-service/request-form/${id}`,
    });
  }

  // THE READER EXCEL EXPORTER
  exportTheReaderFile(params: ReaderRegisterListRequest) {
    return this.api
      .post<ExportFileResponse>(
        "api/cms-service/the-reader-excel-exporter/export-the-reader-file-dto",
        {
          ...params,
        }
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFileImport", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  exportRequestFormFile(params: DocumentListRequest) {
    return this.api
      .post<ExportFileResponse>(
        "api/cms-service/the-reader-excel-exporter/export-request-form-file-dto",
        {
          ...params,
        }
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFileImport", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  exportDataRequestFormFile(params: ListDataRequestFormRequest) {
    return this.api
      .post<ExportFileResponse>(
        "api/cms-service/the-reader-excel-exporter/export-data-request-form-file-dto",
        {
          ...params,
        }
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFileImport", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  exportContractFile(params: ListContractRequest) {
    return this.api
      .post<ExportFileResponse>(
        "api/cms-service/the-reader-excel-exporter/export-contract-file-dto",
        {
          ...params,
        }
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFileImport", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  // THE EXPORT AND VIEW FILE
  exportRegisterUsingDocument(params: FileTypeRequest) {
    const { id } = params;
    return this.request({
      method: "POST",
      url: `cms-service/the-reader/${id}/export-registration-form-to-use-documents`,
    }).pipe(
      mergeMap(({ data }) => {
        return this.api
          .get<Blob>("api/File/DownloadTempFile", data, {
            responseType: "blob",
          })
          .pipe(
            map(({ data: fileData }) => ({
              fileName: data.fileName,
              fileContent: fileData,
            }))
          );
      })
    );
  }

  viewRegisterUsingDocument(id: string) {
    return this.request({
      method: "POST",
      url: `cms-service/the-reader/${id}/export-registration-form-to-use-documents-to-view`,
    });
  }

  exportRequestDocument(params: FileTypeRequest) {
    const { id, typeRequest } = params;
    return this.request({
      method: "POST",
      url: `cms-service/request-form/${id}/export?typeRequest=${typeRequest}`,
    }).pipe(
      mergeMap(({ data }) => {
        return this.api
          .get<Blob>("api/File/DownloadTempFile", data, {
            responseType: "blob",
          })
          .pipe(
            map(({ data: fileData }) => ({
              fileName: data.fileName,
              fileContent: fileData,
            }))
          );
      })
    );
  }

  getLoopItem(params: GetLoopItemRequest) {
    return this.api.get<GetLoopItemResponse>(
      "api/cms-service/request-form/loop-item",
      params
    );
  }
}

export default new ReadersRepository();
