import { Repository } from "src/core/Repository";
import { AllUserRequest } from "src/models/administration/User";
import { DeleteRequest } from "src/models/Request";
import { addOrEditRolesProps, getRolesProps } from "src/models/roles";

class RoleRepository extends Repository {
  protected basePath = "api/";

  getAllRole(params: getRolesProps) {
    return this.request<AllUserRequest>({
      method: "GET",
      url: "auth/role/list-all",
      params,
    });
  }

  deleteRole(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `auth/role/${param.id}`,
    });
  }

  editRole(params: addOrEditRolesProps) {
    return this.request({
      method: "PUT",
      url: "auth/role/grant-permission-role",
      data: params,
    });
  }

  addRole(params: addOrEditRolesProps) {
    return this.request({
      method: "POST",
      url: "auth/role/grant-permission-role",
      data: params,
    });
  }

  getAllTree(params: getRolesProps) {
    return this.request<AllUserRequest>({
      method: "GET",
      url: "auth/role/all-permission",
      params,
    });
  }

  getPermissionUser() {
    return this.request<string[]>({
      method: "GET",
      url: "auth/role/permissions-profile",
    });
  }

  getRoleUser() {
    return this.request<string[]>({
      method: "GET",
      url: "auth/role/roles-profile",
    });
  }
}

export default new RoleRepository();
