import { map, mergeMap } from "rxjs/operators";
import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  ContractRequest,
  ContractResponse,
  ExportContractRequest,
  ListContractRequest,
  ListContractResponse,
} from "src/models/Contract";
import { ExportFileResponse } from "src/models/report";

class ContractRepository extends Repository<
  ContractRequest,
  ContractResponse,
  ListContractRequest,
  ListContractResponse
> {
  protected basePath = "api/cms-service/contracts";

  constructor() {
    super(env.API_CMS_URL);
  }

  export({ id, fileType }: ExportContractRequest) {
    return this.request<ExportFileResponse>({
      method: "post",
      url: `/${id}/export`,
      params: { fileType },
    }).pipe(
      mergeMap(({ data }) => {
        return this.api
          .get<Blob>("api/File/DownloadTempFile", data, {
            responseType: "blob",
          })
          .pipe(
            map(({ data: fileData }) => ({
              fileName: data.fileName,
              fileContent: fileData,
            }))
          );
      })
    );
  }

  exportToView(id: string) {
    return this.request<string>({
      method: "post",
      url: `/${id}/export-to-view`,
    });
  }
}

export default new ContractRepository();
