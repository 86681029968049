import {
  AppstoreAddOutlined,
  AuditOutlined,
  GroupOutlined,
  IdcardOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  SecurityScanOutlined,
  UngroupOutlined,
} from "@ant-design/icons";
import { lazy } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router";
import Images from "src/assets/images";
import Permissions from "src/constants/permessions";
import { MenuItem } from "src/models/layout";
import { PreservationOfRecordOwnerType } from "src/models/PreservationOfRecord";
import ListDocument from "src/pages/screen/PreservationOfRecord/Create/Document/ListDocument";
import routePaths from "src/routes/paths";
import usePreservationOfRecordRoutes from "src/routes/preservationOfRecordRoutes";
import usePreservationOfRecordRoutesV2 from "src/routes/preservationOfRecordRoutesv2";

const Icon = ({ src }: { src: string }) => (
  <img
    src={src}
    style={{ width: 30, height: 30, paddingRight: 10, objectFit: "contain" }}
  />
);
// const isRoleAdmin = localStorage.getItem("isRoleAdmin");
// const isAdmin = isRoleAdmin !== null;
const CirculationDocumentIndexPage = lazy(
  () => import("src/pages/screen/PreservationOfRecord/CirculationDocument")
);
const PreservationOfRecordCreatePage = lazy(
  () => import("src/pages/screen/PreservationOfRecord/Create")
);

const FileTypeIndexPage = lazy(() => import("src/pages/screen/FileType"));

const useScreenRoutes = (): MenuItem[] => {
  const { t } = useTranslation("pages");
  const preservationOfRecordRoutes = usePreservationOfRecordRoutes();
  const preservationOfRecordRoutesV2 = usePreservationOfRecordRoutesV2();

  return [
    {
      name: t("dashboaed.menu_name"),
      path: routePaths.Screen.Index,
      icon: <AppstoreAddOutlined />,
      component: lazy(() => import("src/pages/screen/Dashboard")),
      hideChildrenInMenu: true,
      permissions: [
        Permissions.Permission.DashBoard.SettingPreservationOfRecordByArchives,
        Permissions.Permission.DashBoard.SettingPreservationOfRecordByField,
      ],
      // routes: [
      //   {
      //     name: t("dashboaed.menu_name"),
      //     path: routePaths.Screen.Index,
      //     component: lazy(() => import("src/pages/screen/Dashboard")),
      //   },
      // ],
    },
    {
      path: routePaths.Screen.DetaiProfileExpired,
      icon: <Icon src={Images.menu.menuDashborad} />,
      render: (props) => (
        <PreservationOfRecordCreatePage
          {...props}
          ownerType={PreservationOfRecordOwnerType.Organization}
        />
      ),
      hideChildrenInMenu: true,
      hideInMenu: true,
    },
    {
      name: t("search.menu_name"),
      path: routePaths.Screen.Search.Index,
      icon: <SecurityScanOutlined />,
      routes: [
        {
          path: routePaths.Screen.Search.Profile.Index,
          name: t("search.index.profile"),
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.AdvanceSearchManage
              .SearchPreservationOfRecord.Index,
          ],
          routes: [
            {
              path: routePaths.Screen.Search.Profile.Index,
              name: t("search.index.profile"),
              component: lazy(
                () => import("src/pages/screen/Searching/ProfileSearch")
              ),
              permissions: [
                Permissions.Permission.AdvanceSearchManage
                  .SearchPreservationOfRecord.View,
                Permissions.Permission.AdvanceSearchManage
                  .SearchPreservationOfRecord.ViewList,
              ],
            },
            {
              name: t("preservation_of_record.detail.page_title", {
                action: "Chỉnh sửa",
              }),
              path: routePaths.Screen.Search.Profile.DetailsProfile,
              component: lazy(
                () => import("src/pages/screen/PreservationOfRecord/Create")
              ),
            },
          ],
        },
        {
          path: routePaths.Screen.Search.Text.Index,
          name: t("search.index.Text"),
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.AdvanceSearchManage.SearchDocument.Index,
          ],
          routes: [
            {
              path: routePaths.Screen.Search.Text.Index,
              name: t("search.index.Text"),
              component: lazy(
                () => import("src/pages/screen/Searching/DocumentsSearch")
              ),
              permissions: [
                Permissions.Permission.AdvanceSearchManage.SearchDocument.View,
                Permissions.Permission.AdvanceSearchManage.SearchDocument
                  .ViewList,
              ],
            },
            {
              name: t("preservation_of_record.document.view"),
              path: routePaths.Screen.Search.Text.DetailsDocument,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/PreservationOfRecord/Create/Document/View"
                  )
              ),
            },
          ],
        },
        // {
        //   path: routePaths.Screen.Search.Fonts.Index,
        //   name: t("search.index.Fonts"),
        //   hideChildrenInMenu: true,
        //   permissions: [
        //     Permissions.Permission.AdvanceSearchManage.SearchPhong.Index,
        //   ],
        //   routes: [
        //     {
        //       path: routePaths.Screen.Search.Fonts.Index,
        //       name: t("search.index.Fonts"),
        //       component: lazy(
        //         () => import("src/pages/screen/Searching/FontsSearch")
        //       ),
        //       permissions: [
        //         Permissions.Permission.AdvanceSearchManage.SearchPhong.View,
        //         Permissions.Permission.AdvanceSearchManage.SearchPhong.ViewList,
        //       ],
        //     },
        //     {
        //       name: "Thông tin chi tiết phông",
        //       path: routePaths.Screen.Search.Fonts.DetailsFonts,
        //       component: lazy(
        //         () => import("src/pages/screen/Phongs/detailsPhongs")
        //       ),
        //     },
        //   ],
        // },
        {
          path: routePaths.Screen.Search.Reader.Index,
          name: t("search.index.Reader"),
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.AdvanceSearchManage.SearchTheReader.Index,
          ],
          routes: [
            {
              path: routePaths.Screen.Search.Reader.Index,
              name: t("search.index.Reader"),
              component: lazy(
                () => import("src/pages/screen/Searching/ReaderSearch")
              ),
              permissions: [
                Permissions.Permission.AdvanceSearchManage.SearchTheReader.View,
                Permissions.Permission.AdvanceSearchManage.SearchTheReader
                  .ViewList,
              ],
            },
            {
              name: t("readers.registration_list.edit.page_title"),
              path: routePaths.Screen.Search.Reader.RegistrationDetails.Index,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RegistrationList/Add"
                  )
              ),
            },
            {
              name: t("readers.request_info.add.page_title"),
              path: routePaths.Screen.Search.Reader.RequestDatails.Index,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RequestInformation/Add"
                  )
              ),
            },
            {
              path: routePaths.Screen.Search.Reader.ProvideDetails.Index,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/ProvideData/Create"
                  )
              ),
              permissions: [
                Permissions.Permission.TheReader.Edit,
                Permissions.Permission.TheReader.View,
              ],
            },
          ],
        },
      ],
    },

    {
      // phongs
      name: t("storage.menu_name"),
      path: routePaths.Screen.Phongs.Index,
      permissions: Permissions.Permission.Phongs.ViewList,
      icon: <GroupOutlined />,
      hideChildrenInMenu: true,
      routes: [
        {
          name: t("storage.menu_name"),
          path: routePaths.Screen.Phongs.Index,
          hideInMenu: true,
          render: () => (
            <Redirect to={routePaths.Screen.Phongs.managerArchives.Index} />
          ),
        },
        {
          name: t("storage.menu_name"),
          path: routePaths.Screen.Phongs.managerArchives.Index,
          hideInMenu: false,
          routes: [
            {
              name: t("storage.menu_name"),
              path: routePaths.Screen.Phongs.managerArchives.Index,
              hideInMenu: false,
              component: lazy(() => import("src/pages/screen/Phongs")),
              permissions: [
                Permissions.Permission.Phongs.ViewList,
                Permissions.Permission.Phongs.Create,
                Permissions.Permission.Phongs.Edit,
                Permissions.Permission.Phongs.Delete,
                Permissions.Permission.Phongs.View,
                Permissions.Permission.DeliveryReport.View,
                Permissions.Permission.PlanReport.View,
                Permissions.Permission.IdentifyReport.View,
                Permissions.Permission.HistoryReport.View,
                Permissions.Permission.ClassifyReport.View,
                Permissions.Permission.TypeClassifyReport.View,
                Permissions.Permission.FinalReport.View,
                Permissions.Permission.Archives.ViewList,
                Permissions.Permission.Archives.Create,
                Permissions.Permission.Archives.Delete,
                Permissions.Permission.Archives.Edit,
              ],
            },
            {
              name: "Sửa phông",
              path: routePaths.Screen.Phongs.managerArchives.Edit,
              component: lazy(
                () => import("src/pages/screen/Phongs/editPhongs")
              ),
              permissions: Permissions.Permission.Phongs.Edit,
              hideInMenu: true,
            },
            {
              name: "Thêm mới phông",
              path: routePaths.Screen.Phongs.managerArchives.Add,
              component: lazy(
                () => import("src/pages/screen/Phongs/addPhongs")
              ),
              permissions: Permissions.Permission.Phongs.Create,
              hideInMenu: true,
            },
            {
              name: "Thông tin chi tiết phông",
              path: routePaths.Screen.Phongs.managerArchives.Details,
              component: lazy(
                () => import("src/pages/screen/Phongs/detailsPhongs")
              ),
              hideInMenu: true,
              permissions: Permissions.Permission.Phongs.View,
            },
            {
              name: "Danh sách hộp số",
              path: routePaths.Screen.Phongs.managerArchives.Box,
              component: lazy(
                () => import("src/pages/screen/Phongs/detailsPhongs")
              ),
              hideChildrenInMenu: true,
              permissions: Permissions.Permission.Phongs.View,
            },
          ],
        },
      ],
    },

    {
      name: "Các văn bản hướng dẫn chỉnh lý",
      path: routePaths.Screen.PhongsDocument.Index,
      permissions: Permissions.Permission.Phongs.ViewList,
      icon: <ReadOutlined />,
      hideChildrenInMenu: false,
      routes: [
        {
          name: "Mẫu phiếu tin",
          path: routePaths.Screen.PhongsDocument.NewsletterForm.Index,
          permissions: Permissions.Permission.Phongs.View,
          hideChildrenInMenu: true,
          routes: [
            {
              name: "",
              path: routePaths.Screen.PhongsDocument.NewsletterForm.Index,
              component: lazy(
                () => import("src/pages/screen/Phongs/NewsletterForm")
              ),
              // permissions: [
              //   Permissions.Permission.TypeClassifyReport.Create,
              //   Permissions.Permission.TypeClassifyReport.Edit,
              //   Permissions.Permission.TypeClassifyReport.Delete,
              //   Permissions.Permission.TypeClassifyReport.Export,
              // ],
            },
            {
              name: "Thêm hướng mẫu phiếu tin",
              path: routePaths.Screen.PhongsDocument.NewsletterForm.Add,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/Phongs/NewsletterForm/addNewsletterForm"
                  )
              ),
              permissions: Permissions.Permission.Phongs.View,
            },
            {
              name: "Cập nhật mẫu phiếu tin",
              path: routePaths.Screen.PhongsDocument.NewsletterForm.Edit,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/Phongs/NewsletterForm/updateNewsletterForm"
                  )
              ),
              permissions: Permissions.Permission.Phongs.View,
            },
          ],
        },
        {
          name: "Biên bản giao nhận tài liệu",
          path: routePaths.Screen.PhongsDocument.HandoverDocument.Index,
          hideChildrenInMenu: true,
          routes: [
            {
              name: "Biên bản giao nhận tài liệu",
              path: routePaths.Screen.PhongsDocument.HandoverDocument.Index,
              component: lazy(
                () => import("src/pages/screen/Phongs/HandoverDocument")
              ),
              permissions: [
                Permissions.Permission.DeliveryReport.View,
                Permissions.Permission.DeliveryReport.Create,
                Permissions.Permission.DeliveryReport.Export,
                Permissions.Permission.DeliveryReport.Edit,
                Permissions.Permission.DeliveryReport.Delete,
              ],
            },
            {
              name: "Danh sách biên bản giao nhận tài liệu",
              path: routePaths.Screen.PhongsDocument.HandoverDocument.Add,

              component: lazy(
                () =>
                  import(
                    "src/pages/screen/Phongs/HandoverDocument/addHandoverDocument"
                  )
              ),
            },
            {
              name: "Chỉnh sửa biên bản giao nhận tài liệu",
              path: routePaths.Screen.PhongsDocument.HandoverDocument.Edit,

              component: lazy(
                () =>
                  import(
                    "src/pages/screen/Phongs/HandoverDocument/editHandoverDocument"
                  )
              ),
              permissions: Permissions.Permission.Phongs.View,
            },
          ],
        },

        {
          name: "Kế hoạch chỉnh lý tài liệu",
          path: routePaths.Screen.PhongsDocument.EditPlantDocument.Index,
          hideChildrenInMenu: true,
          routes: [
            {
              name: "Kế hoạch chỉnh lý tài liệu",
              path: routePaths.Screen.PhongsDocument.EditPlantDocument.Index,
              component: lazy(
                () => import("src/pages/screen/Phongs/EditPlant")
              ),
              permissions: [
                Permissions.Permission.PlanReport.Create,
                Permissions.Permission.PlanReport.Edit,
                Permissions.Permission.PlanReport.Delete,
                Permissions.Permission.PlanReport.Export,
              ],
            },
            {
              name: "Thêm kế hoạch chỉnh sửa tài liệu",
              path: routePaths.Screen.PhongsDocument.EditPlantDocument.Add,

              component: lazy(
                () => import("src/pages/screen/Phongs/EditPlant/add")
              ),
              permissions: Permissions.Permission.Phongs.Create,
            },
            {
              name: "Sửa kế hoạch chỉnh sửa tài liệu",
              path: routePaths.Screen.PhongsDocument.EditPlantDocument.Edit,
              component: lazy(
                () => import("src/pages/screen/Phongs/EditPlant/edit")
              ),
              permissions: Permissions.Permission.Phongs.Create,
            },
          ],
        },
        {
          name: "Lịch sử đơn vị hình thành phông",
          path: routePaths.Screen.PhongsDocument.HistoryPhongs.Index,
          permissions: Permissions.Permission.Phongs.View,
          hideChildrenInMenu: true,
          routes: [
            {
              name: "Lịch sử đơn vị hình thành phông",
              path: routePaths.Screen.PhongsDocument.HistoryPhongs.Index,
              component: lazy(() => import("src/pages/screen/Phongs/History")),
              permissions: [
                Permissions.Permission.HistoryReport.Create,
                Permissions.Permission.HistoryReport.Edit,
                Permissions.Permission.HistoryReport.Delete,
                Permissions.Permission.HistoryReport.Export,
              ],
            },
            {
              name: "Thêm mới lịch sử phông",
              path: routePaths.Screen.PhongsDocument.HistoryPhongs.Add,
              component: lazy(
                () => import("src/pages/screen/Phongs/History/add")
              ),
              permissions: Permissions.Permission.Phongs.Create,
            },
            {
              name: "Sửa lịch sử phông",
              path: routePaths.Screen.PhongsDocument.HistoryPhongs.Edit,
              component: lazy(
                () => import("src/pages/screen/Phongs/History/edit")
              ),
              permissions: Permissions.Permission.Phongs.Edit,
            },
          ],
        },
        {
          name: "Hướng dẫn phân loại, lập hồ sơ",
          path: routePaths.Screen.PhongsDocument.ClassifyCreateFile.Index,
          permissions: Permissions.Permission.Phongs.View,
          hideChildrenInMenu: true,
          routes: [
            {
              name: "Hướng dẫn phân loại, lập hồ sơ",
              path: routePaths.Screen.PhongsDocument.ClassifyCreateFile.Index,
              component: lazy(
                () => import("src/pages/screen/Phongs/ClassifyCreateFile")
              ),
              permissions: [
                Permissions.Permission.ClassifyReport.Create,
                Permissions.Permission.ClassifyReport.Edit,
                Permissions.Permission.ClassifyReport.Delete,
                Permissions.Permission.ClassifyReport.Export,
              ],
            },
            {
              name: "Thêm hướng dẫn phân loại, lập hồ sơ",
              path: routePaths.Screen.PhongsDocument.ClassifyCreateFile.Add,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/Phongs/ClassifyCreateFile/addClassifyCreateFile"
                  )
              ),
              permissions: Permissions.Permission.Phongs.View,
            },
            {
              name: "Chỉnh sửa hướng dẫn phân loại, lập hồ sơ",
              path: routePaths.Screen.PhongsDocument.ClassifyCreateFile.Edit,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/Phongs/ClassifyCreateFile/editClassifyCreateFile"
                  )
              ),
              permissions: Permissions.Permission.Phongs.View,
            },
          ],
        },
        {
          name: "Hướng dẫn xác định tài liệu",
          path: routePaths.Screen.PhongsDocument.DetermineDocument.Index,
          hideChildrenInMenu: true,
          routes: [
            {
              name: "Hướng dẫn xác định tài liệu",
              path: routePaths.Screen.PhongsDocument.DetermineDocument.Index,
              component: lazy(
                () => import("src/pages/screen/Phongs/DetermineDocument")
              ),
              permissions: [
                Permissions.Permission.IdentifyReport.Create,
                Permissions.Permission.IdentifyReport.Edit,
                Permissions.Permission.IdentifyReport.Delete,
                Permissions.Permission.IdentifyReport.Export,
              ],
            },
            {
              name: "Hướng dẫn xác định tài liệu",
              path: routePaths.Screen.PhongsDocument.DetermineDocument.Add,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/Phongs/DetermineDocument/addDetermineDocument"
                  )
              ),
              permissions: Permissions.Permission.Phongs.View,
            },
            {
              name: "Chỉnh sửa dẫn xác định tài liệu",
              path: routePaths.Screen.PhongsDocument.DetermineDocument.Edit,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/Phongs/DetermineDocument/editDetermineDocument"
                  )
              ),
              permissions: Permissions.Permission.Phongs.View,
            },
          ],
        },
        {
          name: "Danh sách báo cáo tổng kết chỉnh lý",
          path: routePaths.Screen.PhongsDocument.FinalReport.Index,
          permissions: Permissions.Permission.Phongs.View,
          hideChildrenInMenu: true,
          routes: [
            {
              name: "",
              path: routePaths.Screen.PhongsDocument.FinalReport.Index,
              component: lazy(
                () => import("src/pages/screen/Phongs/FinalReport")
              ),
              permissions: [
                Permissions.Permission.FinalReport.Create,
                Permissions.Permission.FinalReport.Edit,
                Permissions.Permission.FinalReport.Delete,
                Permissions.Permission.FinalReport.Export,
              ],
            },
            {
              name: "Thêm báo cáo tổng kết chỉnh lý",
              path: routePaths.Screen.PhongsDocument.FinalReport.Add,
              component: lazy(
                () =>
                  import("src/pages/screen/Phongs/FinalReport/addFinalReport")
              ),
              permissions: Permissions.Permission.Phongs.View,
            },
            {
              name: "Chỉnh sửa báo cáo tổng kết chỉnh lý",
              path: routePaths.Screen.PhongsDocument.FinalReport.Edit,
              component: lazy(
                () =>
                  import("src/pages/screen/Phongs/FinalReport/editFinalReport")
              ),
              permissions: Permissions.Permission.Phongs.View,
            },
          ],
        },
      ],
    },

    {
      name: "Mục lục hồ sơ",
      path: routePaths.Screen.PreservationOfRecord.Index,
      permissions: Permissions.Permission.Phongs.ViewList,
      icon: <ReconciliationOutlined />,
      hideChildrenInMenu: false,

      routes: [
        {
          name: t("preservation_of_record.document.view"),
          path: routePaths.Screen.PreservationOfRecord.DocumentDetail,
          hideInMenu: true,
          component: lazy(
            () =>
              import(
                "src/pages/screen/PreservationOfRecord/Create/Document/View"
              )
          ),
          permissions: [Permissions.Permission.Document.View],
        },
        {
          name: "Mục lục hồ sơ vĩnh viễn",
          path: routePaths.Screen.PreservationOfRecord.Organization.Index,
          hideInMenu: false,
          hideChildrenInMenu: false,
          routes: [
            {
              name: "Mục lục hồ sơ",
              path:
                routePaths.Screen.PreservationOfRecord.Organization.File.Index,
              hideChildrenInMenu: true,
              render: (props) => {
                return (
                  <CirculationDocumentIndexPage
                    {...props}
                    ownerType={PreservationOfRecordOwnerType.Organization}
                  />
                );
              },
              permissions: [
                Permissions.Permission.PreservationOfRecord.ViewList,
                Permissions.Permission.PreservationOfRecord.View,
                Permissions.Permission.PreservationOfRecord.Create,
                Permissions.Permission.PreservationOfRecord.Edit,
                Permissions.Permission.CirculationDocument.ViewList,
                Permissions.Permission.CirculationDocument.Create,
                Permissions.Permission.CirculationDocument.View,
              ],
              routes: [...preservationOfRecordRoutes],
            },
            {
              name: "Mục lục văn bản",
              path:
                routePaths.Screen.PreservationOfRecord.Organization
                  .ListDocument,
              permissions: Permissions.Permission.Phongs.ViewList,
              hideChildrenInMenu: true,
              render: (props) => {
                return <ListDocument storageTimeTypeFilter={1} />;
              },
            },
          ],
        },
        {
          name: "Mục lục hồ sơ có thời hạn",
          path: routePaths.Screen.PreservationOfRecord.DocumentDeadline.Index,
          hideInMenu: false,
          routes: [
            {
              name: "Mục lục hồ sơ",
              path:
                routePaths.Screen.PreservationOfRecord.DocumentDeadline.File
                  .Index,
              hideChildrenInMenu: true,
              render: (props) => {
                return (
                  <CirculationDocumentIndexPage
                    {...props}
                    ownerType={PreservationOfRecordOwnerType.Organization}
                  />
                );
              },
              permissions: [
                Permissions.Permission.PreservationOfRecord.ViewList,
                Permissions.Permission.PreservationOfRecord.View,
                Permissions.Permission.PreservationOfRecord.Create,
                Permissions.Permission.PreservationOfRecord.Edit,
                Permissions.Permission.CirculationDocument.ViewList,
                Permissions.Permission.CirculationDocument.Create,
                Permissions.Permission.CirculationDocument.View,
              ],
              routes: [...preservationOfRecordRoutesV2],
            },
            {
              name: "Mục lục văn bản",
              path:
                routePaths.Screen.PreservationOfRecord.DocumentDeadline
                  .ListDocument,
              permissions: Permissions.Permission.Phongs.ViewList,
              render: (props) => {
                return <ListDocument storageTimeTypeFilter={2} />;
              },
            },
          ],
        },

        {
          name: t("preservation_of_record.file_type"),
          path: routePaths.Screen.PreservationOfRecord.FileType.Index,
          // icon: <ClusterOutlined />,
          hideChildrenInMenu: false,
          permissions: [
            Permissions.Permission.PreservationOfRecord.ViewList,
            Permissions.Permission.CirculationDocument.ViewList,
          ],
          routes: [
            {
              name: "Mục lục hồ sơ",
              path: routePaths.Screen.PreservationOfRecord.FileType.File.Index,
              hideChildrenInMenu: true,
              permissions: [
                Permissions.Permission.PreservationOfRecord.ViewList,
                Permissions.Permission.PreservationOfRecord.View,
                Permissions.Permission.PreservationOfRecord.Create,
                Permissions.Permission.PreservationOfRecord.Edit,
                Permissions.Permission.CirculationDocument.ViewList,
                Permissions.Permission.CirculationDocument.Create,
                Permissions.Permission.CirculationDocument.View,
              ],
              render: (props) => {
                return (
                  <FileTypeIndexPage
                    {...props}
                    ownerType={PreservationOfRecordOwnerType.FileType}
                  />
                );
              },
              routes: [
                {
                  name: "Mục lục hồ sơ",
                  path:
                    routePaths.Screen.PreservationOfRecord.FileType.File.Index,
                  hideChildrenInMenu: true,
                  render: (props) => {
                    return (
                      <FileTypeIndexPage
                        {...props}
                        ownerType={PreservationOfRecordOwnerType.FileType}
                      />
                    );
                  },
                },
                {
                  name: t("preservation_of_record.file_type"),
                  path:
                    routePaths.Screen.PreservationOfRecord.FileType.File.Detail,
                  icon: <Icon src={Images.menu.menuType} />,
                  render: (props) => (
                    <PreservationOfRecordCreatePage
                      {...props}
                      ownerType={PreservationOfRecordOwnerType.Organization}
                    />
                  ),
                },
                {
                  name: t("preservation_of_record.create.page_title"),
                  path:
                    routePaths.Screen.PreservationOfRecord.FileType.File.Create,
                  render: (props) => (
                    <PreservationOfRecordCreatePage
                      {...props}
                      ownerType={PreservationOfRecordOwnerType.Organization}
                    />
                  ),
                  permissions:
                    Permissions.Permission.PreservationOfRecord.Create,
                },
              ],
            },
            {
              name: "Mục lục văn bản",
              path:
                routePaths.Screen.PreservationOfRecord.FileType.ListDocument,
              permissions: Permissions.Permission.Phongs.ViewList,
              render: (props) => {
                return <ListDocument storageTimeTypeFilter={3} />;
              },
            },
            {
              name: "Bản thuyết minh tài liệu phân loại",
              path:
                routePaths.Screen.PreservationOfRecord.FileType
                  .CategoryClassifyDocument.Index,
              permissions: Permissions.Permission.Phongs.View,
              hideChildrenInMenu: true,
              routes: [
                {
                  name: "",
                  path:
                    routePaths.Screen.PreservationOfRecord.FileType
                      .CategoryClassifyDocument.Index,
                  component: lazy(
                    () =>
                      import("src/pages/screen/Phongs/CategoryClassifyDocument")
                  ),
                  permissions: [
                    Permissions.Permission.TypeClassifyReport.Create,
                    Permissions.Permission.TypeClassifyReport.Edit,
                    Permissions.Permission.TypeClassifyReport.Delete,
                    Permissions.Permission.TypeClassifyReport.Export,
                  ],
                },
                {
                  name: "Thêm hướng dẫn phân loại, lập hồ sơ",
                  path:
                    routePaths.Screen.PreservationOfRecord.FileType
                      .CategoryClassifyDocument.Add,
                  component: lazy(
                    () =>
                      import(
                        "src/pages/screen/Phongs/CategoryClassifyDocument/addCategoryClassifyDocument"
                      )
                  ),
                  permissions: Permissions.Permission.Phongs.View,
                },
                {
                  name: "",
                  path:
                    routePaths.Screen.PreservationOfRecord.FileType
                      .CategoryClassifyDocument.Edit,
                  component: lazy(
                    () =>
                      import(
                        "src/pages/screen/Phongs/CategoryClassifyDocument/editCategoryClassifyDocument"
                      )
                  ),
                  permissions: Permissions.Permission.Phongs.View,
                },
              ],
            },
          ],
        },
      ],
    },

    // Readers Management
    {
      name: t("readers.menu_name"),
      path: routePaths.Screen.Readers.Index,
      icon: <IdcardOutlined />,
      routes: [
        {
          name: t("readers.registration_list.menu_name"),
          path: routePaths.Screen.Readers.RegistrationList.Index,
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.TheReader.ViewList,
            Permissions.Permission.TheReader.View,
            Permissions.Permission.TheReader.Create,
            Permissions.Permission.TheReader.Edit,
            Permissions.Permission.TheReader.PrintView,
          ],
          routes: [
            {
              name: t("readers.registration_list.menu_name"),
              path: routePaths.Screen.Readers.RegistrationList.Index,
              component: lazy(
                () =>
                  import("src/pages/screen/ReadersManagement/RegistrationList")
              ),
              permissions: Permissions.Permission.TheReader.ViewList,
            },
            {
              name: t("readers.registration_list.add.page_title"),
              path: routePaths.Screen.Readers.RegistrationList.Add,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RegistrationList/Add"
                  )
              ),
              permissions: Permissions.Permission.TheReader.Create,
            },
            {
              name: t("readers.registration_list.edit.page_title"),
              path: routePaths.Screen.Readers.RegistrationList.Edit,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RegistrationList/Add"
                  )
              ),
              permissions: Permissions.Permission.TheReader.Edit,
            },
          ],
        },
        {
          name: t("readers.request_info.menu_name"),
          path: routePaths.Screen.Readers.RequestInformation.Index,
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.TheReader.ViewList,
            Permissions.Permission.TheReader.View,
            Permissions.Permission.TheReader.Create,
            Permissions.Permission.TheReader.Edit,
            Permissions.Permission.TheReader.PrintView,
          ],
          routes: [
            {
              name: t("readers.request_info.menu_name"),
              path: routePaths.Screen.Readers.RequestInformation.Index,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RequestInformation"
                  )
              ),
              permissions: Permissions.Permission.TheReader.ViewList,
            },
            {
              name: t("readers.request_info.add.page_title"),
              path: routePaths.Screen.Readers.RequestInformation.Add,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RequestInformation/Add"
                  )
              ),
              permissions: Permissions.Permission.TheReader.Create,
            },
            {
              name: t("readers.request_info.add.page_title"),
              path: routePaths.Screen.Readers.RequestInformation.Edit,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RequestInformation/Add"
                  )
              ),
              permissions: Permissions.Permission.TheReader.Edit,
            },
          ],
        },
        {
          name: t("readers.provide_data.menu_name"),
          path: routePaths.Screen.Readers.ProvideData.Index,
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.TheReader.ViewList,
            Permissions.Permission.TheReader.Edit,
            Permissions.Permission.TheReader.Create,
            Permissions.Permission.TheReader.View,
          ],
          routes: [
            {
              name: t("readers.provide_data.menu_name"),
              path: routePaths.Screen.Readers.ProvideData.Index,
              component: lazy(
                () => import("src/pages/screen/ReadersManagement/ProvideData")
              ),
              permissions: [Permissions.Permission.TheReader.ViewList],
            },
            {
              path: routePaths.Screen.Readers.ProvideData.Create,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/ProvideData/Create"
                  )
              ),
              permissions: [Permissions.Permission.TheReader.Create],
            },
            {
              path: routePaths.Screen.Readers.ProvideData.Detail,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/ProvideData/Create"
                  )
              ),
              permissions: [
                Permissions.Permission.TheReader.Edit,
                Permissions.Permission.TheReader.View,
              ],
            },
          ],
        },
      ],
    },
    // Report
    {
      name: t("report.menu_name"),
      path: routePaths.Screen.Report.Index,
      icon: <AuditOutlined />,
      permissions: [
        Permissions.Permission.StatisticAndReport.ArchiveWorkReport.Download,
        Permissions.Permission.StatisticAndReport.ArchiveWorkReport
          .ViewAndPrint,
        Permissions.Permission.StatisticAndReport.StatisticListOfStorageStatus,
      ],
      routes: [
        {
          name: t("report.storage_status.page_title"),
          path: routePaths.Screen.Report.StorageStatus.Index,
          component: lazy(
            () => import("src/pages/screen/Report/StorageStatus")
          ),
          permissions:
            Permissions.Permission.StatisticAndReport
              .StatisticListOfStorageStatus,
        },
        {
          name: t("report.storage_work.page_title"),
          path: routePaths.Screen.Report.StorageWork.Index,
          component: lazy(() => import("src/pages/screen/Report/StorageWork")),
          permissions: [
            Permissions.Permission.StatisticAndReport.ArchiveWorkReport
              .Download,
            Permissions.Permission.StatisticAndReport.ArchiveWorkReport
              .ViewAndPrint,
          ],
        },
      ],
    },
    // Administration
    {
      name: t("administer.menu_name"),
      path: routePaths.Screen.Administration.Index,
      icon: <UngroupOutlined />,
      routes: [
        {
          name: t("categories.menu_name"),
          path: routePaths.Screen.Administration.Categories,
          // icon: <CreditCardOutlined />,
          component: lazy(
            () => import("src/pages/screen/Directorys/Directory")
          ),
          permissions: [
            Permissions.Permission.Category.Create,
            Permissions.Permission.Category.Edit,
            Permissions.Permission.Category.Delete,
            Permissions.Permission.Category.ViewList,
          ],
          // routes: [
          //   {
          //     path: routePaths.Screen.Categories.Index,
          //     name: t("categories.index"),
          //     component: lazy(
          //       () => import("src/pages/screen/Directorys/Directory")
          //     ),
          //     permissions: [
          //       Permissions.Permission.Category.Create,
          //       Permissions.Permission.Category.Edit,
          //       Permissions.Permission.Category.Delete,
          //       Permissions.Permission.Category.ViewList,
          //     ],
          //   },
          //   {
          //     path: routePaths.Screen.Categories.Warehouse,
          //     name: t("categories.warehouse"),
          //     hideChildrenInMenu: true,
          //     component: lazy(() => import("src/pages/screen/Directorys/Archives")),
          //     permissions: [
          //       Permissions.Permission.Archives.Create,
          //       Permissions.Permission.Archives.Delete,
          //       Permissions.Permission.Archives.Edit,
          //       Permissions.Permission.Archives.ViewList,
          //     ],
          //   },
          // ],
        },
        {
          name: t("administer.index.page_title"),
          path: routePaths.Screen.Administration.OrganizationUnits.Index,
          component: lazy(
            () => import("src/pages/screen/Administration/OrganizationUnits")
          ),
          permissions: [
            Permissions.Permission.OrganizationUnit.Create,
            Permissions.Permission.OrganizationUnit.Delete,
            Permissions.Permission.OrganizationUnit.Active,
            Permissions.Permission.OrganizationUnit.Edit,
            Permissions.Permission.OrganizationUnit.ViewList,
            Permissions.Permission.OrganizationUnit.View,
          ],
        },
        {
          name: t("administer.role.menu_name"),
          path: routePaths.Screen.Administration.Role.Index,
          component: lazy(() => import("src/pages/screen/Administration/Role")),
          permissions: [
            Permissions.Permission.RoleManage.Create,
            Permissions.Permission.RoleManage.Delete,
            Permissions.Permission.RoleManage.Edit,
            Permissions.Permission.RoleManage.View,
            Permissions.Permission.RoleManage.ViewList,
          ],
        },
        {
          name: t("administer.user.menu_name"),
          path: routePaths.Screen.Administration.User.Index,
          component: lazy(() => import("src/pages/screen/Administration/User")),
          permissions: [
            Permissions.Auth.UserManage.Create,
            Permissions.Auth.UserManage.Export,
            Permissions.Auth.UserManage.View,
            Permissions.Auth.UserManage.ViewList,
            Permissions.Auth.UserManage.LockAndManage,
            Permissions.Auth.UpdateAvartar,
            Permissions.Auth.UserManageEdit,
            Permissions.Auth.UserManageDelete,
            Permissions.Auth.UpdateProfile,
            Permissions.Auth.ChangePassword,
          ],
        },
      ],
    },
    {
      path: routePaths.Screen.Index,
      hideInMenu: true,
      redirect: routePaths.Screen.User.Index,
      exact: true,
    },
  ];
};

export default useScreenRoutes;
