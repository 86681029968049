import { UploadFile } from "antd/lib/upload/interface";
import dayjs, { Dayjs } from "dayjs";
import { PreservationOfRecordCoordinate } from "src/models/PreservationOfRecord";
import { ListRequest, ListResponse } from "src/models/Request";
import { escapeDate, FULL_DATE } from "src/utilities/dateFormatHelper";
import {
  fileStringsToUploadFile,
  uploadFilesToString,
} from "src/utilities/fileHelpers";
export interface ReaderRegisterListRequest extends ListRequest {
  archivesIdFilter?: string;
  fromTime?: string | Dayjs;
  toTime?: string | Dayjs;
}
export interface RegistrationListRequest {
  id?: string;
  code?: string;
  fullName?: string;
  birthday?: string | Dayjs | null;
  nationality?: string;
  identityCard?: string;
  phone?: string;
  workingAgency?: string;
  address?: string;
  purpose?: string;
  researchTopic?: string;
  researchTime?: string;
  archivesId?: string | null;
  approver?: string;
  registrationDate?: string | Dayjs | null;
  address_phone?: string;
  image1?: string;
  image2?: string;
  image3?: string;
  defaultArchivesId?: string | null;
  images?: any[];
}

export class ReaderRegisterRequestModel {
  theReader: RegistrationListRequest;

  constructor() {
    this.theReader = {};
  }

  static parseFromData = (
    items: ReaderRegisterRequestModel[]
  ): RegistrationListRequest[] => {
    const newData = [];
    for (let i = 0; i < items.length; i++) {
      const { theReader } = new ReaderRegisterRequestModel();
      theReader.id = items[i].theReader.id;
      theReader.code = items[i].theReader.code;
      theReader.registrationDate =
        items[i]?.theReader?.registrationDate &&
        items[i]?.theReader?.registrationDate !== null &&
        items[i]?.theReader?.registrationDate !== undefined
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            dayjs(items[i]!.theReader!.registrationDate!).format(FULL_DATE)
          : null;
      theReader.fullName = items[i].theReader.fullName;
      theReader.nationality = items[i].theReader.nationality;
      theReader.identityCard = items[i].theReader.identityCard;
      theReader.workingAgency = items[i].theReader.workingAgency;
      theReader.researchTopic = items[i].theReader.researchTopic;
      theReader.address = items[i].theReader.address ?? "";
      theReader.phone = items[i].theReader.phone ?? "";
      newData.push(theReader);
    }
    return newData;
  };
}

export type ReadersOfRecordListResponse = ListResponse<ReaderRegisterRequestModel>;

export enum DocumentFilterRequestType {}

export enum DocumentRequestType {
  Read = "1",
  Copy = "2",
  Attestation = "3",
  Coordinates = "4",
}

export enum EditOrDetailType {
  Edit = "edit",
  Detail = "detail",
}

export interface DocumentListRequest extends ReaderRegisterListRequest {
  typeRequestFilter: number;
  defaultArchivesId?: string;
}

export interface RequestFormItems {
  no: number;
  fontName: string;
  fileSymbol: string;
  fileName: string;
  fromToSheet: string;
  totalPage: number | string;
  note: string;
}

export interface FormListRequest<For extends "client" | "server" = "server"> {
  typeRequest: number;
  fullName: string;
  identityCard: string;
  researchTopic: string;
  approver: string;
  registrationDate: For extends "client" ? Dayjs | null : string | null;
  readingRoomComments: string;
  image1: string;
  image2: string;
  image3: string;
  images: For extends "client" ? UploadFile[] : undefined;
  authentication: string;
  requestFormItems: RequestFormItems[];
  id?: string;
  archivesId?: string | null;
  defaultArchivesId?: string | null;
}

export interface FormListResponseModel {
  creationTime: string;
  requestForm: FormListRequest;
  listRequestFormItem: RequestFormItems[];
}

export type DocumentListResponse = ListResponse<FormListResponseModel>;

export interface ExportCadastralCoordinateRequest {
  organizationUnitName: string;
  petitioner: string;
  items: {
    itemNo: number;
    name: string;
    horizontalAxis?: number;
    verticalAxis?: number;
    height: number;
    coordinatePointType: string;
  }[];
}

export interface ExportRequestFormRequest {
  id: string;
  fileType: 0 | 1;
  typeRequest: 1 | 2 | 3 | 4;
}

export const clientToServerRequestForm = (
  request: FormListRequest<"client">
): FormListRequest => {
  const imageStrings = uploadFilesToString(request.images || []).split(",");
  return {
    ...request,
    registrationDate: request.registrationDate
      ? request.registrationDate.format(escapeDate)
      : null,
    image1: imageStrings[0] || "",
    image2: imageStrings[1] || "",
    image3: imageStrings[2] || "",
    images: undefined,
  };
};

export const serverToClientRequestForm = (
  data: FormListRequest<"server">
): FormListRequest<"client"> => {
  const images = fileStringsToUploadFile(
    [data.image1, data.image2, data.image3]
      .filter(
        (image) =>
          image !== null && image !== undefined && image !== "" && image !== "/"
      )
      .join(",")
  );
  return {
    ...data,
    registrationDate: data.registrationDate
      ? dayjs(data.registrationDate)
      : null,
    images: images,
  };
};

export interface GetLoopItemRequest extends ListRequest {
  archivesIdFilter?: string;
}

export type GetLoopItemResponse = ListResponse<PreservationOfRecordCoordinate>;
