import Images from "src/assets/images";
import env from "src/core/env";
import { UserModel } from "src/models/administration/User";

export function getUserStatus(status: boolean) {
  switch (status) {
    case true:
      return {
        label: "Hoạt động",
        backgroundColor: "#C9F7F5",
        color: "#1BC5BD",
      };
    case false:
      return {
        label: "Khoá",
        backgroundColor: "#E4E5E7",
        color: "#000",
      };
  }
}

export function getNegativeUserStatus(status: boolean) {
  switch (status) {
    case false:
      return {
        negative_label: "Hoạt động",
        negative_backgroundColor: "#C9F7F5",
        negative_color: "#1BC5BD",
      };
    case true:
      return {
        negative_label: "Khoá",
        negative_backgroundColor: "#E4E5E7",
        negative_color: "#000",
      };
  }
}

export function getSource(info: UserModel | undefined) {
  let src: string | undefined;
  const defaultSrc = info?.extraProperties?.Gender
    ? Images.user.boy
    : Images.user.girl;
  if (info?.publicId && info?.fileExtension) {
    src =
      env.API_CDN_URL.replace(/['"]+/g, "") +
      `file/${info.publicId}${info.fileExtension}`;
  }
  return src ?? defaultSrc;
}
