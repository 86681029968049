import { UploadFile } from "antd/lib/upload/interface";
import { Dayjs } from "dayjs";
import {
  PreservationOfRecordFileType,
  PreservationOfRecordOwnerType,
} from "src/models/PreservationOfRecord";
import { ListRequest, ListResponse } from "src/models/Request";

export enum DocumentStatus {
  Good = 1,
  Damage = 2,
  Broken = 3,
}

export const documentStatuses = Object.values(DocumentStatus).filter(
  (type) => typeof type !== "string"
) as DocumentStatus[];

export function getDocumentStatusText(type: DocumentStatus) {
  switch (type) {
    case DocumentStatus.Good:
      return "Tốt";
    case DocumentStatus.Damage:
      return "Hư hại";
    case DocumentStatus.Broken:
      return "Hỏng";
  }
}

export interface Document<
  TimeType extends any = Dayjs,
  FileType extends any = UploadFile[]
> {
  id: string;
  content: string;
  note: string;
  files: FileType;
  status: DocumentStatus;
  code: string;
  documentType: PreservationOfRecordFileType;
  author: string;
  dateIssued?: TimeType;
  numberOfCopies: string;
  sheetNumber: string;
  preservationOfRecordId: string;
  archivesId?: string;
  displayOrder?: number;
  storageTimeType?: number;
  storageBoxName?: string;
  fileNumber?: string;
}

export type DocumentRequest = Document<string, string>;

export type BatchCreateDocumentRequest = { documentDtos: DocumentRequest[] };

export interface DocumentResponse {
  no: number;
  creationTime: string;
  isFileDownload: boolean;
  displayOrder?: number;
  document: Document<string, string>;
}
export interface DocumentRequestGetList extends ListRequest {
  Filter?: string;
  ArchivesIdFilter?: string;
  fromTime?: string | Dayjs;
  documentTypeFilter?: number;
}

export type ListDocumentResponse = ListResponse<DocumentResponse>;

export interface ListDocumentRequest extends ListRequest {
  documentTypeFilter?: number;
  fromTime?: string | Dayjs;
  preservationOfRecordFilter: string;
}
export interface ListExportDocument {
  documentTypeFilter?: number;
  Filter?: string;
  ArchivesIdFilter?: string;
  preservationOfRecordFilter?: string;
  FromFileNumber?: number;
  ToFileNumber?: number;
  DocumentTypeFilter?: number;
  FromTime?: string | Dayjs;
  ToTime?: string | Dayjs;
  SheetNumberFilter?: string;
  PreservationOfRecordId?: string;
}

export interface ImportRequest {
  file: File;
  preservationOfRecordId: string;
  ownerType: PreservationOfRecordOwnerType;
  ArchiveId?: string;
}

export interface ImportResponse {
  success: boolean;
  message: null | string[];
  result: {
    stt: number;
    preservationOfRecordId: string;
    documentId: string;
    success: boolean;
    error: string[];
  }[];
  totalSuccess: number;
  totalFail: number;
  importType: string;
}
