import { Select as AntSelect } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { SelectProps as AntSelectProps, SelectValue } from "antd/lib/select";
import _, { uniqBy } from "lodash";
import React, {
  ForwardedRef,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useDidUpdate, usePrevious } from "src/core/hooks";
import useDebounceFn from "src/core/hooks/useDebounceFn";
import useDebounceState from "src/core/hooks/useDebounceState";
import { useToggle } from "src/core/hooks/useToggle";

interface SelectProps<VT extends SelectValue = SelectValue>
  extends AntSelectProps<VT> {
  size?: SizeType;
}

const Select = ({ size = "middle", ...props }: SelectProps) => {
  return <AntSelect {...props} size={size} />;
};

Select.Option = AntSelect.Option;
Select.OptGroup = AntSelect.OptGroup;

export type OptionType = {
  value: string | number;
  label: string;
  additional_data?: any;
  disabled?: boolean;
};

export interface SelectFetchResponse {
  hasMore?: boolean;
  options: OptionType[];
}

export interface SelectFetchFunc {
  (
    searchText: string,
    page: number,
    extra: any,
    dependencies: any[]
  ): Promise<SelectFetchResponse>;
}

interface AjaxSelectProps extends Omit<SelectProps, "onChange" | "options"> {
  fetchFunc: SelectFetchFunc;
  renderOption?: (record: OptionType, index: number) => ReactNode;
  extra?: any;
  dependencies?: any[];
  dependenciesWait?: number;
  onFetched?: (
    response: SelectFetchResponse,
    page: number,
    extra: any,
    dependencies: any[]
  ) => void;
  onDependenciesChanged?: (oldDependencies: any[], dependencies: any[]) => void;
  onChange?: (value?: any, option?: OptionType) => void;
  disabledOptions?: (string | number)[];
  extraOptions?: OptionType[];
}

export interface AjaxSelectHandlers {
  reloadData: () => void;
}

Select.Ajax = forwardRef(
  (
    {
      fetchFunc,
      renderOption,
      dependencies = [],
      dependenciesWait = 500,
      extra,
      onFetched,
      onDependenciesChanged,
      onChange,
      disabledOptions,
      extraOptions,
      ...props
    }: AjaxSelectProps,
    ref: ForwardedRef<AjaxSelectHandlers>
  ) => {
    const [options, setOptions] = useState<OptionType[]>([]);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useDebounceState("", { wait: 500 });
    const [hasMore, setHasMore] = useState(true);
    const { state: loading, on: startLoading, off: stopLoading } = useToggle();
    const oldDependencies = usePrevious(dependencies);
    const canFetch = useMemo(() => {
      return !loading && hasMore;
    }, [loading, hasMore]);

    const updateStatesFromFetchResponse = useCallback(
      (fetchResponse: SelectFetchResponse) => {
        setOptions((state) => {
          if (page === 1) {
            return fetchResponse.options;
          }
          return [...state, ...fetchResponse.options];
        });
        setHasMore(fetchResponse.hasMore || false);

        onFetched && onFetched(fetchResponse, page, extra, dependencies);
      },
      [page, extra, dependencies, onFetched]
    );

    const fetchData = useCallback(async () => {
      startLoading();
      try {
        updateStatesFromFetchResponse(
          await fetchFunc(searchText, page, extra, dependencies)
        );
      } catch (error) {
        updateStatesFromFetchResponse({
          hasMore: false,
          options: [],
        });
      }
      stopLoading();
    }, [page, searchText, canFetch, dependencies, extra]);

    const { run: reloadData } = useDebounceFn(
      useCallback(() => {
        if (searchText !== "" || page !== 1) {
          setPage(1);
          setSearchText("");
        } else {
          fetchData();
        }
      }, [fetchData]),
      { wait: dependenciesWait }
    );
    const onFocus = useCallback(async () => {
      if (options.length || !canFetch) {
        return;
      }
      setPage(1);
      fetchData();
    }, [options, fetchData, canFetch]);

    const onSearch = useCallback((text: string) => {
      setSearchText(text);
      setPage(1);
    }, []);

    const onPopupScroll = useCallback(
      async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        if (loading) {
          return;
        }

        const target = e.nativeEvent.target as HTMLDListElement;
        if (target.scrollHeight - target.scrollTop <= target.clientHeight * 2) {
          setPage((state) => state + 1);
        }
      },
      [loading, options, fetchFunc]
    );

    useEffect(() => {
      if (canFetch) {
        fetchData();
      }
    }, [page]);

    useEffect(() => {
      setHasMore(true);
      fetchData();
    }, [searchText]);

    useDidUpdate(() => {
      onDependenciesChanged &&
        onDependenciesChanged(oldDependencies, dependencies);
      setHasMore(true);
      reloadData();
    }, [...(dependencies || [])]);

    useImperativeHandle(
      ref,
      () => ({
        reloadData,
      }),
      [reloadData]
    );

    // Handle change
    const handleChange = useCallback(
      (value: any) => {
        if (!onChange) {
          return;
        }

        const option = options.find((opt) => opt.value === value);
        onChange(value, option);
      },
      [options, onChange]
    );

    // Handle disabled options
    const combinedOptions = useMemo(() => {
      const result: OptionType[] = options.map((option) => ({
        ...option,
        disabled: disabledOptions?.includes(option.value),
      }));

      if (extraOptions && searchText.length === 0) {
        result.push(...extraOptions);
      }

      return uniqBy(result, "value");
    }, [options, disabledOptions, extraOptions, searchText]);

    return (
      <Select
        {...props}
        options={renderOption ? undefined : combinedOptions}
        onFocus={onFocus}
        loading={loading}
        onChange={handleChange}
        onPopupScroll={onPopupScroll}
        onSearch={onSearch}
        showSearch={props.showSearch ?? true}
        filterOption={false}
      >
        {renderOption
          ? _.uniqBy(combinedOptions, "value").map((option, index) => {
              return (
                <Select.Option
                  key={index}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {renderOption(option, index)}
                </Select.Option>
              );
            })
          : null}
      </Select>
    );
  }
);

export default Select;
