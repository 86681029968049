import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import {
  DataRequestFormRequest,
  ExportDataRequestFormRequest,
  ListDataRequestFormRequest,
} from "src/models/DataRequestForm";
import DataRequestFormRepository from "src/repositories/DataRequestFormRepository";

const dataRequestFormModule = createReduxObservableModule(
  {
    getList: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ListDataRequestFormRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DataRequestFormRepository.get(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    getOne: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DataRequestFormRepository.getOne(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    create: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DataRequestFormRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DataRequestFormRepository.create(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    update: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DataRequestFormRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DataRequestFormRepository.update(
            action.payload.id,
            action.payload
          ).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    delete: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DataRequestFormRepository.delete(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    export: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ExportDataRequestFormRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DataRequestFormRepository.export(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    exportToView: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DataRequestFormRepository.exportToView(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "dataRequestForm"
);

export default dataRequestFormModule;
