import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { MenuItem } from "src/models/layout";
import pathMatched from "src/utilities/pathMatched";

const useCurrentRoute = (routes: MenuItem[]) => {
  const [currentRoute, setCurrentRoute] = useState<MenuItem>();
  const location = useLocation();

  const findCurrentRoute = useCallback(
    (curItems: MenuItem[]) => {
      const matchedItems = curItems.filter((item) =>
        pathMatched(location.pathname, item.path)
      );
      for (const matchedItem of matchedItems) {
        if (matchedItem) {
          if (matchedItem.routes) {
            if (pathMatched(location.pathname, matchedItem.path, true)) {
              setCurrentRoute(matchedItem);
            } else findCurrentRoute(matchedItem.routes);
          } else if (pathMatched(location.pathname, matchedItem.path, true)) {
            setCurrentRoute(matchedItem);
            break;
          }
        }
      }
    },
    [location]
  );

  useEffect(() => {
    findCurrentRoute(routes);
  }, [findCurrentRoute, routes]);

  return currentRoute;
};

export default useCurrentRoute;
