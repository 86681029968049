import directory from "./directory.json";
import gender from "./gender.json";
import agency from "./agency.json";
import product from "./product.json";
import order from "./order.json";

export default {
  directory,
  gender,
  agency,
  product,
  order,
};
