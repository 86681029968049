import dayjs from "dayjs";
import { useCallback, useEffect } from "react";
import { useDidUpdate, useMount, useToggle } from "src/core/hooks";
import useIntervalFn from "src/core/hooks/useIntervalFn";
import useAuth from "src/hooks/modules/useAuth";
import routePaths from "src/routes/paths";

const AuthHandler = () => {
  const { logout, isUser } = useAuth();
  const { state: focused, on: focus, off: blur } = useToggle(true);

  const checkLastUseToLogout = useCallback(() => {
    if (!focused) {
      return;
    }

    const lastUseTime = localStorage.getItem("last-use-time");

    if (dayjs().diff(dayjs(lastUseTime || undefined), "minute") >= 1) {
      if (isUser) {
        location.href = routePaths.Auth.Login;
        logout();
      }
    } else {
      localStorage.setItem("last-use-time", dayjs().format());
    }
  }, [logout, isUser, focused]);

  const refreshlastUseTime = useIntervalFn(checkLastUseToLogout, 10000);

  useDidUpdate(() => {
    if (isUser) {
      localStorage.setItem("last-use-time", dayjs().format());
      refreshlastUseTime.run();
    } else {
      refreshlastUseTime.cancel();
    }
  }, [isUser]);

  useMount(() => {
    checkLastUseToLogout();
  });

  useEffect(() => {
    window.onblur = blur;
    window.onfocus = () => {
      focus();
      localStorage.setItem("last-use-time", dayjs().format());
      refreshlastUseTime.run();
    };

    return () => {
      window.onblur = null;
      window.onblur = null;
    };
  }, [refreshlastUseTime]);

  return <></>;
};

export default AuthHandler;
