import { Tag } from "antd";
import React from "react";

interface Props {
  color?: string;
  children?: React.ReactNode;
  width?: number;
}

const Tags = ({ color, children, width }: Props) => {
  return (
    <Tag
      style={{
        background: color,
        borderRadius: 18,
        textAlign: "center",
        borderWidth: 0,
        paddingRight: 8,
        paddingLeft: 8,
        paddingTop: 2,
        paddingBottom: 2,
        width,
        // margin: "0 auto",
      }}
    >
      {children}
    </Tag>
  );
};

export default Tags;
