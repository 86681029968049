import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { newPasswordRequest, UserNameRequest } from "src/models/auth/auth";
import { accountRepository } from "src/repositories/AccountRepository";

const accountModule = createReduxObservableModule({
  forgotPassword: {
    epic: ({
      actions$,
      actionTypes,
      actions,
    }: ReduxObservableModuleEpicProps<Action<UserNameRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          accountRepository.forgotPassword(action.payload).pipe(
            map((reponse) => actions.success(reponse.data)),
            catchError((error) => {
              return of(actions.failed(error));
            }),
            takeUntil(actions$.pipe(ofType(actionTypes.failed)))
          )
        )
      ),
  },
  newPassword: {
    epic: ({
      actions$,
      actionTypes,
      actions,
    }: ReduxObservableModuleEpicProps<Action<newPasswordRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          accountRepository.newPassword(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => {
              return of(actions.failed(error));
            }),
            takeUntil(actions$.pipe(ofType(actionTypes.failed)))
          )
        )
      ),
  },
  generateCaptcha: ({
    actions$,
    actions,
    actionTypes,
  }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
    actions$.pipe(
      ofType(actionTypes.start),
      mergeMap(() =>
        accountRepository
          .generateCaptcha()
          .pipe(map((response) => actions.success(response.data)))
      )
    ),
});

export default accountModule;
