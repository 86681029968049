// import { lazy } from "react";
import DashboardLayout from "src/layouts/Dashboard";
import { MenuItem } from "src/models/layout";
import AdministrationRoutes from "src/routes/administrationRoutes";
import useAgencyRoutes from "src/routes/agencyRoutes";
import routePaths from "src/routes/paths";
import useScreenRoutes from "src/routes/screenRoutes";

export default (): MenuItem[] => {
  const screenRoutes = useScreenRoutes();
  const agencyRoutes = useAgencyRoutes();
  const phongsRoutes = AdministrationRoutes();
  return [
    {
      path: routePaths.Screen.Index,
      exact: false,
      component: (props: any) => (
        <DashboardLayout {...props} routes={screenRoutes} />
      ),
      routes: screenRoutes,
    },
    {
      path: routePaths.Agency.Index,
      exact: false,
      component: (props: any) => (
        <DashboardLayout {...props} routes={agencyRoutes} />
      ),
      routes: agencyRoutes,
    },
    {
      path: routePaths.Screen.Phongs.Index,
      exact: false,
      component: (props: any) => (
        <DashboardLayout {...props} routes={phongsRoutes} />
      ),
      routes: agencyRoutes,
    },
    // {
    //   path: "/404",
    //   exact: true,
    //   component: lazy(() => import("src/pages/NotFound")),
    // },
    // {
    //   path: "/403",
    //   exact: true,
    //   component: lazy(() => import("src/pages/AccessDenied")),
    // },
  ];
};
