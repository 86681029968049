export interface IListBox {
  archivesId: string;
  id?: string;
  name: string;
  nameUnaccented?: string;
  parentId?: string;
  status: boolean;
  type: number;
  timeType: number;
}

export interface IActionsDeleteOrEdit {
  action: "edit" | "delete" | "create" | "export";
  id?: string | number;
  datas?: IListBox;
}

export const TYPE_BOX = 3;
