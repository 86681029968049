import { lazy } from "react";
import { MenuItem } from "src/models/layout";
import routePaths from "src/routes/paths";

const informationPublicRoutes = (): MenuItem[] => {
  return [
    {
      path: routePaths.InformationPublic.File,
      component: lazy(
        () => import("src/pages/screen/InformationPublic/InformationFile")
      ),
    },
  ];
};

export default informationPublicRoutes;
