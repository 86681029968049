import { Input, InputProps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import useDebounceFn from "src/core/hooks/useDebounceFn";

interface DebounceInputProps extends InputProps {
  wait?: number;
}

const DebounceInput = ({ wait = 500, ...props }: DebounceInputProps) => {
  const [val, setVal] = useState(props.value);

  const { run: onChange } = useDebounceFn(
    useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange && props.onChange(event);
      },
      [props.onChange]
    ),
    { wait }
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setVal(event.target.value);
      onChange(event);
    },
    []
  );

  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  return <Input {...props} value={val} onChange={handleInputChange} />;
};

export default DebounceInput;
