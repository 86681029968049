import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { OrganizationUnitsProps } from "src/models/organizationUnits";
import {
  ExportFileResponse,
  ExportToWordRequest,
  GetAllListReportResponse,
} from "src/models/report";
import { DeleteRequest, ListRequest } from "src/models/Request";
class OrganizationUnitsRepository extends Repository<
  ExportToWordRequest,
  ExportFileResponse
> {
  protected basePath = "api/cms-service/";

  constructor() {
    super(env.API_CMS_URL);
  }

  getOrganizationUnits(params: OrganizationUnitsProps) {
    return this.request<GetAllListReportResponse>({
      method: "GET",
      url: "organization-units/all",
      params,
    });
  }

  deleteOrganizationUnits(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `organization-units/${param.id}`,
    });
  }

  editOrganizationUnits(params: OrganizationUnitsProps) {
    return this.request({
      method: "PUT",
      url: "organization-units",
      data: params,
    });
  }

  addOrganizationUnits(params: OrganizationUnitsProps) {
    return this.request({
      method: "POST",
      url: "organization-units",
      data: params,
    });
  }

  getTableOrganizationUnits(params: OrganizationUnitsProps) {
    return this.request<GetAllListReportResponse>({
      method: "GET",
      url: "organization-units/all",
      params,
    });
  }

  getForLoop(params: ListRequest) {
    return this.request<{ organizationUnit: OrganizationUnitsProps }[]>({
      url: "organization-units/for-loop",
      method: "get",
      params,
    });
  }
}

export const organizationUnitsRepository = new OrganizationUnitsRepository();
