import { memo, useEffect, useRef } from "react";
import RouterGenerator from "src/core/routes/RouterGenerator";
import useAuth from "src/hooks/modules/useAuth";
import RoleRepository from "src/repositories/RoleRepository";
import useAuthRoutes from "src/routes/authRoutes";
import informationPublicRoutes from "src/routes/informationPublicRoutes";
import getListRoute from "src/routes/listRoutes";
import routePaths from "src/routes/paths";

const AppRoutesBased = () => {
  const { isUser } = useAuth();
  useEffect(() => {
    RoleRepository.getRoleUser()
      .toPromise()
      .then(({ data }) => {
        if (data.length > 0) {
          data.forEach((subData: any) => {
            if (subData.roleName === "admin") {
              localStorage.setItem("isRoleAdmin", "true");
            } else {
              localStorage.removeItem("isRoleAdmin");
            }
          });
        }
      })
      .catch((err) => console.log("err", err));
  }, [isUser]);

  const guestRoutes = useRef(useAuthRoutes()).current;
  const routes = useRef(getListRoute()).current;
  const publicRoutes = useRef(informationPublicRoutes()).current;

  return (
    <RouterGenerator
      userRoutes={routes}
      guestRoutes={guestRoutes}
      publicRoutes={publicRoutes}
      initialGuestRoutePath={routePaths.Auth.Index}
      initialRoutePath={routePaths.Auth.Index}
      initialUserRoutePath={routePaths.Screen.Index}
      isUser={isUser}
    />
  );
};

export const AppRoutes = memo(AppRoutesBased);
