import { useTranslation } from "react-i18next";
import Images from "src/assets/images";
import ErrorPage from "src/components/ErrorPage";

interface Props {
  width?: string | number;
  height?: string | number;
}

const AccessDenied = (props: Props) => {
  const { t } = useTranslation("errors");
  return (
    <ErrorPage
      title="403"
      subTitle={t("access_denied")}
      description={t("access_denied_description")}
      image={Images.accessDenied}
      {...props}
    />
  );
};

export default AccessDenied;
