import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import {
  createDeliveryReportRequest,
  getAllDeliveryReportRequest,
  updateDeliveryReportRequest,
} from "src/models/phongs/DeliveryReport";
import { DeleteRequest } from "src/models/Request";
import { deliveryReportRepository } from "src/repositories/DeliveryReportRepository";

const directorysModule = createReduxObservableModule(
  {
    addNewCategory: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<createDeliveryReportRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            deliveryReportRepository.createDeliveryReport(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
    getAll: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<getAllDeliveryReportRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            deliveryReportRepository.getAll(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
    delete: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DeleteRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            deliveryReportRepository.deleteDeliveryReport(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
    update: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<updateDeliveryReportRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            deliveryReportRepository.updateDeliveryReport(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    // getCategoryNames: {
    //   epic: ({
    //     actions$,
    //     actionTypes,
    //     actions,
    //   }: ReduxObservableModuleEpicProps<Action<GetCategoryNamesRequest>>) =>
    //     actions$.pipe(
    //       ofType(actionTypes.start),
    //       mergeMap((action) =>
    //         directorysRepository.getCategoryNames(action.payload).pipe(
    //           map((response) => actions.success(response.data)),
    //           catchError((error) => {
    //             return of(actions.failed(error));
    //           }),
    //           takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
    //         )
    //       )
    //     ),
    // },
  },
  "DELIVERYREPORT"
);

export default directorysModule;
