import { DeploymentUnitOutlined } from "@ant-design/icons";
import { Avatar, Col, Drawer, Dropdown, Layout, Menu, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Images from "src/assets/images";
// import Logo from "src/assets/images/logo.png";
import HeadBg1 from "src/assets/images/bgHead1.png";
import NewLogo from "src/assets/images/lgLTVN.png";
import { Button, Row } from "src/components";
import Permissions from "src/constants/permessions";
import { PrivateView } from "src/core/permission/PrivateView";
import history from "src/core/routes/history";
import useAuth from "src/hooks/modules/useAuth";
import useLayoutConfig from "src/hooks/useLayoutConfig";
import UserProfileDrawer from "src/layouts/Component/Header/UserProfileDrawer";
import { getSource } from "src/models/Status";
import BoxProfile from "src/pages/screen/Phongs/BoxProfile";
import { RootReducer } from "src/redux";
import routePaths from "src/routes/paths";
import "src/styles/auth/logout.less";

const { Header } = Layout;

const LayoutHeader = () => {
  const isRoleAdmin = localStorage.getItem("isRoleAdmin");
  const isAdmin = isRoleAdmin !== null;
  const { profile, fetchUserProfile } = useAuth();
  const [visible, setVisible] = useState(false);
  const [subvisible, setSubVisible] = useState(false);
  const [loading, setloading] = useState(false);
  const [isModalBoxVisible, setIsModalBoxVisible] = useState(false);
  const phong = useSelector(
    (rootState: RootReducer) => rootState.phongApp.phongSelected
  );

  const { openModalSelectPhong } = useLayoutConfig();

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        fetchUserProfile();
      }, 500);
    } else {
      fetchUserProfile();
    }
    setloading(false);
  }, [loading]);

  const logOut = () => {
    localStorage.clear();
    window.location.href = routePaths.Auth.Index;
  };

  const checkOf = (i: boolean) => {
    setSubVisible(i);
  };

  const checkNewLoad = (value: boolean) => {
    setloading(value);
  };

  const checkNewAvatar = (values: any) => {
    //  setloading(value);
  };

  const subDrawer = () => {
    return (
      <Drawer
        title="Xem thông tin nhân viên"
        placement="right"
        closable={false}
        onClose={() => setSubVisible(false)}
        visible={subvisible}
        contentWrapperStyle={{
          top: "64px",
          height: 600,
          borderRadius: 10,
          width: 450,
        }}
      >
        <UserProfileDrawer
          info={profile}
          checkOf={checkOf}
          checkNewLoad={checkNewLoad}
          checkNewAvatar={checkNewAvatar}
        />
      </Drawer>
    );
  };

  return (
    <>
      <BoxProfile
        isModalList={isModalBoxVisible}
        handleModalList={() => setIsModalBoxVisible(false)}
      />
      <Header className="site-layout-background">
        <Row
          justify="space-between"
          className="align-items-center header-row-content"
          style={{
            backgroundImage: `url(${HeadBg1})`,
            objectFit: "fill",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginLeft: -16,
          }}
        >
          <Col>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Avatar size={50} src={NewLogo} />
              <Typography.Title level={4} className="px-3 pt-2 title-brand">
                HỆ THỐNG QUẢN LÝ LƯU TRỮ TÀI LIỆU
              </Typography.Title>
            </div>
          </Col>
          <Col style={{ height: "100%" }}>
            <Row style={{ height: "100%" }}>
              <Col>
                <div className="flex align-items-center h-full flex-col">
                  <div
                    className={`flex align-items-center justify-content-end w-full ${
                      !phong ? "h-full" : ""
                    }`}
                    style={{ marginTop: "0.5em" }}
                  >
                    <Typography.Text
                      type="secondary"
                      style={{ color: "#ffff", fontSize: "12px" }}
                    >
                      Chào bạn,
                    </Typography.Text>
                    <Typography.Title
                      level={5}
                      className="title-spacing header-username"
                    >
                      {profile?.account?.name ?? ""}
                    </Typography.Title>
                  </div>
                  {phong && (
                    <div className="mt-4">
                      <Dropdown
                        overlay={
                          <Menu>
                            {isAdmin && (
                              <Menu.Item onClick={openModalSelectPhong}>
                                Thay Phông
                              </Menu.Item>
                            )}
                            <Menu.Item
                              onClick={() =>
                                history.push({
                                  pathname:
                                    routePaths.Screen.Phongs.managerArchives
                                      .Details,
                                  state: phong.id,
                                })
                              }
                            >
                              <div>Thông tin Phông</div>
                            </Menu.Item>
                            <Menu.Item
                              onClick={() => setIsModalBoxVisible(true)}
                            >
                              <div>Danh sách hộp số</div>
                            </Menu.Item>
                          </Menu>
                        }
                        trigger={["click"]}
                        placement="bottomCenter"
                      >
                        <Typography.Text className="text-phong-selected">
                          <DeploymentUnitOutlined />
                          <span className="ml-4">{phong?.name}</span>
                        </Typography.Text>
                      </Dropdown>
                    </div>
                  )}
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100%", paddingRight: 16 }}
              >
                <div
                  onClick={() => {
                    // fetchUserProfile();
                    setVisible(true);
                  }}
                  className="cursor-pointer"
                >
                  <Avatar size="large" src={getSource(profile?.account)} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        placement="right"
        className="logout"
        closable={false}
        bodyStyle={{ background: "#E5F7F8", borderRadius: 10 }}
        drawerStyle={{ borderRadius: 10 }}
        style={{ borderRadius: 10 }}
        contentWrapperStyle={{
          top: "64px",
          height: 230,
        }}
      >
        <div>
          {profile?.account.fileExtension ? (
            <Avatar
              className="centerImage"
              shape="square"
              size={64}
              src={getSource(profile?.account)}
              style={{ backgroundColor: "var(--light-color)" }}
            />
          ) : (
            <>
              {profile?.account?.extraProperties?.Gender ? (
                <Avatar
                  className="centerImage"
                  shape="square"
                  src={Images.user.boy}
                  size={64}
                  style={{ backgroundColor: "var(--light-color)" }}
                  // className="p-1"
                />
              ) : (
                <Avatar
                  className="centerImage"
                  shape="square"
                  size={64}
                  src={Images.user.girl}
                  style={{ backgroundColor: "var(--light-color)" }}
                />
              )}
            </>
          )}
        </div>
        <div>
          <Typography.Title level={5} style={{ textAlign: "center" }}>
            {profile ? profile?.account?.name : ""}
          </Typography.Title>
          <PrivateView permissions={Permissions.Auth.ProfileManage.View}>
            <Button
              type="primary"
              style={{ background: "#DD0000" }}
              className="centerButton"
              onClick={() => {
                setSubVisible(true);
                setVisible(false);
              }}
            >
              Trang cá nhân
            </Button>
          </PrivateView>
        </div>
        <div>
          <Button onClick={() => logOut()} className="centerButton">
            Đăng xuất
          </Button>
        </div>
      </Drawer>
      {subDrawer()}
    </>
  );
};

export default LayoutHeader;
