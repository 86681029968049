import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import {
  CirculationDocumentRequest,
  ListCirculationDocumentRequest,
} from "src/models/CirculationDocument";
import CirculationDocumentRepository from "src/repositories/CirculationDocumentRepository";

const circulationDocumentModule = createReduxObservableModule(
  {
    getList: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<
      Action<ListCirculationDocumentRequest>
    >) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          CirculationDocumentRepository.get(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    getOne: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          CirculationDocumentRepository.getOne(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    create: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<CirculationDocumentRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          CirculationDocumentRepository.create(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    delete: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          CirculationDocumentRepository.delete(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "circulationDocument"
);

export default circulationDocumentModule;
