import env from "src/core/env";
import { Repository } from "src/core/Repository";

class FileRepository extends Repository {
  constructor() {
    super(env.API_CDN_URL.replace(/['"]+/g, ""));
  }

  delete(publicId: string) {
    return this.api.post("file/delete", { publicId });
  }
}

export default new FileRepository();
