import { Repository } from "src/core/Repository";
import {
  GenerateCaptchaResponse,
  LoginRequest,
  newPasswordRequest,
  UserNameRequest,
} from "src/models/auth/auth";

class AccountRepository extends Repository {
  protected basePath = "api/";

  login(account: LoginRequest) {
    return this.request({
      method: "POST",
      url: "auth/account/token",
      data: account,
    });
  }

  forgotPassword(username: UserNameRequest) {
    return this.request({
      method: "POST",
      url: "auth/account/forgot-password",
      data: username,
    });
  }

  newPassword(params: newPasswordRequest) {
    return this.request({
      method: "POST",
      url: "account/reset-password",
      data: params,
    });
  }

  generateCaptcha() {
    return this.request<GenerateCaptchaResponse>({
      method: "POST",
      url: "auth/account/generate-captcha-image",
    });
  }
}

export const accountRepository = new AccountRepository();
