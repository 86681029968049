import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { getAllDeliveryReportRequest } from "src/models/phongs/DeliveryReport";
import { classifyReportRepository } from "src/repositories/ClassifyReportRepostitory";

const classifyReportModules = createReduxObservableModule(
  {
    getAll: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<getAllDeliveryReportRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            classifyReportRepository.getAll(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
    exportClassify: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<string>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            classifyReportRepository.exportClassify(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
  },
  "CLASSIFYREPORT"
);

export default classifyReportModules;
