import { Modal, ModalProps, Pagination, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Table, Tags } from "src/components";
import { OptionType } from "src/components/Select";
import { useDidUpdate, useQuery } from "src/core/hooks";
import { Action } from "src/core/models/redux";
import { ReduxObservableModuleReducer } from "src/core/redux/ReduxObservableModule";
import { Archives, ResponseArchives, getAllPhongs } from "src/models/phongs";
import { RootReducer } from "src/redux";
import phongsModule from "src/redux/modules/phongs";
import { getPageNumber } from "src/utilities/tableHelper";

interface ModalSelectPhongsProps extends Omit<ModalProps, "onOk"> {
  PhongsReducer: ReduxObservableModuleReducer<any>;
  getAllPhongs: (params: getAllPhongs) => any;
  onConfirm?: (phong: Archives) => void;
}

const ModalSelectPhongs = ({
  PhongsReducer,
  getAllPhongs,
  ...props
}: ModalSelectPhongsProps) => {
  const { Column } = Table;
  const { Text } = Typography;
  const [data, setData] = useState<Array<ResponseArchives> | []>([]);
  const MaxResultCount = 50;
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const phongStore = useSelector(
    (rootState: RootReducer) => rootState.phongApp.phongSelected
  );
  const [selectedPhongs, setSelectedPhongs] = useState<Archives | undefined>(
    phongStore ?? undefined
  );
  const { lg } = useBreakpoint();
  const { query, setQuery } = useQuery<getAllPhongs>({
    Page: String(currentPage),
    MaxResultCount: String(MaxResultCount),
  });

  const dataTable = useMemo(() => {
    const listDataTable: Array<Archives> = [];
    data.forEach((item: ResponseArchives) => {
      if (item.archives) {
        listDataTable.push(item.archives);
      }
    });
    return listDataTable;
  }, [data]);

  const handleChangePage = (page: number) => {
    setLoadingTable(true);
    setCurrentPage(page);
    setQuery({
      ...query,
      Page: String(page),
    });
  };

  const optionFilterStatus: Array<OptionType> = [
    { value: -1, label: "Tất cả" },
    { value: 0, label: "Khoá" },
    { value: 1, label: "Hoạt động" },
  ];

  const statusGetAll: number = parseInt(optionFilterStatus[0].value.toString());
  const fetchListOfRecords = useCallback(() => {
    const skipcount = Number(query.Page) * MaxResultCount - MaxResultCount;
    query.filter = "";
    const param = {
      ...query,
      SkipCount: skipcount,
      StatusFilter: query.StatusFilter
        ? Number(query.StatusFilter)
        : statusGetAll,
    };
    getAllPhongs(param as any);
  }, [query]);

  useDidUpdate(() => {
    const { state } = PhongsReducer;
    const {
      reducerStates: { getTablePhongs, deletePhongs, editPhongs },
    } = phongsModule;
    const dataSource: any[] = [];
    switch (state) {
      case getTablePhongs.processing:
        setLoadingTable(true);
        break;
      case getTablePhongs.success:
        PhongsReducer.data.items.forEach((item: Archives) => {
          dataSource.push(item);
        });
        setTotalCount(PhongsReducer.data.totalCount);
        setData(dataSource);
        setLoadingTable(false);
        break;
      case getTablePhongs.failed:
        setLoadingTable(false);
        break;
    }
  }, [PhongsReducer.state]);

  useEffect(() => {
    if (props.visible) {
      fetchListOfRecords();
    }
  }, [query, props.visible]);

  const handleOk = useCallback(() => {
    if (selectedPhongs && props.onConfirm) {
      props.onConfirm(selectedPhongs);
    }
  }, [selectedPhongs]);
  return (
    <Modal
      width={lg ? 920 : "80%"}
      {...props}
      onOk={handleOk}
      okButtonProps={{
        disabled: !selectedPhongs,
      }}
      cancelButtonProps={{
        hidden: true,
      }}
    >
      <div>
        <Table
          dataSource={dataTable}
          pagination={false}
          size="small"
          striped
          bordered
          loading={loadingTable}
          rowKey="id"
          rowSelection={{
            type: "radio",
            selectedRowKeys: selectedPhongs ? [selectedPhongs.id] : undefined,
            onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
              setSelectedPhongs(selectedRows[0]);
            },
          }}
        >
          <Column
            title="STT"
            dataIndex="no"
            render={(_: string, __: any, index: number) =>
              (currentPage - 1) * MaxResultCount + index + 1
            }
            align="center"
          />
          <Column title="Số phông" dataIndex="numberOrder" align="center" />
          <Column title="Tên phông lưu trữ" dataIndex="name" align="center" />

          <Column
            title="Trạng thái"
            dataIndex="status"
            align="center"
            render={(text: string, record: any) => {
              return (
                <>
                  {record.status ? (
                    <Tags color="#C9F7F5" width={70}>
                      <Text style={{ color: "#1BC5BD" }}>Hoạt động</Text>
                    </Tags>
                  ) : (
                    <Tags color="#E4E5E7" width={70}>
                      Khoá
                    </Tags>
                  )}
                </>
              );
            }}
          />
        </Table>
        {data.length > 0 && (
          <Pagination
            onChange={handleChangePage}
            current={currentPage}
            total={totalCount}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} của ${getPageNumber(
                total,
                MaxResultCount
              )} trang`
            }
            showSizeChanger={false}
            showQuickJumper={false}
            pageSize={MaxResultCount}
            className="d-flex justify-content-start mt-4"
            size="small"
          />
        )}
      </div>
    </Modal>
  );
};

const mapStateToProp = (state: RootReducer) => {
  return {
    PhongsReducer: state.phongsModule,
    archivesReducer: state.archives,
  };
};

const mapDispatchToProps = (dispatch: (arg0: Action<any>) => any) => ({
  getAllPhongs: (param: getAllPhongs) =>
    dispatch(phongsModule.actions.getTablePhongs.start(param)),
});
export default connect(mapStateToProp, mapDispatchToProps)(ModalSelectPhongs);
