import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { of } from "rxjs";
import { Action } from "src/core/models/redux";
import { OrganizationUnitsProps } from "src/models/organizationUnits";
import { DeleteRequest } from "src/models/Request";
import { organizationUnitsRepository } from "src/repositories/OrganizationUnitsRepository";

const OrganizationUnitsModule = createReduxObservableModule(
  {
    getOrganizationUnits: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<OrganizationUnitsProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            organizationUnitsRepository
              .getOrganizationUnits(action.payload)
              .pipe(
                map((response) => actions.success(response.data)),
                catchError((error) => {
                  return of(actions.failed(error));
                }),
                takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
              )
          )
        ),
    },

    deleteOrganizationUnits: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DeleteRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            organizationUnitsRepository
              .deleteOrganizationUnits(action.payload)
              .pipe(
                map((response) => actions.success(response.data)),
                catchError((error) => {
                  return of(actions.failed(error));
                }),
                takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
              )
          )
        ),
    },

    editOrganizationUnits: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<OrganizationUnitsProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            organizationUnitsRepository
              .editOrganizationUnits(action.payload)
              .pipe(
                map((response) => actions.success(response.data)),
                catchError((error) => {
                  return of(actions.failed(error));
                }),
                takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
              )
          )
        ),
    },

    addOrganizationUnits: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<OrganizationUnitsProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            organizationUnitsRepository
              .addOrganizationUnits(action.payload)
              .pipe(
                map((response) => actions.success(response.data)),
                catchError((error) => {
                  return of(actions.failed(error));
                }),
                takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
              )
          )
        ),
    },
  },
  "ORGANIZATIONUNITS"
);

export default OrganizationUnitsModule;
