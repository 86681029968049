import { PreservationOfRecordType } from "src/models/PreservationOfRecord";
import {
  DocumentRequestType,
  EditOrDetailType,
} from "src/models/ReadersManagementModel";
import { ProvideDataType } from "src/pages/screen/ReadersManagement/ProvideData/constants";

const routePaths = {
  Auth: {
    Index: "/auth",
    Login: "/auth/login",
    ForgotPassword: "/auth/forgot-password",
    NewPassword: "/auth/new-password",
    ChangeSuccesPassword: "/auth/Succes-password",
  },
  InformationPublic: {
    Index: "/digitize/information",
    File: "/:id",
  },
  Screen: {
    Index: "/digitize",
    DetaiProfileExpired:
      "/digitize/detail-profile-expired/:id/:action(view|edit)",
    Directory: {
      Index: "/digitize/directory",
      Directory: {},
      Warehose: {
        Index: "/digitize/warehose",
      },
    },
    Readers: {
      Index: "/digitize/readers",
      RegistrationList: {
        Index: "/digitize/readers/registration-list",
        Add: "/digitize/readers/registration-list/add",
        Edit: "/digitize/readers/registration-list/:id",
      },
      RequestInformation: {
        Index: "/digitize/readers/request-information",
        Add:
          "/digitize/readers/request-information/add/:type(" +
          Object.values(DocumentRequestType).join("|") +
          ")",
        Edit:
          "/digitize/readers/request-information/:type(" +
          Object.values(DocumentRequestType).join("|") +
          ")/:view(" +
          Object.values(EditOrDetailType).join("|") +
          ")?=:id",
      },
      ProvideData: {
        Index: "/digitize/readers/provide-data",
        Create:
          "/digitize/readers/provide-data/create/:type(" +
          Object.values(ProvideDataType).join("|") +
          ")",
        Detail:
          "/digitize/readers/provide-data/:type(" +
          Object.values(ProvideDataType).join("|") +
          ")/:id/:action(view|edit)",
      },
    },
    Report: {
      Index: "/digitize/report",
      StorageStatus: {
        Index: "/digitize/report/report-status",
      },
      StorageWork: {
        Index: "/digitize/report/report-work",
      },
    },
    Administration: {
      Index: "/digitize/administration",
      Categories: "/digitize/administration/Categories",
      Warehose: {
        Index: "/digitize/administration/warehose",
      },
      User: {
        Index: "/digitize/administration/user",
        Add: "/digitize/administration/user/add",
        Edit: "/digitize/administration/user/edit",
      },
      OrganizationUnits: {
        Index: "/digitize/administration/organizationUnits",
      },
      Role: {
        Index: "/digitize/administration/role",
      },
    },
    Search: {
      Index: "/digitize/advancedSearch",
      Profile: {
        Index: "/digitize/advancedSearch/Profile",
        DetailsProfile:
          "/digitize/advancedSearch/Profile/DetailsProfile/:id/:action(view|edit)",
      },
      Text: {
        Index: "/digitize/advancedSearch/Document",
        DetailsDocument:
          "/digitize/advancedSearch/Document/DetailsDocument/:id/document/:documentId",
      },
      Fonts: {
        Index: "/digitize/advancedSearch/Fonts",
        DetailsFonts: "/digitize/advancedSearch/Fonts/DetailsFonts",
      },
      Reader: {
        Index: "/digitize/advancedSearch/Reader",
        RegistrationDetails: {
          Index: "/digitize/advancedSearch/Reader/RegistrationDetails/:id",
        },
        RequestDatails: {
          Index:
            "/digitize/advancedSearch/Reader/RequestDatails/:type(" +
            Object.values(DocumentRequestType).join("|") +
            ")/:view(" +
            Object.values(EditOrDetailType).join("|") +
            ")?=:id",
        },
        ProvideDetails: {
          Index:
            "/digitize/advancedSearch/Reader/ProvideDetails/:type(" +
            Object.values(ProvideDataType).join("|") +
            ")/:id/:action(view|edit)",
        },
      },
    },
    Categories: {
      Index: "/digitize/categories",
      Warehouse: "/digitize/categories/warehouse",
    },
    User: {
      Index: "/digitize/user",
      Staff: {
        Index: "/digitize/user/staff",
        Add: "/digitize/user/staff/add",
        Edit: "/digitize/user/staff/:id/edit",
      },
      Agency: {
        Index: "/digitize/user/agency",
        Add: "/digitize/user/agency/add",
        Edit: "/digitize/user/agency/edit",
      },
    },
    ListDocument: {
      Index: "/digitize/document/view",
    },
    Phongs: {
      Index: "/digitize/phongs",
      managerArchives: {
        Index: "/digitize/phongs/managerArchives",
        document: "/digitize/phongs/managerArchives/document",
        Add: "/digitize/phongs/managerArchives/add",
        Edit: "/digitize/phongs/managerArchives/edit",
        Details: "/digitize/phongs/managerArchives/details",
        Box: "/digitize/phongs/managerArchives/Box",
      },
    },
    PhongsDocument: {
      Index: "/digitize/phongs-document",
      NewsletterForm: {
        Index: "/digitize/phongs-document/newsletter-form",
        Add: "/digitize/phongs-document/newsletter-form/add",
        Edit: "/digitize/phongs-document/newsletter-form/edit",
      },

      HandoverDocument: {
        Index: "/digitize/phongs-document/handover-document",
        Add: "/digitize/phongs-document/handover-document/add",
        Edit: "/digitize/phongs-document/handover-document/edit",
        Delete: "/digitize/phongs-document/handover-document/delete",
        Details: "/digitize/phongs-document/handover-document/details",
      },
      EditPlantDocument: {
        Index: "/digitize/phongs-document/edit-plant-document",
        Add: "/digitize/phongs-document/edit-plant-document/add",
        Edit: "/digitize/phongs-document/edit-plant-document/edit",
        Delete: "/digitize/phongs-document/edit-plant-document/delete",
        Details: "/digitize/phongs-document/edit-plant-document/details",
      },
      DetermineDocument: {
        Index: "/digitize/phongs-document/determine-document",
        Add: "/digitize/phongs-document/determine-document/add",
        Edit: "/digitize/phongs-document/determine-document/edit",
        Delete: "/digitize/phongs-document/determine-document/delete",
        Details: "/digitize/phongs-document/determine-document/details",
      },
      ClassifyCreateFile: {
        Index: "/digitize/phongs-document/classify-create-file",
        Add: "/digitize/phongs-document/classify-create-file/add",
        Edit: "/digitize/phongs-document/classify-create-file/edit",
        Delete: "/digitize/phongs-document/classify-create-file/delete",
        Details: "/digitize/phongs-document/classify-create-file/details",
      },
      HistoryPhongs: {
        Index: "/digitize/phongs-document/history-phongs",
        Add: "/digitize/phongs-document/history-phongs/add",
        Edit: "/digitize/phongs-document/history-phongs/edit",
        Delete: "/digitize/phongs-document/history-phongs/delete",
        Details: "/digitize/phongs-document/history-phongs/details",
      },
      FinalReport: {
        Index: "/digitize/phongs-document/final-report",
        Add: "/digitize/phongs-document/final-report/add",
        Edit: "/digitize/phongs-document/final-report/edit",
        Delete: "/digitize/phongs-document/final-report/delete",
        Details: "/digitize/phongs-document/final-report/details",
      },
    },

    PhongsProfile: {
      Index: "/digitize/phongs-profile",
      DocumentPermanent: "/digitize/phongs-profile/permanent",
      DocumentDeadline: "/digitize/phongs-profile/documentDeadline",
      PreservationRecord: "/digitize/phongs-profile/preservationRecord",
      ListDocument: "/digitize/phongs-profile/listDocument",
    },

    Product: {
      Index: "/digitize/product",
      Category: {
        Index: "/digitize/product/category",
      },
      Attribute: {
        Index: "/digitize/product/attribute",
      },
      Review: {
        Index: "/digitize/product/review",
      },
      Tag: {
        Index: "/digitize/product/tag",
      },
    },

    PreservationOfRecord: {
      Index: "/digitize/phongs-profile/preservation-of-record",
      FileType: {
        Index: "/digitize/phongs-profile/preservation-of-record/file-type",
        File: {
          Index:
            "/digitize/phongs-profile/preservation-of-record/file-type/file",
          Detail:
            "/digitize/phongs-profile/preservation-of-record/file-type/file/detail-profile-expired/:id/:action(view|edit)",
          Create:
            "/digitize/phongs-profile/preservation-of-record/file-type/file/create/:type(" +
            Object.values(PreservationOfRecordType).join("|") +
            ")",
        },
        ListDocument:
          "/digitize/phongs-profile/preservation-of-record/file-type/document",
        CategoryClassifyDocument: {
          Index:
            "/digitize/phongs-profile/preservation-of-record/file-type/category-classify-document",
          Add:
            "/digitize/phongs-profile/preservation-of-record/file-type/category-classify-document/add",
          Edit:
            "/digitize/phongs-profile/preservation-of-record/file-type/category-classify-document/edit",
        },
      },
      Family: {
        Index: "/digitize/phongs-profile/preservation-of-record/family",
        Create:
          "/digitize/phongs-profile/preservation-of-record/family/create/:type(" +
          Object.values(PreservationOfRecordType).join("|") +
          ")",
        Detail:
          "/digitize/phongs-profile/preservation-of-record/family/:id/:action(view|edit)",

        CirculationDocument: {
          Index:
            "/digitize/phongs-profile/preservation-of-record/family/circulation-document",
          Create:
            "/digitize/phongs-profile/preservation-of-record/family/circulation-document/create",
          View:
            "/digitize/phongs-profile/preservation-of-record/family/circulation-document/:id",
        },
      },
      Organization: {
        Index: "/digitize/phongs-profile/preservation-of-record/organization",
        ListDocument:
          "/digitize/phongs-profile/preservation-of-record/organization/document",
        File: {
          Index:
            "/digitize/phongs-profile/preservation-of-record/organization/file",
          Create:
            "/digitize/phongs-profile/preservation-of-record/organization/file/create/:type(" +
            Object.values(PreservationOfRecordType).join("|") +
            ")",
          Detail:
            "/digitize/phongs-profile/preservation-of-record/organization/file/:id/:action(view|edit)",
          CirculationDocument: {
            Index:
              "/digitize/phongs-profile/preservation-of-record/organization/file/circulation-document",
            Create:
              "/digitize/phongs-profile/preservation-of-record/organization/file/circulation-document/create",
            View:
              "/digitize/phongs-profile/preservation-of-record/organization/file/circulation-document/:id",
          },
        },
      },
      DocumentDeadline: {
        Index:
          "/digitize/phongs-profile/preservation-of-record/document-deadline",
        ListDocument:
          "/digitize/phongs-profile/preservation-of-record/document-deadline/document",
        File: {
          Index:
            "/digitize/phongs-profile/preservation-of-record/document-deadline/file",
          Create:
            "/digitize/phongs-profile/preservation-of-record/document-deadline/file/create/:type(" +
            Object.values(PreservationOfRecordType).join("|") +
            ")",
          Detail:
            "/digitize/phongs-profile/preservation-of-record/document-deadline/file/:id/:action(view|edit)",
        },
      },
      DocumentDetail:
        "/digitize/phongs-profile/preservation-of-record/:id/document/:documentId",
    },
  },

  Agency: {
    Index: "/agency",
  },
};

export default routePaths;
