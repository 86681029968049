import { PlusOutlined } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import Permissions from "src/constants/permessions";
import env from "src/core/env";
import { useDidUpdate, useLoading } from "src/core/hooks";
import { Action } from "src/core/models/redux";
import { PrivateView } from "src/core/permission/PrivateView";
import { ReduxObservableModuleReducer } from "src/core/redux/ReduxObservableModule";

import {
  OptionType,
  SelectFetchFunc,
  SelectFetchResponse,
} from "src/components/Select";
import {
  ArchivesEditRequest,
  getTableArchives,
  PostProfileArchives,
} from "src/models/archives";
import { IActionsDeleteOrEdit, TYPE_BOX } from "src/models/phongs/boxInPhongs";
import { DeleteRequest, statusArchives } from "src/models/Request";
import { RootReducer } from "src/redux";
import ArcchivesModule from "src/redux/modules/archives";
import { directorysRepository } from "src/repositories/DirectorysRepository";
import BoxCreate from "./components/boxCreate";
import BoxEdit from "./components/boxEdit";
import BoxList from "./components/boxList";
import { handleLogicBox } from "./components/logicBox";

// const { Column } = Table;
const isRoleAdmin = localStorage.getItem("isRoleAdmin");
const isAdmin = isRoleAdmin !== null;
interface Props {
  // data: IListBox[];
  handleModalList: () => void;
  isModalList: boolean;
  actions?: (items: IActionsDeleteOrEdit) => void;
  infoPhong?: any;
  deleteBoxScreen: (params: DeleteRequest) => any;
  getTableBoxScreen: (params: getTableArchives) => any;
  editBoxScreen: (params: ArchivesEditRequest) => void;
  addNewProfileArchivesScreen: (param: PostProfileArchives) => any;
  archivesReducer: ReduxObservableModuleReducer<any>;
}

const mapStateToProp = (state: RootReducer) => {
  return {
    PhongsReducer: state.phongsModule,
    archivesReducer: state.archives,
  };
};

const mapDispatchToProps = (dispatch: (arg0: Action<any>) => any) => ({
  getTableBoxScreen: (param: getTableArchives) =>
    dispatch(ArcchivesModule.actions.getTableRowArchives.start(param)),
  deleteBoxScreen: (param: DeleteRequest) =>
    dispatch(ArcchivesModule.actions.deleteProfileArchives.start(param)),
  editBoxScreen: (param: ArchivesEditRequest) =>
    dispatch(ArcchivesModule.actions.editProfileArchivesType.start(param)),
  addNewProfileArchivesScreen: (param: PostProfileArchives) =>
    dispatch(ArcchivesModule.actions.addNewProfileArchives.start(param)),
});

const BoxProfile = ({
  handleModalList,
  isModalList,
  infoPhong,
  archivesReducer,
  getTableBoxScreen,
}: // editBoxScreen,
// addNewProfileArchivesScreen,
// deleteBoxScreen,
Props) => {
  const { deletes, update, create, status, exportFile } = handleLogicBox();
  const { startLoading, stopLoading } = useLoading();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [boxData, setBoxData] = useState<any>([]);
  const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const phongSelected = useSelector(
    (state: RootReducer) => state.phongApp.phongSelected
  );

  const archiveId = useMemo(() => {
    return infoPhong?.id ?? phongSelected?.id;
  }, [phongSelected, infoPhong]);

  useEffect(() => {
    setIsModalVisible(isModalList);
  }, [isModalList]);

  useEffect(() => {
    if (isModalVisible) handleShowBox();
  }, [isModalVisible]);

  useEffect(() => {
    if (isModalVisible) handleShowBox();
    if (status === "create") {
      setIsModalVisibleCreate(false);
    }
    if (status === "update") {
      setIsModalVisibleEdit(false);
    }
  }, [status]);

  const handleShowBox = () => {
    startLoading();
    getTableBoxScreen({
      ArchivesIdFilter: archiveId,
      MaxResultCount: 999,
      TypeFilter: TYPE_BOX,
    });
  };
  useEffect(() => {
    getTableBoxScreen({
      ArchivesIdFilter: archiveId,
      MaxResultCount: 999,
      TypeFilter: TYPE_BOX,
    });
  }, [archiveId]);

  useDidUpdate(() => {
    const { state } = archivesReducer;
    const dataSourceBox: any = [];
    const {
      reducerStates: { getTableRowArchives },
    } = ArcchivesModule;
    switch (state) {
      case getTableRowArchives.success:
        for (let i = 0; i < archivesReducer.data.items.length; i++) {
          dataSourceBox.push(archivesReducer.data.items[i].recordsStorage);
        }
        setBoxData(dataSourceBox);
        stopLoading();
        break;
      case getTableRowArchives.failed:
        stopLoading();
        message.error("Lỗi");
        break;
    }
  }, [archivesReducer]);

  const handleActions = (items: IActionsDeleteOrEdit) => {
    if (items.action === "edit") {
      setIsModalVisibleEdit(true);
      setDataEdit({
        name: items.datas?.name,
        status: items.datas?.status,
        id: items.datas?.id,
        archivesId: items.datas?.archivesId,
        type: items.datas?.type,
        timeType:
          items.datas?.timeType === 1 || items.datas?.timeType === 2
            ? items.datas?.timeType
            : undefined,
      });
    } else if (items.action === "delete") {
      if (items.id) deletes({ id: items.id });
    } else {
      if (items.id) exportFile(String(items.id));
    }
  };

  const actionEdit = (items: any) => {
    const temp = items.name.trim();
    items.name = temp;
    if (items.name) {
      update(items);
    } else {
      message.error("Tên hộp số nhập sai định dạng");
    }
  };

  const actionCreate = (items: any) => {
    const temp = items.name.trim();
    items.name = temp;
    if (items.name) {
      const params = {
        name: items.name,
        type: TYPE_BOX,
        archivesId: infoPhong ? infoPhong.id : items.archivesId,
        status: items.status,
      };
      create(params);
    } else {
      message.error("Tên hộp số nhập sai định dạng");
    }
  };
  const fetchArchiveOptions: SelectFetchFunc = useCallback(
    (filter, page): Promise<SelectFetchResponse> => {
      return directorysRepository
        .getAllSelect({
          filter,
          skipCount: (page - 1) * env.DEFAULT_PAGE_SIZE,
          maxResultCount: env.DEFAULT_PAGE_SIZE,
          StatusFilter: statusArchives.NUMBER_TRUE,
        })
        .toPromise()
        .then(({ data }) => {
          return {
            hasMore:
              (page - 1) * env.DEFAULT_PAGE_SIZE + env.DEFAULT_PAGE_SIZE <
              data.totalCount,
            options: data.items.map<OptionType>((archive) => ({
              value: archive.id,
              label: archive.archives.name,
            })),
          };
        });
    },
    []
  );

  return (
    <>
      <Modal
        title={
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ width: "100%" }}>Danh sách hộp số</div>
            <PrivateView permissions={Permissions.Permission.Archives.Create}>
              <div>
                <Button
                  onClick={() => setIsModalVisibleCreate(true)}
                  icon={<PlusOutlined />}
                >
                  Thêm mới hộp
                </Button>
              </div>
            </PrivateView>
          </div>
        }
        visible={isModalVisible}
        onOk={() => {
          handleModalList();
        }}
        closable={false}
        onCancel={() => {
          handleModalList();
        }}
        footer={false}
      >
        <BoxList data={boxData} actions={handleActions} />
      </Modal>
      <BoxEdit
        isModalEdit={isModalVisibleEdit}
        data={dataEdit}
        onFinish={actionEdit}
        isHandleModal={() => setIsModalVisibleEdit(false)}
      />
      <BoxCreate
        isAdmin={isAdmin}
        fetchArchiveOptions={fetchArchiveOptions}
        isModalCrete={isModalVisibleCreate}
        isHandleModal={() => setIsModalVisibleCreate(false)}
        onFinish={actionCreate}
      />
    </>
  );
};

// export default BoxProfile;
export default connect(mapStateToProp, mapDispatchToProps)(BoxProfile);
