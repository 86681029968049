import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Observable, Subject, Subscription } from "rxjs";
import { useLoading, useMount, useProgressMessage } from "src/core/hooks";
import useDebounceFn from "src/core/hooks/useDebounceFn";
import JobState from "src/core/models/JobState";

type Fn<Result = any> = (...args: any) => Observable<Result>;
export interface UseJobOptions<Title extends string = ""> {
  showLoading?: boolean;
  wait?: number;
  startMessage?: Title;
  successMessage?: Title;
  errorMessage?: Title;
}

export interface UseJobResult<T extends Fn> {
  run: (...args: Parameters<T>) => void;
  cancel: () => void;
  state: JobState;
  processing: boolean;
  result?: ReturnType<T> extends Observable<infer R> ? R : any;
  error: any;
}

export function useJob<T extends Fn, Title extends string = "">(
  fn: T,
  options?: UseJobOptions<Title>
): UseJobResult<T> {
  const fnRef = useRef<T>(fn);
  fnRef.current = fn;
  const subject = useRef(new Subject<Observable<any>>());
  const [jobSubscription, setJobSubscription] = useState<Subscription>();

  const { startLoading, stopLoading } = useLoading();
  const {
    startProgress,
    progressSuccess,
    progressFail,
    destroyProgress,
  } = useProgressMessage();

  const [state, setState] = useState<JobState>(JobState.Standing);
  const [result, setResult] = useState<
    ReturnType<T> extends Observable<infer R> ? R : any
  >();
  const [error, setError] = useState<any>();

  const processing = useMemo(() => state === JobState.Processing, [state]);

  const { run } = useDebounceFn(
    (...args: Parameters<T>) => {
      setState(JobState.Processing);

      options?.startMessage && startProgress(options.startMessage);
      if (options?.showLoading) {
        startLoading();
      }

      subject.current.next(fnRef.current(...(args as any[])));
    },
    { wait: options?.wait ?? 100 }
  );

  const cancel = useCallback(() => {
    setJobSubscription((current) => {
      current?.unsubscribe();

      return undefined;
    });
    setState(JobState.Standing);

    destroyProgress();
    if (options?.showLoading) {
      stopLoading();
    }
  }, []);

  useMount(() => {
    if (subject.current.closed) {
      subject.current = new Subject();
    }

    subject.current.subscribe({
      next: (observable) => {
        setJobSubscription(
          observable.subscribe({
            next: setResult,
            complete: () => {
              setState(JobState.Success);

              options?.successMessage &&
                progressSuccess(options.successMessage);
              if (options?.showLoading) {
                stopLoading();
              }
            },
            error: (error) => {
              console.error(error);

              setState(JobState.Failed);
              setError(error);

              options?.errorMessage && progressFail(options.errorMessage);
              if (options?.showLoading) {
                stopLoading();
              }
            },
          })
        );
      },
    });

    return () => {
      destroyProgress();
      if (options?.showLoading) {
        stopLoading();
      }
      subject.current.unsubscribe();
    };
  });

  useEffect(() => {
    return () => {
      jobSubscription?.unsubscribe();
    };
  }, [jobSubscription]);

  useEffect(() => {
    if (options?.showLoading) {
      processing ? startLoading() : stopLoading();
    }
  }, [processing]);

  return {
    run,
    cancel,
    state,
    processing,
    result,
    error,
  };
}
