const Images = {
  auth: {
    backgroutLogin: require("./images/backgroundLogin.png").default,
    logoLogin: require("./images/logoLogin.png").default,
    logoQt: require("./images/lgLogin.png").default,
    logoLTVN: require("./images/lgLTVN.png").default,
    newLogo: require("./images/newLogo.png").default,
    backgroundHead: require("./images/bgHead.png").default,
  },
  menu: {
    menuDashborad: require("./images/menu-dashborad.png").default,
    menuSearch: require("./images/menu-search.png").default,
    menuFile: require("./images/menu-file.png").default,
    menuType: require("./images/menu-type.png").default,
    menuFont: require("./images/menu-font.png").default,
    menuCategories: require("./images/menu-categories.png").default,
    menuReaders: require("./images/menu-readers.png").default,
    menuReport: require("./images/menu-report.png").default,
    menuAdministration: require("./images/menu_8.png").default,
  },
  user: {
    boy: require("./images/boy.png").default,
    girl: require("./images/girl.png").default,
    girl_gender: require("./images/girl_gender.png").default,
    boy_gender: require("./images/boy_gender.png").default,
    gift: require("./images/gift.png").default,
    email: require("./images/email.png").default,
    location: require("./images/location.png").default,
    calculator: require("./images/calculator.png").default,
    phone: require("./images/phone.png").default,
    phoneCard: require("./images/phone_card.png").default,
    warehouse_01: require("./images/warehouse_01.png").default,
    warehouse_02: require("./images/warehouse_02.png").default,
    changeTo: require("./images/change_to.png").default,
  },
  search: {
    refresh: require("./images/refresh.png").default,
  },

  accessDenied: require("./images/access_denied.svg").default,
  bug: require("./images/bug.svg").default,
  notFound: require("./images/404.svg").default,
  icons: {
    date: require("./icons/date-icon.svg").default,
  },
};

export default Images;
