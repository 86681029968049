import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  ReduxObservableModuleEpicProps,
  createReduxObservableModule,
} from "src/core/redux/ReduxObservableModule";
import {
  BatchCreateDocumentRequest,
  DocumentRequest,
  DocumentRequestGetList,
  ImportRequest,
  ListDocumentRequest,
  ListExportDocument,
} from "src/models/Doucment";
import DocumentRepository from "src/repositories/DocumentRepository";

export interface DocumentReducer {}

const defaultState: DocumentReducer = {};

const documentModule = createReduxObservableModule(
  {
    getList: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ListDocumentRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.get(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    getOne: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.getOne(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    create: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DocumentRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.create(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    batchCreate: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<BatchCreateDocumentRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.batchCreate(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    documentGetList: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DocumentRequestGetList>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.getListDocument(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    update: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DocumentRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.update(action.payload.id, action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    delete: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.delete(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    downloadImportFileTemplate: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap(() =>
          DocumentRepository.downloadImportFileTemplate().pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    import: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ImportRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.import(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    export: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ListExportDocument>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.export(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    downloadFiles: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DocumentRepository.downloadFiles(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "document",
  defaultState
);

export default documentModule;
