import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { AllParentFieldsResponse } from "src/models/directorys/directory";
import {
  createNewsletterRequest,
  getListRequest,
  updateNewsletterRequest,
} from "src/models/phongs/NewsletterForm";
import { DeleteRequest } from "src/models/Request";

class NewsletterFormRepository extends Repository {
  protected basePath = "/api/cms-service/newsletter-form-report";

  constructor() {
    super(env.API_CMS_URL);
  }

  getAll(params: getListRequest) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "/all",
      params,
    });
  }

  createNewsletterForm(params: createNewsletterRequest) {
    return this.request({
      method: "POST",
      url: "",
      data: params,
    });
  }

  exportNewsletterForm(id: string) {
    return this.request({
      method: "POST",
      url: `/${id}/export`,
    });
  }

  deleteNewsletterForm(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `/${param.id}`,
    });
  }

  updateNewsletterForm(params: updateNewsletterRequest) {
    return this.request({
      method: "PUT",
      url: "",
      data: params,
    });
  }

  createProfileNewsletterForm(id: DeleteRequest) {
    return this.request({
      method: "POST",
      url: `/file/${id}`,
    });
  }
}
export const newsletterFormRepository = new NewsletterFormRepository();
