import { ofType } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import {
  DashboardParamsRequest,
  DashboardSettingRequest,
} from "src/models/DashboardManagementModel";
import DashboardRepository from "src/repositories/DashboardRepository";

const dashboardModule = createReduxObservableModule(
  {
    getDashboardRecordByDateRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DashboardParamsRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DashboardRepository.getRecordByDate(action.payload).pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
    getRecordWarehouseRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<any>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap(() =>
          DashboardRepository.getRecordWarehouse().pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
    getRecordProfileFieldRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<any>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap(() =>
          DashboardRepository.getRecordProfileField().pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
    getRecordPieChartRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<any>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap(() =>
          DashboardRepository.getRecordPieChart().pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
    getRecordPieChartByUser: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<any>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap(() =>
          DashboardRepository.getRecordPieChartByUser().pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
    getRequestByUserForView: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DashboardParamsRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DashboardRepository.getRequestByUserForView(action.payload).pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
    getRecordByUserSettings: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DashboardSettingRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DashboardRepository.getRecordByUserSettings(action.payload).pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
    getRecordBarChartRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DashboardParamsRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DashboardRepository.getRecordBarChart(action.payload).pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
    settingFieldReportRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DashboardSettingRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DashboardRepository.settingFieldReport(action.payload).pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
    settingArchivesReportRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DashboardSettingRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          DashboardRepository.settingArchivesReport(action.payload).pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
  },
  "DASHBOARD"
);

export default dashboardModule;
