import { map, mergeMap } from "rxjs/operators";
import { Repository } from "src/core/Repository";
import env from "src/core/env";
import {
  ExportFileResponse,
  ExportToWordRequest,
  GetAllListReportResponse,
  GetAllListRequest,
} from "src/models/report";

class ReportRepository extends Repository<
  ExportToWordRequest,
  ExportFileResponse
> {
  protected basePath = "api/";

  constructor() {
    super(env.API_CMS_URL);
  }

  exportWordReport(params: ExportToWordRequest) {
    return this.request<ExportFileResponse>({
      method: "POST",
      url: `cms-service/report/export?fileType=0`,
      data: params,
    }).pipe(
      mergeMap(({ data }) => {
        return this.api
          .get<Blob>("api/File/DownloadTempFile", data, {
            responseType: "blob",
          })
          .pipe(
            map(({ data: fileData }) => ({
              fileName: data.fileName,
              fileContent: fileData,
            }))
          );
      })
    );
  }

  viewToPrintReport(params: ExportToWordRequest) {
    return this.request({
      method: "POST",
      url: "cms-service/report/export-to-html",
      data: params,
    });
  }

  getAllListReport(params: GetAllListRequest) {
    return this.request<GetAllListReportResponse>({
      method: "GET",
      url: "cms-service/report/all",
      params,
    });
  }

  getLogImport(archivesId: string, type: number) {
    // delete = 3
    // import = 1
    // export = 2
    return this.request<any>({
      method: "GET",
      url: "import-log/get-status",
      params: { archivesId, type },
    });
  }
}

export default new ReportRepository();
