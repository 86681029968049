import { parse, stringify } from "query-string";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useDebounceFn from "src/core/hooks/useDebounceFn";
import { useDidUpdate } from "src/core/hooks/useDidUpdate";

export type QueryResult<P, K extends keyof P = keyof P> = {
  [T in K]: string;
};

function getDefaultData<T>(search: string, defaultData?: QueryResult<T>) {
  const query = (parse(search) as unknown) as QueryResult<T>;
  if (JSON.stringify(query) === "{}") {
    return defaultData;
  }

  return Object.assign(defaultData, query);
}

export function useQuery<ListParams = any>(
  defaultData?: QueryResult<ListParams>
) {
  const { search, pathname, ...rest } = useLocation();
  const { replace } = useHistory();
  const [query, setQuery] = useState<QueryResult<ListParams>>(
    getDefaultData(search, defaultData) as QueryResult<ListParams>
  );

  const { run: replaceSearch } = useDebounceFn(
    () => {
      replace({
        ...rest,
        pathname,
        search: "?" + stringify(query as any),
      });
    },
    { wait: 50 }
  );

  useDidUpdate(() => {
    setQuery(getDefaultData(search, defaultData) as QueryResult<ListParams>);
  }, [search]);

  useEffect(() => {
    replaceSearch();
  }, [query]);

  const setQueryParam = useCallback(function <T extends keyof ListParams>(
    name: T,
    value: ListParams[T] | undefined
  ) {
    if (typeof value === "undefined") {
      const newQuery: any = { ...query };
      delete newQuery[name];
      setQuery(() => newQuery);

      return;
    }

    setQuery(
      (old) =>
        ({
          ...old,
          [name]: value,
        } as any)
    );
  },
  []);

  return {
    query: (query as unknown) as QueryResult<ListParams>,
    setQueryParam,
    setQuery,
  };
}
