export enum ProvideDataType {
  /**
   * Phiếu yêu cầu
   */
  DataRequestForm = "dataRequestForm",
  /**
   * Hợp đồng
   */
  Contract = "contract",
  /**
   * Phiếu yêu cầu cung cấp tọa độ
   */
  RequestForm = "requestForm",
}

export const methodResults = [
  "Bản giấy sao chụp",
  "Nhận tại nơi cung cấp",
  "Lưu trữ điện tử USB, CD",
  "Gửi EMS theo địa chỉ",
  "Fax",
  "Email",
];
