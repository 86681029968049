import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { getListRequest } from "src/models/phongs/NewsletterForm";
import { newsletterFormRepository } from "src/repositories/NewsletterForm";

const newsletterForm = createReduxObservableModule(
  {
    getAll: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<getListRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) => {
            return newsletterFormRepository.getAll(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            );
          })
        ),
    },
  },
  "NEWSLETTERFORM"
);

export default newsletterForm;
