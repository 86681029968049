import { LeftOutlined } from "@ant-design/icons";
import { Layout, PageHeader, Spin } from "antd";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { map } from "rxjs/operators";
import { Button } from "src/components";
import env from "src/core/env";
import { useProgressMessage, useToggle } from "src/core/hooks";
import { useJob } from "src/core/hooks/useJob";
import { Listener } from "src/core/utilities/EventListenersManager";
import useAuth from "src/hooks/modules/useAuth";
import useCurrentRoute from "src/hooks/useCurrentRoute";
import LayoutHeader from "src/layouts/Component/Header";
import { BasedMenu } from "src/layouts/Dashboard/components/BasedMenu";
import ModalSelectPhongs from "src/layouts/Dashboard/components/ModalSelectPhongs";
import LayoutEventsListenersManager, {
  LayoutEvents,
} from "src/layouts/LayoutEventsListenersManager";
import { MenuItem } from "src/models/layout";
import { Archives } from "src/models/phongs";
import { RootReducer } from "src/redux";
import PhongsRepository from "src/repositories/PhongsRepository";

const { Content, Sider } = Layout;

interface DashboardLayoutProps {
  defaultConfig?: LayoutConfig;
  routes: MenuItem[];
  bottomRoutes: MenuItem[];
}

export interface LayoutConfig {
  showPageTitle?: boolean;
  showHeaderExtra?: boolean;
  headerExtra?: ReactNode;
  backButton?: string | boolean;
  showSidebar?: boolean;
  pageTitle?: string;
}

export type LayoutConfigContextType = {
  config: LayoutConfig;
  phong: Archives | null;
  addEventListener<E extends keyof LayoutEvents>(
    event: E,
    listener: Listener<LayoutEvents[E]>
  ): () => void;
  changeLayoutConfig: (config: LayoutConfig) => void;
  openModalSelectPhong: () => void;
};

export const LayoutConfigContext = createContext<
  LayoutConfigContextType | undefined
>(undefined);

const defaultLayoutConfig: LayoutConfig = {
  showPageTitle: true,
  showHeaderExtra: true,
  headerExtra: undefined,
  showSidebar: true,
  backButton: false,
  pageTitle: "",
};

const DashboardLayout: FunctionComponent<DashboardLayoutProps> = ({
  defaultConfig = defaultLayoutConfig,
  children,
  routes,
  // bottomRoutes,
}) => {
  // Handle layout config
  const isRoleAdmin = localStorage.getItem("isRoleAdmin");
  const isAdmin = isRoleAdmin !== null;
  const { progressFail, progressWarning } = useProgressMessage();
  const { profile, fetchUserProfile, logout } = useAuth();
  const { location } = useHistory();
  const phongApp = useSelector((rootState: RootReducer) => rootState.phongApp);
  const [visible, setVisible] = useState(!phongApp.phongSelected && isAdmin);
  const [config, setConfig] = useState<LayoutConfig>(defaultConfig);
  const [phong, setPhong] = useState<Archives | null>(null);
  const dispatch = useDispatch();

  const changeLayoutConfig = useCallback((config: LayoutConfig) => {
    setConfig((old) => ({
      ...old,
      ...config,
    }));
  }, []);

  const addEventListener = useCallback(function <E extends keyof LayoutEvents>(
    event: E,
    listener: Listener<LayoutEvents[E]>
  ) {
    return LayoutEventsListenersManager.on(event, listener);
  },
  []);

  useLayoutEffect(() => {
    setConfig(defaultConfig);
  }, [location.pathname]);

  // Handle page title and breadcrumb
  const currentRoute = useCurrentRoute(routes);
  useLayoutEffect(() => {
    if (currentRoute) {
      document.title =
        currentRoute.location?.key ||
        currentRoute.documentTitle ||
        currentRoute.pageTitle ||
        currentRoute.name ||
        env.APP_NAME;

      if (!config.pageTitle) {
        changeLayoutConfig({
          pageTitle: currentRoute.pageTitle || currentRoute.name,
        });
      }
    }
  }, [currentRoute]);

  // const fetchAllFields = async (): Promise<any> => {
  //   const response = await administrationRepository.getProfile().toPromise();
  //   setdata(response.data);
  // };

  // Handle second sider collapse
  const {
    state: secondSiderCollapsed,
    on: collapseSecondSider,
    off: expandSecondSider,
    toggle: toggleSeconSiderCollapse,
  } = useToggle();

  const handleSecondSiderBreak = useCallback((breaked: boolean) => {
    if (breaked) {
      collapseSecondSider();
    } else {
      expandSecondSider();
    }
  }, []);

  // Handle back button click
  const history = useHistory();
  const handleBackButtonClick = useCallback(() => {
    if (config.backButton !== false) {
      typeof config.backButton === "string" && config.backButton.length > 0
        ? history.push(config.backButton)
        : history.goBack();
    }
  }, [config.backButton]);

  const handleSelectPhong = useCallback((phong: Archives) => {
    setPhong(phong);
    setVisible(false);
  }, []);

  const { run: getDetailPhongApiId } = useJob(
    (id: string | number) => {
      console.log("request get detail");
      return PhongsRepository.getDetailsPhongs({ id }).pipe(
        map((res) => {
          if (res.status === 200) {
            const phongArchive = res.data.archives;
            setPhong(phongArchive);
          } else {
            progressFail("Lấy dữ liệu tài khoản không thành công");
            logout();
          }
        })
      );
    },
    {
      showLoading: true,
      errorMessage: "Lấy thông tin tài khoản không thành công",
    }
  );

  useEffect(() => {
    if (!isAdmin && profile) {
      console.log(profile, "pp");
      getDetailPhongApiId(profile.account.archivesInCharge);
    }
  }, [isAdmin, profile]);

  useEffect(() => {
    if (phong) {
      dispatch({
        type: "SET_PHONG",
        payload: phong,
      });
    }
  }, [phong]);

  return (
    <LayoutConfigContext.Provider
      value={{
        config,
        phong,
        changeLayoutConfig,
        addEventListener,
        openModalSelectPhong: () => setVisible(true),
      }}
    >
      <ModalSelectPhongs
        visible={visible}
        title="Chọn Phông mặc định"
        onConfirm={handleSelectPhong}
        onCancel={() =>
          !phongApp.phongSelected
            ? progressWarning("Bạn phải chọn phông")
            : setVisible(false)
        }
        closable={!!phongApp.phongSelected}
      ></ModalSelectPhongs>
      <PerfectScrollbar
        options={{
          wheelSpeed: 0.5,
          suppressScrollX: true,
        }}
      >
        <Layout id="dashboard-layout" className="fixed-header">
          <LayoutHeader />
          <Layout>
            <Sider
              className="second-sider pb-3"
              breakpoint="lg"
              onBreakpoint={handleSecondSiderBreak}
              collapsed={secondSiderCollapsed}
              trigger={
                <Button
                  icon={
                    <LeftOutlined rotate={secondSiderCollapsed ? 180 : 0} />
                  }
                  className="m-n1"
                  type="primary"
                  size="large"
                  onClick={toggleSeconSiderCollapse}
                />
              }
              collapsible
            >
              <BasedMenu mode="inline" items={routes} />
            </Sider>
            <Layout className="right-layout">
              <Suspense
                fallback={
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Spin spinning />
                  </div>
                }
              >
                {!!currentRoute && (
                  <div className="page-header-container">
                    <div className="container">
                      <PageHeader
                        backIcon={
                          <LeftOutlined
                            className="p-2 border rounded"
                            style={{ color: "#999" }}
                          />
                        }
                        onBack={
                          config.backButton !== false
                            ? handleBackButtonClick
                            : undefined
                        }
                        title={
                          config.showPageTitle ? config.pageTitle : undefined
                        }
                        extra={config.showHeaderExtra && config.headerExtra}
                        className="py-0"
                      />
                    </div>
                  </div>
                )}
                <div className="container d-flex flex-column">
                  <Content className="p-4">{children}</Content>
                </div>
              </Suspense>
            </Layout>
          </Layout>
        </Layout>
      </PerfectScrollbar>
    </LayoutConfigContext.Provider>
  );
};

export default DashboardLayout;
