import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  AllArchivesResponse,
  AllOrganizationalUnits,
  ArchivesEditRequest,
  ArchivesListRequest,
  ArchivesResponse,
  GetArchiveNamesRequest,
  getTableArchives,
  PostArchives,
  PostProfileArchives,
} from "src/models/archives";
import {
  AllDataTableResponse,
  AllFieldsResponse,
  AllParentFieldsRequest,
  AllParentFieldsResponse,
  CategoryListRequest,
  GetCategoryNamesRequest,
} from "src/models/directorys/directory";
import { DeleteRequest, ListPhongsRequest } from "src/models/Request";

class DirectorysRepository extends Repository {
  protected basePath = "api/";

  constructor() {
    super(env.API_CMS_URL);
  }

  getAllFields() {
    return this.request<AllFieldsResponse>({
      method: "GET",
      url: "cms-service/types/all",
    });
  }

  getParentFields(params: AllParentFieldsRequest) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "cms-service/categories/category-for-lookup-by-filter",
      params,
    });
  }

  addNewCategory(params: CategoryListRequest) {
    return this.request({
      method: "POST",
      url: "cms-service/categories",
      data: params,
    });
  }

  getTableCategoryType(params: AllParentFieldsRequest) {
    return this.request<AllDataTableResponse>({
      method: "GET",
      url: "cms-service/categories/all",
      params,
    });
  }

  deleteTableRowCategoryType(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `cms-service/categories/${param.id}`,
    });
  }

  editTableRowCategoryType(params: CategoryListRequest) {
    return this.request({
      method: "PUT",
      url: "cms-service/categories",
      data: params,
    });
  }

  // Kho
  getAllSelect(request?: ListPhongsRequest) {
    return this.request<AllArchivesResponse>({
      method: "GET",
      url: "cms-service/archives/for-loop",
      params: request,
    });
  }

  addNewArchives(params: PostArchives) {
    return this.request({
      method: "POST",
      url: "cms-service/archives",
      data: params,
    });
  }

  getOrganizationalUnits() {
    return this.request<AllOrganizationalUnits>({
      method: "GET",
      url: "cms-service/archives/organiztion-units-lookup-by-filter",
    });
  }

  editTableRowArchivesType(params: ArchivesListRequest) {
    return this.request({
      method: "PUT",
      url: "cms-service/archives",
      data: params,
    });
  }

  deleteTableRowArchives(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `cms-service/archives/${param.id}`,
    });
  }

  getTableRowArchives(params: getTableArchives) {
    params.MaxResultCount = 999;
    return this.request({
      method: "GET",
      url: "cms-service/records-storages/all",
      params,
    });
  }

  addNewFrofileArchives(params: PostProfileArchives) {
    return this.request<ArchivesResponse>({
      method: "POST",
      url: "cms-service/records-storages",
      data: params,
    });
  }

  editProfileArchivesType(params: ArchivesEditRequest) {
    return this.request({
      method: "PUT",
      url: "cms-service/records-storages",
      data: params,
    });
  }

  deleteProfileArchives(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `cms-service/records-storages/${param.id}`,
    });
  }

  getArchivesSelect() {
    const params = { MaxResultCount: 1000, StatusFilter: -1 };
    return this.request<AllArchivesResponse>({
      method: "GET",
      url: "cms-service/archives/all",
      params,
    });
  }

  getArchiveNames(ids: GetArchiveNamesRequest) {
    return this.request({
      url: "cms-service/archives/get-archives-by-ids",
      method: "POST",
      data: { ids },
    });
  }

  getCategoryNames(codes: GetCategoryNamesRequest) {
    return this.request({
      url: "cms-service/categories/get-name-by-codes",
      method: "POST",
      data: { codes },
    });
  }

  exportFileBox(id: string) {
    return this.request({
      url: `cms-service/cover-word/${id}/export-box`,
      method: "GET",
      // params: { id },
    });
  }

  exportNewsletterForm(id: string) {
    return this.request({
      url: `cms-service/cover-word/${id}/export-newsletter-form`,
      method: "GET",
      // params: { id },
    });
  }
}
export const directorysRepository = new DirectorysRepository();
