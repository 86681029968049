export interface ListResponse<T = any> {
  totalCount: number;
  items: T[];
}

export interface ListRequest {
  filter?: string;
  sorting?: string;
  skipCount: number;
  maxResultCount: number;
  OrganizationUnitId?: string;
}

export interface ListPhongsRequest extends ListRequest {
  StatusFilter?: number;
}

export interface DeleteRequest {
  id: number | string;
}

export interface FileTypeRequest {
  id: string;
  typeRequest?: 1 | 2 | 3 | 4;
}

export enum statusArchives {
  NUMBER_TRUE = 1,
  NUMBER_FALSE = 0,
}
