import { Action } from "redux";
import { actionHasPayload } from "src/core/models/redux/Action";
import { Archives } from "src/models/phongs";

const initialState: {
  phongSelected: Archives | null;
} = {
  phongSelected: null,
};

export function phongAppReducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case "SET_PHONG": {
      const payload: Archives = actionHasPayload(action)
        ? action.payload
        : undefined;
      return {
        phongSelected: payload,
      };
    }

    default:
      return state;
  }
}
