import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Space,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Card, DatePicker, Row } from "src/components";
import DraggerUpload, {
  DraggerUploadProps,
} from "src/components/DraggerUpload";
import Select, { OptionType, SelectFetchResponse } from "src/components/Select";
import env from "src/core/env";
import { useDidUpdate, useLoading, useProgressMessage } from "src/core/hooks";
import { ReduxObservableModuleReducer } from "src/core/redux/ReduxObservableModule";
import TokenManager from "src/core/utilities/TokenManager";
import {
  Document,
  DocumentRequest,
  DocumentResponse,
  DocumentStatus,
  documentStatuses,
  getDocumentStatusText,
} from "src/models/Doucment";
import {
  getPreservationOfRecordFileTypeText,
  getPreservationOfRecordStorageTimeTypeText,
  PreservationOfRecordFileType,
  preservationOfRecordFileTypes,
  PreservationOfRecordStorageTimeType,
  preservationOfRecordStorageTimeTypes,
} from "src/models/PreservationOfRecord";
import { statusArchives } from "src/models/Request";
import { RootReducer } from "src/redux";
import documentModule from "src/redux/modules/document";
import DashboardRepository from "src/repositories/DashboardRepository";
import { directorysRepository } from "src/repositories/DirectorysRepository";
import FileRepository from "src/repositories/FileRepository";
import { FULL_DATE } from "src/utilities/dateFormatHelper";
import {
  fileStringsToUploadFile,
  uploadFilesToString,
} from "src/utilities/fileHelpers";
import { patternNumberOnly } from "src/utilities/patternHelper";

interface PreservationOfRecordDocumentFormProps {
  createDocument: (request: DocumentRequest) => void;
  updateDocument: (request: DocumentRequest) => void;
  getOneDocument: (request: string) => void;
  documentReducer: ReduxObservableModuleReducer;
  documentId?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  editable: boolean;
  visible: boolean;
}

const mapStateToProps = (state: RootReducer) => ({
  documentReducer: state.document,
});

const mapDispatchToProps = {
  createDocument: documentModule.actions.create.start,
  updateDocument: documentModule.actions.update.start,
  getOneDocument: documentModule.actions.getOne.start,
};

const PreservationOfRecordDocumentFormBased = ({
  createDocument,
  updateDocument,
  getOneDocument,
  documentReducer,
  documentId,
  onSuccess,
  onCancel,
  editable,
  visible,
}: PreservationOfRecordDocumentFormProps) => {
  const {
    startProgress,
    progressSuccess,
    destroyProgress,
  } = useProgressMessage();
  const { startLoading, stopLoading } = useLoading();
  // const { id: preservationOfRecordId } = useParams<{ id: string }>();
  const [form] = useForm<Document>();
  const [oldFormData, setOldFormData] = useState<Document>();
  const [
    preservationOfRecordId,
    setPreservationOfRecordId,
  ] = useState<string>();
  const phongSelected = useSelector(
    (state: RootReducer) => state.phongApp.phongSelected
  );

  const archiveId = useMemo(() => {
    return phongSelected?.id ? String(phongSelected?.id) : undefined;
  }, [phongSelected]);

  useEffect(() => {
    form.setFieldsValue({
      preservationOfRecordId: preservationOfRecordId,
    });
  }, [preservationOfRecordId]);

  // Handle load document data for edit
  useEffect(() => {
    if (documentId) {
      getOneDocument(documentId);
    }
  }, [documentId, visible]);

  useDidUpdate(() => {
    if (
      documentReducer.state === documentModule.reducerStates.getOne.processing
    ) {
      startLoading();
    } else if (
      documentReducer.state === documentModule.reducerStates.getOne.success
    ) {
      stopLoading();
      const data: Document = {
        ...(documentReducer.data as DocumentResponse).document,
        dateIssued: (documentReducer.data as DocumentResponse).document
          .dateIssued
          ? dayjs(
              (documentReducer.data as DocumentResponse).document.dateIssued
            )
          : undefined,
        files: (documentReducer.data as DocumentResponse).document.files
          ? fileStringsToUploadFile(
              (documentReducer.data as DocumentResponse).document.files
            )
          : [],
        storageTimeType: (documentReducer.data as any).storageTimeType,
        fileNumber: (documentReducer.data as any).fileNumber,
        storageBoxName: (documentReducer.data as any).storageBoxName,
      };
      form.setFieldsValue(data);
      setOldFormData(data);
    } else {
      stopLoading();
    }
  }, [documentReducer.state]);

  // Handle upload file
  const uploadProps = useMemo(
    (): DraggerUploadProps => ({
      name: "files",
      action: env.API_CDN_URL.replace(/['"]+/g, "") + "file/upload",
      headers: {
        authorization: "Bearer " + TokenManager.getToken("base_token"),
      },
      multiple: true,
      onRemove(file) {
        if (file.response && Array.isArray(file.response)) {
          const response = file.response[0] || {};

          FileRepository.delete(response.publicId).subscribe(
            () => {},
            () => {}
          );
        }
      },
    }),
    []
  );

  // Handle submit
  const handleSubmit = useCallback(
    (values: Document) => {
      const temp = values.content.trim();
      values.content = temp;
      if (values.content) {
        if (isEqual(oldFormData, values)) {
          message.error("Chưa thay đổi thông tin, vui lòng kiểm tra lại");
          return;
        }

        const params: DocumentRequest = {
          ...values,
          dateIssued: values.dateIssued ? values.dateIssued.format() : "",
          files: uploadFilesToString(values.files || []),
        };
        if (values.id) {
          updateDocument(params);
        } else {
          createDocument(params);
        }
      } else {
        message.error("Trường nội dung trích yếu nhập không hợp lệ");
      }
    },
    [oldFormData]
  );

  useDidUpdate(() => {
    switch (documentReducer.state) {
      case documentModule.reducerStates.create.processing:
        startLoading();
        startProgress("Đang thêm mục lục văn bản...");
        break;

      case documentModule.reducerStates.update.processing:
        startProgress("Đang cập nhật mục lục văn bản...");
        startLoading();
        break;

      case documentModule.reducerStates.create.success:
        stopLoading();
        progressSuccess("Thêm mục lục văn bản thành công!");
        onSuccess && onSuccess();
        break;

      case documentModule.reducerStates.update.success:
        stopLoading();
        progressSuccess("Cập nhật mục lục văn bản thành công!");
        onSuccess && onSuccess();
        break;

      case documentModule.reducerStates.create.failed:
      case documentModule.reducerStates.update.failed:
        stopLoading();
        destroyProgress();
        break;

      default:
        break;
    }
  }, [documentReducer.state]);

  const fetchArchiveOptions = useCallback(
    (filter, page): Promise<SelectFetchResponse> => {
      return directorysRepository
        .getAllSelect({
          filter,
          skipCount: (page - 1) * env.DEFAULT_PAGE_SIZE,
          maxResultCount: env.DEFAULT_PAGE_SIZE,
          StatusFilter: statusArchives.NUMBER_TRUE,
        })
        .toPromise()
        .then(({ data }) => {
          return {
            hasMore:
              (page - 1) * env.DEFAULT_PAGE_SIZE + env.DEFAULT_PAGE_SIZE <
              data.totalCount,
            options: data.items.map<OptionType>((archive) => ({
              value: archive.id,
              label: archive.archives.name,
            })),
          };
        });
    },
    []
  );
  const fetchPreservationOfRecordOptions = useCallback(
    (filter, page): Promise<SelectFetchResponse> => {
      return DashboardRepository.getPreservationOfRecord({
        filter,
        skipCount: (page - 1) * env.DEFAULT_PAGE_SIZE,
        maxResultCount: env.DEFAULT_PAGE_SIZE,
        archivesIdFilter: archiveId,
      })
        .toPromise()
        .then(({ data }) => {
          console.log(data);
          return {
            hasMore:
              (page - 1) * env.DEFAULT_PAGE_SIZE + env.DEFAULT_PAGE_SIZE <
              data.totalCount,
            options: data.items.map((value: any) => ({
              value: value.preservationOfRecord.id,
              label: value.preservationOfRecord.title,
            })),
          };
        });
    },
    [archiveId]
  );

  useEffect(() => {
    form.setFieldsValue({ archivesId: archiveId });
  }, [archiveId]);

  return (
    <>
      {!documentId ? (
        <Form>
          <Row style={{ marginBottom: 5 }}>
            <Col xl={6}>
              <Form.Item
                // label="Phông lưu trữ"
                name="archivesId"
                className="label-bold mb-0"
                initialValue={phongSelected?.id ?? undefined}
              >
                <Select.Ajax
                  onSelect={() => setPreservationOfRecordId(undefined)}
                  fetchFunc={fetchArchiveOptions}
                  placeholder="Phông lưu trữ"
                  style={{ minWidth: 250 }}
                  disabled={!!phongSelected?.id}
                  allowClear
                  extraOptions={
                    phongSelected?.id
                      ? [
                          {
                            label: phongSelected.name ?? "",
                            value: phongSelected.id,
                          },
                        ]
                      : undefined
                  }
                />
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item className="label-bold mb-0">
                {archiveId ? (
                  <Select.Ajax
                    value={preservationOfRecordId}
                    onChange={(value) => setPreservationOfRecordId(value)}
                    fetchFunc={fetchPreservationOfRecordOptions}
                    dependencies={[archiveId]}
                    placeholder="Chọn hồ sơ"
                    style={{ minWidth: 350 }}
                    allowClear
                  />
                ) : (
                  <Input placeholder="Chọn hồ sơ" disabled />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : null}

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item name="id" hidden>
          <Input disabled={!editable} />
        </Form.Item>
        <Form.Item
          name="preservationOfRecordId"
          initialValue={preservationOfRecordId}
          hidden
        >
          <Input disabled={!editable} />
        </Form.Item>
        <Row>
          <Col
            xs={{
              span: 24,
              order: 2,
            }}
            lg={{
              span: 16,
              order: 2,
            }}
            xl={18}
          >
            <Card>
              <Form.Item
                label="Nội dung trích yếu văn bản"
                name="content"
                rules={[{ required: true }]}
              >
                <Input.TextArea disabled={!editable} />
              </Form.Item>
              <Form.Item label="Nội dung bổ sung" name="note">
                <Input.TextArea />
              </Form.Item>
              <Form.Item
                label="Hình ảnh/văn bản"
                name="files"
                valuePropName="fileList"
              >
                <DraggerUpload {...uploadProps} disabled={!editable}>
                  <div className="my-5 mx-5">
                    <Button size="large" disabled={!editable}>
                      Tải file
                    </Button>
                    <Typography.Paragraph className="mt-3">
                      Hoặc kéo thả ảnh/video để thêm
                    </Typography.Paragraph>
                  </div>
                </DraggerUpload>
              </Form.Item>

              <Space className="d-flex justify-content-end mt-3">
                {editable ? (
                  <>
                    <Button.ConfirmDelete
                      modalConfig={{
                        title: "Bạn muốn hủy bỏ?",
                        content: "Bạn có chắc chắn muổn hủy thao tác này?",
                        onOk: onCancel,
                      }}
                      danger
                    >
                      Hủy bỏ
                    </Button.ConfirmDelete>
                    <Button htmlType="submit" type="primary">
                      Lưu
                    </Button>
                  </>
                ) : (
                  <Button onClick={onCancel}>Đóng</Button>
                )}
              </Space>
            </Card>
          </Col>

          <Col
            xs={{
              span: 24,
              order: 1,
            }}
            lg={{
              span: 8,
              order: 2,
            }}
            xl={6}
          >
            <Card className="mb-3">
              <Form.Item
                label="Tình trạng"
                name="status"
                className="label-bold mb-0"
                initialValue={DocumentStatus.Good}
              >
                <Select
                  options={documentStatuses.map((status) => ({
                    value: status,
                    label: getDocumentStatusText(status),
                  }))}
                  disabled={!editable}
                />
              </Form.Item>
            </Card>
            {!editable ? (
              <Card className="mb-3">
                <h3>Tình trạng hồ sơ</h3>
                <Form.Item
                  label="Hồ sơ số"
                  name="fileNumber"
                  className="label-bold mb-2"
                >
                  <Input disabled={!editable} />
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {(form) => (
                    <Form.Item
                      label="Hộp lưu trữ"
                      name="storageBoxName"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!editable} />
                    </Form.Item>
                  )}
                </Form.Item>

                <Form.Item
                  label="Thời hạn bảo quản"
                  name="storageTimeType"
                  className="label-bold"
                >
                  <Select
                    options={preservationOfRecordStorageTimeTypes.map(
                      (type) => ({
                        value: type,
                        label: getPreservationOfRecordStorageTimeTypeText(type),
                      })
                    )}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {(form) =>
                    form.getFieldValue("storageTimeType") ===
                      PreservationOfRecordStorageTimeType.TimeLimited && (
                      <Form.Item
                        label="Số năm bảo quản"
                        name="storageYear"
                        className="label-bold"
                        rules={[
                          { required: true },
                          { type: "integer", min: 1 },
                        ]}
                      >
                        <InputNumber className="w-100" disabled={!editable} />
                      </Form.Item>
                    )
                  }
                </Form.Item>
              </Card>
            ) : null}
            <Card>
              <Form.Item
                label="Ký hiệu văn bản"
                name="code"
                className="label-bold"
              >
                <Input disabled={!editable} />
              </Form.Item>
              <Form.Item
                label="Loại văn bản"
                name="documentType"
                className="label-bold"
                initialValue={PreservationOfRecordFileType.Paper}
              >
                <Select
                  options={preservationOfRecordFileTypes.map((type) => ({
                    value: type,
                    label: getPreservationOfRecordFileTypeText(type),
                  }))}
                  disabled={!editable}
                />
              </Form.Item>
              <Form.Item label="Tác giả" name="author" className="label-bold">
                <Input disabled={!editable} />
              </Form.Item>
              <Form.Item
                label="Ngày ban hành"
                name="dateIssued"
                className="label-bold"
              >
                <DatePicker
                  className="w-100"
                  format={FULL_DATE}
                  disabled={!editable}
                />
              </Form.Item>
              <Form.Item
                label="Số bản lưu"
                name="numberOfCopies"
                className="label-bold"
                rules={[
                  {
                    pattern: patternNumberOnly,
                    message: "Số bản lưu chỉ được phép chứa ký tự số",
                  },
                ]}
              >
                <Input disabled={!editable} />
              </Form.Item>
              <Form.Item
                label="Tờ số"
                name="sheetNumber"
                className="label-bold mb-0"
                rules={[
                  {
                    pattern: patternNumberOnly,
                    message: "Tờ số chỉ được phép chứa ký tự số",
                  },
                ]}
              >
                <Input disabled={!editable} />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const PreservationOfRecordDocumentFormMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreservationOfRecordDocumentFormBased);

export default PreservationOfRecordDocumentFormMenu;
