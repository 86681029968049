import { Menu } from "antd";
import { MenuTheme } from "antd/lib/menu/MenuContext";
import { MenuMode } from "rc-menu/lib/interface";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import usePermissions from "src/core/permission/usePermissions";
import { MenuItem } from "src/models/layout";
import BoxProfile from "src/pages/screen/Phongs/BoxProfile";
import routePaths from "src/routes/paths";
import pathMatched from "src/utilities/pathMatched";

interface Props {
  items: MenuItem[];
  mode?: MenuMode;
  theme?: MenuTheme;
  className?: string;
  showChildren?: boolean;
  onCurrentMenuItemChange?: (item: MenuItem) => void;
}

export const BasedMenu = ({
  items,
  mode,
  theme,
  className,
  showChildren = true,
  onCurrentMenuItemChange,
}: Props) => {
  const location = useLocation();
  const { can } = usePermissions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeKey, setActiveKey] = useState<string>("");
  const [, setOpenKey] = useState<React.ReactText>("");
  const [openKeys, setOpenKeys] = useState<any>([]);

  const onOpenChange = useCallback(
    (keys: React.ReactText[]) => {
      if (keys.length) {
        if (openKeys.length) {
          if (
            openKeys[0] === keys[keys.length - 1] ||
            keys[keys.length - 1] === openKeys[1]
          )
            setOpenKeys([]);
          else if (
            String(keys[keys.length - 1]).split("/")[2] ===
            String(openKeys[0]).split("/")[2]
          )
            setOpenKeys([openKeys[0], keys[keys.length - 1]]);
          else setOpenKeys([keys[keys.length - 1]]);
        } else {
          setOpenKeys(keys);
        }
      } else {
        setOpenKeys([]);
      }
    },
    [openKeys]
  );

  const setCurrentMenuItem = useCallback(
    (curItems: MenuItem[], parent?: MenuItem) => {
      for (const item of curItems) {
        if (pathMatched(location.pathname, item.path)) {
          if (
            showChildren &&
            !(typeof item.hideChildrenInMenu === "undefined"
              ? false
              : item.hideChildrenInMenu) &&
            item.routes
          ) {
            setCurrentMenuItem(item.routes, item);
            return;
          } else {
            if (item.routes && item.routes.length) {
              onCurrentMenuItemChange && onCurrentMenuItemChange(item);
              setActiveKey(item.path);
              return;
            } else if (pathMatched(location.pathname, item.path, true)) {
              onCurrentMenuItemChange && onCurrentMenuItemChange(item);
              setActiveKey(item.path);

              if (parent) {
                setOpenKey(parent.path);
              } else {
                setOpenKey("");
              }
              return;
            }
          }
        }
      }

      if (!parent) {
        setOpenKey("");
        setActiveKey("");
      }
    },
    [location.pathname, onCurrentMenuItemChange, showChildren]
  );

  useEffect(() => {
    setCurrentMenuItem(items);
  }, [setCurrentMenuItem, items]);

  const renderMenuItem = useCallback(
    (item: MenuItem) => {
      const childrenGrantedPermission = item.routes
        ? item.routes.filter((route) => can(route.permissions || [])).length > 0
        : false;
      const hasShowChildren = item.routes
        ? item.routes.filter(
            (route) =>
              typeof route.hideInMenu === "undefined" ||
              route.hideInMenu === false
          ).length > 0
        : false;

      return item.routes &&
        showChildren &&
        !(typeof item.hideChildrenInMenu === "undefined"
          ? false
          : item.hideChildrenInMenu) ? (
        childrenGrantedPermission && hasShowChildren ? (
          <Menu.SubMenu
            key={item.path}
            icon={item.icon}
            title={
              item.name && item.name.length > 26
                ? item.name?.substring(0, 24) + "..."
                : item.name
            }
          >
            {item.routes?.map((childItem) => {
              return renderMenuItem(childItem);
            })}
          </Menu.SubMenu>
        ) : null
      ) : (typeof item.hideInMenu === "undefined" ? false : item.hideInMenu) ||
        !can(item.permissions || []) ? null : (
        <Menu.Item
          key={item.path}
          title={
            item.name && item.name.length > 17
              ? item.name?.substring(0, 17) + "..."
              : item.name
          }
          icon={item.icon}
        >
          {item.path === routePaths.Screen.Phongs.managerArchives.Box ? (
            <div onClick={() => setIsModalVisible(true)}>{item.name}</div>
          ) : (
            <Link to={item.path}>{item.name}</Link>
          )}
        </Menu.Item>
      );
    },
    [showChildren]
  );

  return (
    <>
      <Menu
        theme={theme}
        openKeys={openKeys}
        selectedKeys={[activeKey]}
        mode={mode}
        className={className}
        onOpenChange={onOpenChange}
      >
        {items.map((item) => renderMenuItem(item))}
      </Menu>
      <BoxProfile
        isModalList={isModalVisible}
        handleModalList={() => setIsModalVisible(false)}
      />
    </>
  );
};
