import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { vi } from "src/localization/languages/vi";

export const resources = {
  vi: vi,
} as const;

i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: "vi",
    lng: "vi",
    debug: true,
    ns: Object.keys(resources),
    resources,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export { i18n };
