import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { LoginRequest, LoginResponse, Profile } from "src/models/auth/auth";
import { AuthReducer } from "src/models/auth/authReducer";
import { AuthState } from "src/models/auth/authState";
import { accountRepository } from "src/repositories/AccountRepository";
import { administrationRepository } from "src/repositories/AdministrationRepository";

const defaultState: AuthReducer = {
  token: null,
  profile: undefined,
};

const authModule = createReduxObservableModule(
  {
    login: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<LoginRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            accountRepository.login(action.payload).pipe(
              map((response) => {
                if (response.data.accessToken) {
                  return actions.success(response.data);
                }

                return actions.failed(response.data.error);
              }),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),

      reducers: {
        processing: (state) => {
          return {
            ...state,
            state: AuthState.loggingIn,
            data: {
              token: null,
              profile: undefined,
            },
          };
        },

        success: (state, payload: LoginResponse) => {
          return {
            ...state,
            state: AuthState.loggedIn,
            data: {
              token: payload.accessToken,
              refreshToken: payload.refreshToken,
              profile: undefined,
            },
            error: undefined,
          };
        },

        failed: (state, error) => {
          return {
            ...state,
            state: AuthState.loginFailed,
            data: {
              token: null,
              profile: undefined,
            },
            error,
          };
        },
      },
    },

    logout: {
      reducers: {
        processing: (state) => {
          return {
            ...state,
            state: AuthState.loggedOut,
            data: {
              token: null,
              profile: undefined,
            },
          };
        },
      },
    },

    fetchUser: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap(() =>
            administrationRepository.getProfile().pipe(
              map((response) => {
                return actions.success(response.data);
              }),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),

      reducers: {
        processing: (state) => {
          return {
            ...state,
            state: AuthState.loggedIn,
            data: {
              ...state.data,
              profile: undefined,
            },
          };
        },

        success: (state, payload: Profile) => {
          return {
            ...state,
            state: AuthState.loggedIn,
            data: {
              ...state.data,
              profile: payload,
            },
          };
        },

        failed: (state, error) => {
          return {
            ...state,
            state: AuthState.loginFailed,
            data: {
              token: null,
              profile: undefined,
            },
            error,
          };
        },
      },
    },
  },
  "auth",
  defaultState
);

export default authModule;
