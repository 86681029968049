import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { AllParentFieldsResponse } from "src/models/directorys/directory";
import { getAllDeliveryReportRequest } from "src/models/phongs/DeliveryReport";
import {
  ICreateIdentifyRequest,
  IUpdateIdentifyRequest,
} from "src/models/phongs/IdentifyReport";
import { DeleteRequest } from "src/models/Request";

class IdentifyReportRepository extends Repository {
  protected basePath = "/api/cms-service/identify-report";

  constructor() {
    super(env.API_CMS_URL);
  }

  getAll(params: getAllDeliveryReportRequest) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "/all",
      params,
    });
  }

  createIdentifyReport(params: ICreateIdentifyRequest) {
    return this.request({
      method: "POST",
      url: "",
      data: params,
    });
  }

  exportIdentify(id: string) {
    return this.request({
      method: "POST",
      url: `/${id}/export`,
    });
  }

  deleteIdentifyReport(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `/${param.id}`,
    });
  }

  updateIdentifyReport(params: IUpdateIdentifyRequest) {
    return this.request({
      method: "PUT",
      url: "",
      data: params,
    });
  }
}
export const identifyReportRepository = new IdentifyReportRepository();
