import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { AllArchivesResponse } from "src/models/archives";
import { AllParentFieldsResponse } from "src/models/directorys/directory";
import {
  AllDocumentsSearch,
  AllFontsSearch,
  AllProfileSearch,
  AllReaderSearch,
} from "src/models/profileSearch";

import { ExportFileResponse } from "src/models/report";
import { ListPhongsRequest } from "src/models/Request";

class ProfileSearchRepository extends Repository {
  protected basePath = "api/";

  constructor() {
    super(env.API_CMS_URL);
  }

  getProfileSearch(params: AllProfileSearch) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "cms-service/advance-search/preservation-of-records",
      params,
    });
  }

  exportProfileSearch(params: AllProfileSearch) {
    return this.request<ExportFileResponse>({
      method: "POST",
      url:
        "cms-service/advance-search-exporter/export-preservation-of-records-on",
      data: params,
    });
  }

  exportDocumentsPrint(params: AllProfileSearch) {
    return this.request<ExportFileResponse>({
      method: "GET",
      url: "cms-service/cover-word/documents-print",
      params,
    });
  }

  getDocumentsSearch(params: AllDocumentsSearch) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "cms-service/advance-search/documents",
      params,
    });
  }

  exportDocumentsSearch(params: AllDocumentsSearch) {
    return this.request<ExportFileResponse>({
      method: "POST",
      url: "cms-service/advance-search-exporter/export-document",
      data: params,
    });
  }

  getFontsSearch(params: AllFontsSearch) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "cms-service/advance-search/fonts",
      params,
    });
  }

  getReaderSearch(params: AllReaderSearch) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "cms-service/advance-search/reader",
      params,
    });
  }

  getArchivesSearch(request?: ListPhongsRequest) {
    return this.request<AllArchivesResponse>({
      method: "GET",
      url: "cms-service/archives/for-loop-search",
      params: request,
    });
  }
}
export const profileSearchRepository = new ProfileSearchRepository();
