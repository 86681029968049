import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  DashboardDataBarChartResponse,
  DashboardDataChartResponse,
  DashboardParamsRequest,
  DashboardPieChartUserResponse,
  DashboardPopoverResponse,
  DashboardSettingRequest,
} from "src/models/DashboardManagementModel";
import { PreservationOfRecordListRequest } from "src/models/PreservationOfRecord";

class DashboardRepository extends Repository {
  protected basePath = "api/";

  constructor() {
    super(env.API_CMS_URL);
  }

  getRecordByDate(params: DashboardParamsRequest) {
    return this.request<DashboardDataChartResponse>({
      method: "GET",
      url: "cms-service/dash-board/records-of-archives-by-date",
      params,
    });
  }

  getRecordWarehouse() {
    return this.request<DashboardPopoverResponse>({
      method: "GET",
      url: "cms-service/dash-board/archives-lookup-by-filter",
    });
  }

  getRecordProfileField() {
    return this.request<DashboardPopoverResponse>({
      method: "GET",
      url: "cms-service/dash-board/category-for-lookup-table",
    });
  }

  getRecordPieChart() {
    return this.request<DashboardDataChartResponse>({
      method: "GET",
      url: "cms-service/dash-board/records-of-archives",
    });
  }

  getRecordPieChartByUser() {
    return this.request<DashboardPopoverResponse>({
      method: "GET",
      url: "cms-service/dash-board/request-for-lookup-table",
    });
  }

  getRequestByUserForView(params: DashboardParamsRequest) {
    return this.request<DashboardPieChartUserResponse>({
      method: "GET",
      url: "cms-service/dash-board/request-for-view",
      params,
    });
  }

  getRecordByUserSettings(params: DashboardSettingRequest) {
    return this.request({
      method: "PUT",
      url: "cms-service/dash-board/request-report",
      data: params,
    });
  }

  getRecordBarChart(params: DashboardParamsRequest) {
    return this.request<DashboardDataBarChartResponse>({
      method: "GET",
      url: "cms-service/dash-board/records-of-field",
      params,
    });
  }

  settingFieldReport(params: DashboardSettingRequest) {
    return this.request({
      method: "PUT",
      url: "cms-service/dash-board/field-report",
      data: params,
    });
  }

  settingArchivesReport(params: DashboardSettingRequest) {
    return this.request({
      method: "PUT",
      url: "cms-service/dash-board/archives-report",
      data: params,
    });
  }

  getPreservationOfRecord(params: PreservationOfRecordListRequest) {
    return this.request<any>({
      method: "GET",
      url: "cms-service/preservation-of-record/all",
      params,
    });
  }
}

export default new DashboardRepository();
