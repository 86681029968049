import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  addPhongsProps,
  DetailsRequest,
  editPhongsProps,
  getAllPhongs,
} from "src/models/phongs";
import {
  ExportFileResponse,
  ExportToWordRequest,
  GetAllListReportResponse,
  GetOnePhongsResponse,
} from "src/models/report";
import { DeleteRequest } from "src/models/Request";
class ReportRepository extends Repository<
  ExportToWordRequest,
  ExportFileResponse
> {
  protected basePath = "api/cms-service/";

  constructor() {
    super(env.API_CMS_URL);
  }

  getAllListPhongs(params: getAllPhongs) {
    return this.request<GetAllListReportResponse>({
      method: "GET",
      url: "archives/all",
      params,
    });
  }

  getDetailsPhongs(param: DetailsRequest) {
    return this.request<GetOnePhongsResponse>({
      method: "GET",
      url: `archives/${param.id}/archives-detail-by-id`,
    });
  }

  deletePhongs(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `archives/${param.id}`,
    });
  }

  editPhongs(params: editPhongsProps) {
    return this.request({
      method: "PUT",
      url: "archives",
      data: params,
    });
  }

  addPhongs(params: addPhongsProps) {
    return this.request({
      method: "POST",
      url: "archives",
      data: params,
    });
  }
}

export default new ReportRepository();
