import { Col, Form, Input, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { Table } from "src/components";
import Select from "src/components/Select";

// import BoxList from "./components/boxList";

const { Column } = Table;
const { Item } = Form;

interface IBoxEdit {
  isAdmin: boolean;
  fetchArchiveOptions: any;
  isModalCrete: boolean;
  onFinish: (params: any) => void;
  isHandleModal: () => void;
}

const BoxCreate = ({
  isModalCrete,
  onFinish,
  isHandleModal,
  isAdmin,
  fetchArchiveOptions,
}: IBoxEdit) => {
  const [isModalVisibleCreate, setIsModalVisibleCreate] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setIsModalVisibleCreate(isModalCrete);
  }, [isModalCrete]);

  return (
    <Modal
      title="Thêm mới hộp số"
      visible={isModalVisibleCreate}
      onOk={form.submit}
      onCancel={() => {
        form.resetFields();
        // setIsModalVisibleCreate(false);
        isHandleModal();
      }}
    >
      <Form
        layout="vertical"
        name="basic"
        size="middle"
        form={form}
        onFinish={(items) => {
          onFinish(items);
          isHandleModal();
          form.resetFields();
        }}
      >
        <Row gutter={24}>
          <Col span={24}>
            {isAdmin ? (
              <Form.Item name={"archivesId"} label="Chọn phông">
                <Select.Ajax
                  onChange={(value) => {
                    // setArchivesId(value);
                  }}
                  fetchFunc={fetchArchiveOptions}
                  placeholder="Phông lưu trữ"
                  style={{ minWidth: 250 }}
                  allowClear
                />
              </Form.Item>
            ) : null}
          </Col>
          <Col span={12}>
            <Item
              label="Tên hộp số"
              name="name"
              rules={[{ required: true, message: "Vui lòng nhập!" }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={12}>
            <Item label="Trạng thái" name="status" initialValue={"true"}>
              <Select
                options={["true", "false"].map((status) => ({
                  value: status,
                  label: status === "true" ? "Hoạt động" : "Khoá",
                }))}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BoxCreate;
