import { useMemo } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { IRoute } from "src/core/models/routes";
import generateRoutes from "src/core/routes/generateRoutes";
import history from "src/core/routes/history";
import { makeId } from "src/core/utilities";

interface RouterGeneratorProps {
  guestRoutes: IRoute[];
  userRoutes: IRoute[];
  publicRoutes: IRoute[];
  isUser: boolean;
  initialRoutePath?: string;
  initialUserRoutePath: string;
  initialGuestRoutePath: string;
  notFoundpath?: string;
}

const RouterGenerator = ({
  guestRoutes,
  userRoutes,
  publicRoutes,
  isUser,
  initialGuestRoutePath,
  initialUserRoutePath,
  initialRoutePath = "/",
  notFoundpath = "/404",
}: RouterGeneratorProps) => {
  const keys = useMemo(() => {
    return [makeId(12), makeId(12), makeId(12), makeId(12)];
  }, []);

  const generatedGuestRoutes = useMemo(
    () =>
      guestRoutes.map((route) => {
        return generateRoutes(route, notFoundpath);
      }),
    [guestRoutes, notFoundpath]
  );

  const generatedGuestRedirectRoutes = useMemo(
    () =>
      guestRoutes.map((route) => {
        return generateRoutes({
          ...route,
          exact: true,
          redirect: initialUserRoutePath,
        });
      }),
    [guestRoutes, initialUserRoutePath]
  );

  const generatedUserRoutes = useMemo(
    () =>
      userRoutes.map((route) => {
        return generateRoutes(route, notFoundpath);
      }),
    [userRoutes, notFoundpath]
  );

  // Redirect from user routes to initial guest route
  const generatedUserRedirectRoutes = useMemo(
    () =>
      userRoutes.map((route) => {
        return generateRoutes({
          ...route,
          exact: true,
          redirect: initialGuestRoutePath,
        });
      }),
    [userRoutes, initialGuestRoutePath]
  );

  const generatedPublicRoutes = useMemo(
    () =>
      publicRoutes.map((route) => {
        return generateRoutes(route, notFoundpath);
      }),
    [publicRoutes, notFoundpath]
  );

  return (
    <Router history={history}>
      <Switch>
        {isUser && (
          <Route key={keys[0]}>
            <Switch>
              {generatedGuestRedirectRoutes}
              {generatedUserRoutes}
              {generatedPublicRoutes}
              <Redirect key={keys[3]} to={initialUserRoutePath} />
            </Switch>
          </Route>
        )}
        {!isUser && (
          <Route key={keys[1]}>
            <Switch>
              {generatedUserRedirectRoutes}
              {generatedGuestRoutes}
              {generatedPublicRoutes}
              <Redirect key={keys[4]} to={initialGuestRoutePath} />
            </Switch>
          </Route>
        )}
        <Redirect to={initialRoutePath} exact />
      </Switch>
    </Router>
  );
};

export default RouterGenerator;
