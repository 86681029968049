import { ofType } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { ExportToWordRequest, GetAllListRequest } from "src/models/report";
import ReportRepository from "src/repositories/ReportRepository";

const reportModule = createReduxObservableModule(
  {
    exportWordReportRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ExportToWordRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReportRepository.exportWordReport(action.payload).pipe(
            map((response) => actions.success(response))
          )
        )
      ),
    viewToPrintReport: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ExportToWordRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReportRepository.viewToPrintReport(action.payload).pipe(
            map((response) => actions.success(response))
          )
        )
      ),
    getAllListReportRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<GetAllListRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReportRepository.getAllListReport(action.payload).pipe(
            map((response) => actions.success(response.data))
          )
        )
      ),
  },
  "REPORT"
);

export default reportModule;
