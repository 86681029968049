import { lazy } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem } from "src/models/layout";
import routePaths from "src/routes/paths";

const AdministrationRoutes = (): MenuItem[] => {
  const { t } = useTranslation();
  return [
    {
      name: t("administer.user.page_title"),
      path: routePaths.Screen.Administration.User.Index,
      component: lazy(() => import("src/pages/screen/Administration/User")),
    },
  ];
};

export default AdministrationRoutes;
