import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { ListContractRequest } from "src/models/Contract";
import { ListDataRequestFormRequest } from "src/models/DataRequestForm";
import {
  DocumentListRequest,
  ExportRequestFormRequest,
  FormListRequest,
  ReaderRegisterListRequest,
  RegistrationListRequest,
} from "src/models/ReadersManagementModel";
import { FileTypeRequest } from "src/models/Request";
import ReadersRepository from "src/repositories/ReadersRepository";

const readersModule = createReduxObservableModule(
  {
    getAllListReadersRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ReaderRegisterListRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.getAllListReaders(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    createReaderRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<RegistrationListRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.createReader(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    readOneReaderRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.readOneReader(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    updateReaderRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<RegistrationListRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.updateReader(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    deleteReaderRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.deleteReader(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    getFormRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DocumentListRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.getForm(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    createFormRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<FormListRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.createForm(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    readFormRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.readForm(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    exportFormRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ExportRequestFormRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.exportForm(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    exportFormRequestToView: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<
      Action<Omit<ExportRequestFormRequest, "fileType">>
    >) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.exportFormToView(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    updateFormRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<FormListRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.updateForm(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    deleteFormRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.deleteForm(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    exportTheReaderFileRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ReaderRegisterListRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.exportTheReaderFile(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    exportRequestFormFileRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<DocumentListRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.exportRequestFormFile(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    exportDataRequestFormFileRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ListDataRequestFormRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.exportDataRequestFormFile(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    // THE EXPORT AND VIEW FILE
    exportRegisterUsingDocument: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<FileTypeRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.exportRegisterUsingDocument(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    viewRegisterUsingDocument: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.viewRegisterUsingDocument(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    exportContractFileRequest: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ListContractRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.exportContractFile(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    exportRequestDocument: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<FileTypeRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          ReadersRepository.exportRequestDocument(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "READERS"
);

export default readersModule;
