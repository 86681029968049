import React, { ReactNode, useCallback, useMemo } from "react";
import { Button as AntButton, Modal } from "antd";
import * as H from "history";
import {
  ButtonProps as AntButtonProps,
  ButtonGroupProps as AntButtonGroupProps,
} from "antd/lib/button";
import { ModalFuncProps } from "antd/lib/modal";
import { useHistory } from "react-router-dom";

export interface ButtonProps extends AntButtonProps {
  warning?: boolean;
  success?: boolean;
  light?: boolean;
  iconRight?: boolean;
  hover?: "light" | "primary" | "success" | "warning" | "danger";
  underline?: boolean;
}

const Button = ({
  warning,
  success,
  light,
  size = "middle",
  iconRight,
  hover,
  underline,
  ...props
}: ButtonProps) => {
  const className = useMemo(() => {
    let btnClasses = (props.className || "") + " ant-btn-custom";

    if (warning) {
      btnClasses += " ant-btn-warning";
    } else if (success) {
      btnClasses += " ant-btn-success";
    }

    if (light) {
      btnClasses += " ant-btn-light";
    }

    if (hover) {
      btnClasses += ` ant-btn-hover-${hover}`;
    }

    if (underline) {
      btnClasses += " ant-btn-hover-underline";
    }

    if (iconRight) {
      btnClasses += " ant-btn-icon-right";
    }

    return btnClasses;
  }, [props.className, warning, success, light, hover]);

  return <AntButton {...props} className={className} size={size} />;
};

interface ButtonConfirmProps extends ButtonProps {
  modalConfig: ModalFuncProps;
}

Button.Confirm = ({ modalConfig, ...props }: ButtonConfirmProps) => {
  const openModal = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      Modal.confirm({
        ...modalConfig,
      });
    },
    [modalConfig]
  );

  return <Button {...props} onClick={openModal} />;
};

Button.ConfirmDelete = (props: ButtonConfirmProps) => {
  return (
    <Button.Confirm
      {...props}
      modalConfig={{
        okButtonProps: {
          danger: true,
        },
        ...props.modalConfig,
      }}
    />
  );
};

interface ButtonLinkProps extends ButtonProps {
  to:
    | H.LocationDescriptor<H.LocationState>
    | ((
        location: H.Location<H.LocationState>
      ) => H.LocationDescriptor<H.LocationState>);
}

Button.Link = ({ to, ...props }: ButtonLinkProps) => {
  const history = useHistory();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      if (typeof to === "function") {
        history.push(to(history.location));
      } else {
        history.push(to);
      }
    },
    [to, history]
  );

  return <Button {...props} onClick={handleClick} />;
};

interface ButtonGroupProps extends AntButtonGroupProps {
  shape?: "round";
  children: ReactNode;
}

Button.Group = ({ shape, ...props }: ButtonGroupProps) => (
  <AntButton.Group
    {...props}
    className={`${props.className || ""}${
      shape === "round" ? " ant-btn-group-round" : ""
    }`}
  />
);

export default Button;
