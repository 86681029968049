import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { ofType } from "redux-observable";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { of } from "rxjs";
import { Action } from "src/core/models/redux";
import { OrganizationUnitsProps } from "src/models/organizationUnits";
import { DeleteRequest } from "src/models/Request";
// import organizationUnitsRepository from "src/repositories/OrganizationUnitsRepository";
import RoleRepository from "src/repositories/RoleRepository";
import { addOrEditRolesProps, getRolesProps } from "src/models/roles";

const RolesModule = createReduxObservableModule(
  {
    getAllRole: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<getRolesProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            RoleRepository.getAllRole(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    deleteRole: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DeleteRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            RoleRepository.deleteRole(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    editRoleModule: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<addOrEditRolesProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            RoleRepository.editRole(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    addRoleModule: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<addOrEditRolesProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            RoleRepository.addRole(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    getAllTree: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<getRolesProps>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            RoleRepository.getAllTree(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    getAllPermissionsUser: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap(() =>
            RoleRepository.getPermissionUser().pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
  },
  "ROLES"
);

export default RolesModule;
