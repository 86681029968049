import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { AllParentFieldsResponse } from "src/models/directorys/directory";
import {
  createDeliveryReportRequest,
  getAllDeliveryReportRequest,
  updateDeliveryReportRequest,
} from "src/models/phongs/DeliveryReport";
import { DeleteRequest } from "src/models/Request";

class DeliveryReportRepository extends Repository {
  protected basePath = "api/cms-service/delivery-report";

  constructor() {
    super(env.API_CMS_URL);
  }

  getAll(params: getAllDeliveryReportRequest) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "/all",
      params,
    });
  }

  createDeliveryReport(params: createDeliveryReportRequest) {
    return this.request({
      method: "POST",
      url: "",
      data: params,
    });
  }

  exportDelivery(id: string) {
    return this.request({
      method: "POST",
      url: `/${id}/export`,
    });
  }

  deleteDeliveryReport(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `/${param.id}`,
    });
  }

  updateDeliveryReport(params: updateDeliveryReportRequest) {
    return this.request({
      method: "PUT",
      url: "",
      data: params,
    });
  }
}
export const deliveryReportRepository = new DeliveryReportRepository();
