import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  ReduxObservableModuleEpicProps,
  createReduxObservableModule,
} from "src/core/redux/ReduxObservableModule";
import {
  CreateNewsletterRequest,
  ImportRequest,
  PreservationOfRecordListRequest,
  PreservationOfRecordOwnerType,
  PreservationOfRecordRequest,
} from "src/models/PreservationOfRecord";
import PreservationOfRecordRepository from "src/repositories/PreservationOfRecordRepository";

const preservationOfRecordModule = createReduxObservableModule(
  {
    getList: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<
      Action<PreservationOfRecordListRequest & { all?: boolean }>
    >) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          (action.payload.all
            ? PreservationOfRecordRepository.getOrganization(action.payload)
            : PreservationOfRecordRepository.getForCirculation(action.payload)
          ).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    getFileType: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<
      Action<PreservationOfRecordListRequest & { all?: boolean }>
    >) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.getFileType(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    getOne: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.getOne(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    create: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<PreservationOfRecordRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.create(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    update: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<PreservationOfRecordRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.update(
            action.payload.id,
            action.payload
          ).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    delete: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.delete(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    downloadImportFileTemplate: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<PreservationOfRecordOwnerType>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.downloadImportFileTemplate(
            action.payload
          ).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    downloadImportCoordinateFileTemplate: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap(() =>
          PreservationOfRecordRepository.downloadImportCoordinateFileTemplate().pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    downloadCoverWord: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.downloadCoverWord(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    import: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<ImportRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.import(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    deleteImport: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<
      Action<{
        ownerType: PreservationOfRecordOwnerType;
        archivesIdInput: string;
      }>
    >) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.deleteImport(action.payload).pipe(
            map((response) => actions.success(response.data)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
    exportOne: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<string>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.exportOne(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    createNewsletter: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<CreateNewsletterRequest>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          PreservationOfRecordRepository.createNewsletter(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),
  },
  "preservationOfRecord"
);

export default preservationOfRecordModule;
