import React from "react";
import usePermissions from "src/core/permission/usePermissions";

interface PrivateViewProps {
  permissions: string | string[];
  children?: React.ReactNode;
  guestView?: React.ReactNode;
}

export const PrivateView = ({
  permissions,
  children,
  guestView,
}: PrivateViewProps) => {
  const { granted } = usePermissions(permissions);
  return <>{granted ? children : guestView}</>;
};
