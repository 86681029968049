import { IRoute } from "src/core/models/routes";

export function findRouteHasPermission(
  routes: IRoute[],
  permissions: string[]
): IRoute | undefined {
  function can(perms: string | string[]) {
    let listPermissions: string[] = [];

    if (!Array.isArray(perms)) {
      listPermissions = perms.split(",");
    } else {
      listPermissions = perms;
    }

    if (!listPermissions.length) {
      return true;
    }

    const granted =
      permissions.filter((perm) => listPermissions.includes(perm)).length > 0;

    return granted;
  }

  for (const route of routes) {
    if (!can(route.permissions || [])) {
      continue;
    }

    if (route.routes) {
      const foundRoute = findRouteHasPermission(route.routes, permissions);

      if (foundRoute) {
        return foundRoute;
      }

      continue;
    }

    return route;
  }

  return undefined;
}
