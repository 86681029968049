import { message } from "antd";
import { useState } from "react";
import env from "src/core/env";
import { ArchivesEditRequest, PostProfileArchives } from "src/models/archives";
import { DeleteRequest } from "src/models/Request";
import { directorysRepository } from "src/repositories/DirectorysRepository";

export function handleLogicBox() {
  const [status, setStatus] = useState<string>();

  const create = (params: PostProfileArchives) => {
    setStatus("");
    directorysRepository
      .addNewFrofileArchives(params)
      .toPromise()
      .then((success) => {
        setStatus("create");
        message.success("Thêm mới thành công");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const deletes = (params: DeleteRequest) => {
    setStatus("");
    directorysRepository
      .deleteProfileArchives(params)
      .toPromise()
      .then((success) => {
        message.success("Xoá thành công");
        setStatus("delete");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const update = (params: ArchivesEditRequest) => {
    setStatus("");
    directorysRepository
      .editProfileArchivesType(params)
      .toPromise()
      .then(() => {
        setStatus("update");
        message.success("Cập nhật thành công");
      });
  };

  const exportFile = (id: string) => {
    setStatus("");
    directorysRepository
      .exportFileBox(id)
      .toPromise()
      .then((res) => {
        console.log("res", res.data);
        const { fileName, fileType, fileToken } = res.data;

        window.location.assign(
          env.API_CMS_URL +
            `api/File/DownloadTempFile?FileName=${fileName}&FileType=${fileType}&FileToken=${fileToken}`
        );
        message.success("Xuất file thành công.");
      });
    console.log("first");
  };
  return {
    create,
    deletes,
    update,
    status,
    exportFile,
  };
}
