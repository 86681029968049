import errors from "./errors.json";
import pages from "./pages.json";
import models from "./models";
import actions from "./actions.json";

export const vi = {
  errors,
  pages,
  models,
  actions,
};
