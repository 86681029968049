import { DownloadOutlined } from "@ant-design/icons";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Typography } from "antd";
import { Button, Table } from "src/components";
import Tag from "src/components/tags";
import Permissions from "src/constants/permessions";
import { PrivateView } from "src/core/permission/PrivateView";
import { IActionsDeleteOrEdit } from "src/models/phongs/boxInPhongs";
import { getUserStatus } from "src/models/Status";

const { Column } = Table;

interface IBoxList {
  data: any[];

  actions: (recold: IActionsDeleteOrEdit) => any;
}

const BoxList = ({ data, actions }: IBoxList) => {
  return (
    <Table
      onRow={(record: any) => {
        return {
          onClick: () => {
            // profile(record);
          },
        };
      }}
      dataSource={data}
      pagination={false}
      size="small"
      striped
      bordered
    >
      <Column
        dataIndex="name"
        title="Tên hộp"
        width={150}
        render={(text: string, record: any) => {
          return <a>{record.name}</a>;
        }}
      />
      <Column
        dataIndex="status"
        title="Trạng thái"
        render={(status: any) => {
          const { label, backgroundColor, color } = getUserStatus(status);
          return (
            <div className="align">
              <Tag color={backgroundColor}>
                <Typography.Text style={{ color }}>{label}</Typography.Text>
              </Tag>
            </div>
          );
        }}
      />
      <Column
        align="center"
        title="Thao tác"
        render={(text: string, record: any) => {
          return (
            <>
              <PrivateView permissions={Permissions.Permission.Archives.Edit}>
                <Tooltip title="Sửa">
                  <Button
                    icon={<FontAwesomeIcon icon={faEdit} />}
                    type="link"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (record?.id) {
                        const newRow = {
                          ...record,
                          status: record.status ? "true" : "false",
                        };
                        actions({ action: "edit", datas: newRow });
                        // editBoxTable(newRow);
                      }
                    }}
                  />
                </Tooltip>
              </PrivateView>
              <PrivateView permissions={Permissions.Permission.Archives.Edit}>
                <Tooltip title="Xuất file">
                  <Button
                    onClick={() => actions({ action: "export", id: record.id })}
                    icon={<DownloadOutlined />}
                    type="link"
                  />
                </Tooltip>
              </PrivateView>
              <PrivateView permissions={Permissions.Permission.Archives.Delete}>
                <Tooltip title="Xoá">
                  <Button.ConfirmDelete
                    modalConfig={{
                      title: "Xoá dữ liệu",
                      content: "Bạn chắc chắn muốn xoá dữ liệu này?",
                      onOk: () => {
                        actions({ action: "delete", id: record.id });
                        // deleteBoxTable(record.id);
                      },
                    }}
                    type="link"
                    icon={<FontAwesomeIcon icon={faTrash} />}
                    danger
                  />
                </Tooltip>
              </PrivateView>
            </>
          );
        }}
      />
    </Table>
  );
};

export default BoxList;
