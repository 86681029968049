import { lazy } from "react";
import { MenuItem } from "src/models/layout";
import routePaths from "src/routes/paths";

const AuthRoutes = (): MenuItem[] => {
  return [
    {
      path: routePaths.Auth.Index,
      component: lazy(() => import("src/pages/screen/Auth/login")),
    },
    {
      path: routePaths.Auth.ForgotPassword,
      component: lazy(() => import("src/pages/screen/Auth/forgotPassword")),
    },
    {
      path: routePaths.Auth.NewPassword,
      component: lazy(() => import("src/pages/screen/Auth/newPassword")),
    },
    {
      path: routePaths.Auth.ChangeSuccesPassword,
      component: lazy(
        () => import("src/pages/screen/Auth/successChangePassword")
      ),
    },
  ];
};

export default AuthRoutes;
