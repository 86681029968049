import { iif, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { formData } from "src/core/utilities";
import {
  CreateNewsletterRequest,
  ImportRequest,
  ImportResponse,
  PreservationOfRecordCoordinate,
  PreservationOfRecordListRequest,
  PreservationOfRecordListResposne,
  PreservationOfRecordOwnerType,
  PreservationOfRecordRequest,
  PreservationOfRecordResponse,
} from "src/models/PreservationOfRecord";
import { ExportFileResponse } from "src/models/report";

class PreservationOfRecordRepository extends Repository<
  PreservationOfRecordRequest,
  PreservationOfRecordResponse,
  PreservationOfRecordListRequest,
  PreservationOfRecordListResposne
> {
  protected basePath = "api/cms-service/preservation-of-record";

  constructor() {
    super(env.API_CMS_URL);
  }

  getForCirculation(request: PreservationOfRecordListRequest) {
    return this.request<PreservationOfRecordListResposne>({
      method: "get",
      url: "/all-for-circulation",
      params: request,
    });
  }

  getOrganization(request: PreservationOfRecordListRequest) {
    return this.request<PreservationOfRecordListResposne>({
      method: "get",
      url: `/all?TypeFilter=${PreservationOfRecordOwnerType.Organization}`,
      params: request,
    });
  }

  getFileType(request: PreservationOfRecordListRequest) {
    return this.request<PreservationOfRecordListResposne>({
      method: "get",
      url: `/all?TypeFilter=${PreservationOfRecordOwnerType.FileType}`,
      params: request,
    });
  }

  downloadImportFileTemplate(ownerType: PreservationOfRecordOwnerType) {
    return this.api
      .post<ExportFileResponse | null>(
        "api/cms-service/export-temp-file-records/export-file-dto",
        {},
        {
          params: {
            ownerType,
          },
        }
      )
      .pipe(
        mergeMap(({ data }) => {
          return iif(
            () => data !== null && data !== undefined,
            this.api
              .get<Blob>("api/File/DownloadTempFileImport", data, {
                responseType: "blob",
              })
              .pipe(
                map(({ data: fileData }) => ({
                  fileName: data?.fileName,
                  fileContent: fileData,
                }))
              ),
            of(null)
          );
        })
      );
  }

  downloadImportCoordinateFileTemplate() {
    return this.api
      .post<ExportFileResponse>(
        "api/cms-service/export-temp-file-records/export-file-coordinate-dto",
        {
          maxResultCount: 1,
          skipCount: 0,
        }
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFileImport", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  downloadCoverWord(id: string) {
    return this.api
      .post<ExportFileResponse>(
        `api/cms-service/cover-word/${id}/export-to-word?locationPath=${window.location.origin}`,
        {
          maxResultCount: 1,
          skipCount: 0,
        }
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFile", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  import(request: ImportRequest) {
    return this.request<ImportResponse>({
      url: "/import-preservation-of-record",
      method: "post",
      data: formData(request),
    });
  }

  deleteImport({
    ownerType,
    archivesIdInput,
    StorageTimeType,
  }: {
    ownerType: PreservationOfRecordOwnerType;
    archivesIdInput: string;
    StorageTimeType?: number;
  }) {
    return this.request<boolean>({
      url: "/import-lastest",
      method: "delete",
      params: { ownerType, archivesIdInput, storageTimeType: StorageTimeType },
    });
  }

  checkCoordinatePoints(
    points: PreservationOfRecordCoordinate<string, string>[]
  ) {
    return this.request<{ [key: number]: string[] }>({
      url: "/check-coordinate-point",
      method: "post",
      data: { coordinatePointDtos: points },
    });
  }

  exportOne(id: string) {
    return this.api
      .post<ExportFileResponse>(
        `/api/cms-service/export-temp-file-records/download-file/${id}`,
        {}
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFileImport", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  createNewsletter(data: CreateNewsletterRequest) {
    return this.request({
      method: "POST",
      url: `/by-file`,
      data,
    });
  }

  getGenerateNumber(request: string) {
    return this.request<any>({
      method: "get",
      url: `/generate-file-number-of-archive/${request}`,
    });
  }
}

export default new PreservationOfRecordRepository();
