import { map, mergeMap } from "rxjs/operators";
import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  BatchCreateDocumentRequest,
  DocumentRequest,
  DocumentRequestGetList,
  DocumentResponse,
  ImportRequest,
  ImportResponse,
  ListDocumentRequest,
  ListDocumentResponse,
  ListExportDocument,
} from "src/models/Doucment";
import { ExportFileResponse } from "src/models/report";

class DocumentRepository extends Repository<
  DocumentRequest,
  DocumentResponse,
  ListDocumentRequest,
  ListDocumentResponse
> {
  protected basePath = "api/cms-service/document";

  constructor() {
    super(env.API_CMS_URL);
  }

  downloadImportFileTemplate() {
    return this.api
      .post<ExportFileResponse>(
        "/api/cms-service/export-temp-file-records/export-document-dto",
        {
          maxResultCount: 1,
          skipCount: 0,
        }
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFileImport", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  import(request: ImportRequest) {
    return this.api.multipartPost<ImportResponse>(
      "/api/cms-service/preservation-of-record/import-document",
      request
    );
  }

  downloadFiles(id: string) {
    return this.api
      .post<ExportFileResponse>(
        "/api/cms-service/export-temp-file-records/download-file-document/" +
          id,
        {
          maxResultCount: 1,
          skipCount: 0,
        }
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFileImport", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  export(data: ListExportDocument) {
    return this.api
      .post<ExportFileResponse>(
        "/api/cms-service/advance-search-exporter/export-document",
        data
      )
      .pipe(
        mergeMap(({ data }) => {
          return this.api
            .get<Blob>("api/File/DownloadTempFileImport", data, {
              responseType: "blob",
            })
            .pipe(
              map(({ data: fileData }) => ({
                fileName: data.fileName,
                fileContent: fileData,
              }))
            );
        })
      );
  }

  batchCreate(data: BatchCreateDocumentRequest) {
    return this.request({ url: "/documents", method: "post", data });
  }

  getListDocument(data: DocumentRequestGetList) {
    return this.api.get<DocumentRequestGetList>(
      this.basePath + "/table-of-content",
      data
    );
  }
}

export default new DocumentRepository();
