import { Col, Form, Input, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { Table } from "src/components";
import Select from "src/components/Select";
import {
  getPreservationOfRecordStorageTimeTypeText,
  preservationOfRecordStorageTimeTypes,
} from "src/models/PreservationOfRecord";

// import BoxList from "./components/boxList";

const { Column } = Table;
const { Item } = Form;

interface IBoxEdit {
  isModalEdit: boolean;
  data: any;
  onFinish: (params: any) => void;
  isHandleModal: () => void;
}

const BoxEdit = ({ isModalEdit, data, onFinish, isHandleModal }: IBoxEdit) => {
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [formBox] = Form.useForm();

  useEffect(() => {
    setIsModalVisibleEdit(isModalEdit);
    formBox.setFieldsValue(data);
  }, [isModalEdit]);

  return (
    <Modal
      title="Sửa hộp số"
      visible={isModalVisibleEdit}
      onOk={formBox.submit}
      onCancel={() => {
        formBox.resetFields();

        isHandleModal();
      }}
    >
      <Form
        layout="vertical"
        name="basic"
        size="middle"
        form={formBox}
        onFinish={(items) => {
          onFinish(items);
        }}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Item
              label="Tên hộp số"
              name="name"
              rules={[{ required: true, message: "Vui lòng nhập!" }]}
            >
              <Input />
            </Item>
          </Col>
          <Col span={8}>
            <Item label="Trạng thái" name="status">
              <Select
                options={["true", "false"].map((status) => ({
                  value: status,
                  label: status === "true" ? "Hoạt động" : "Khoá",
                }))}
              />
            </Item>
            <Item name="id" hidden />
            <Item name="archivesId" hidden />
            <Item name="type" hidden />
          </Col>
          <Col span={8}>
            <Form.Item
              label="Thời hạn bảo quản"
              name="timeType"
              className="label-bold"
            >
              <Select
                options={preservationOfRecordStorageTimeTypes.map((type) => ({
                  value: type,
                  label: getPreservationOfRecordStorageTimeTypeText(type),
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default BoxEdit;
