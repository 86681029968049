import { applyMiddleware, compose, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import env from "src/core/env";
import { rootEpic, rootReducer } from "./modules";

const epicMiddleware = createEpicMiddleware();

const persistConfig = {
  key: env.APP_NAME + "root",
  storage,
  whitelist: ["auth", "phongApp"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
  const store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(epicMiddleware)
      // (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      //   (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
  const persistor = persistStore(store);

  epicMiddleware.run(rootEpic);

  return { store, persistor };
}
