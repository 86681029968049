import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, InputNumber, message, Space, Tooltip } from "antd";
import { useCallback } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Button, DatePicker } from "src/components";
import TableFormList from "src/components/TableFormList";
import { useDidUpdate, useLoading, useProgressMessage } from "src/core/hooks";
import { ReduxObservableModuleReducer } from "src/core/redux/ReduxObservableModule";
import { BatchCreateDocumentRequest } from "src/models/Doucment";
import { RootReducer } from "src/redux";
import documentModule from "src/redux/modules/document";

interface PreservationOfRecordDocumentQuickFormProps {
  batchCreateDocument: (data: BatchCreateDocumentRequest) => void;
  documentReducer: ReduxObservableModuleReducer;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const mapStateToProps = (state: RootReducer) => ({
  documentReducer: state.document,
});

const mapDispatchToProps = {
  batchCreateDocument: documentModule.actions.batchCreate.start,
};

const PreservationOfRecordDocumentQuickFormBased = ({
  batchCreateDocument,
  documentReducer,
  onSuccess,
  onCancel,
}: PreservationOfRecordDocumentQuickFormProps) => {
  const { startLoading, stopLoading } = useLoading();
  const { startProgress, progressSuccess, progressFail } = useProgressMessage();
  const { id: preservationOfRecordId } = useParams<{ id: string }>();
  const [form] = Form.useForm<BatchCreateDocumentRequest>();

  const handleSubmit = useCallback(
    (values: BatchCreateDocumentRequest) => {
      console.log("first", values);
      const tempArr: string[] = [];
      values.documentDtos.length > 0 &&
        values.documentDtos.forEach((element) => {
          if (element.content) {
            const temp = element.content.trim();
            if (temp) {
              tempArr.push(temp);
            }
          }
        });
      console.log("tempArr.length", tempArr);
      if (tempArr.length === values.documentDtos.length) {
        batchCreateDocument({
          documentDtos: values.documentDtos.map((doc) => ({
            ...doc,
            preservationOfRecordId,
          })),
        });
      } else {
        message.error("Trường trích yếu nội dung nhập sai định dạng");
      }
    },
    [preservationOfRecordId]
  );

  useDidUpdate(() => {
    const {
      success,
      processing,
      failed,
    } = documentModule.reducerStates.batchCreate;
    const jobs = {
      [processing]: () => {
        startLoading();
        startProgress("Đang thêm mục lục văn bản...");
      },
      [success]: () => {
        stopLoading();
        progressSuccess("Thêm mục lục văn bản thành công!");
        onSuccess && onSuccess();
      },
      [failed]: () => {
        stopLoading();
        progressFail("Thêm mục lục văn bản thất bại!");
      },
    };

    documentReducer.state &&
      jobs[documentReducer.state] &&
      jobs[documentReducer.state]();
  }, [documentReducer.state]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <TableFormList
        name="documentDtos"
        initialValue={[{}]}
        toggleEdit={false}
        header={({ add }) => (
          <div className="text-right">
            <Button onClick={() => add({})} light>
              Thêm dòng
            </Button>
          </div>
        )}
        columns={[
          {
            render: (_, __, index) => index + 1,
            title: "STT",
          },
          {
            title: "Số, ký hiệu",
            dataIndex: "code",
            customInput: <Input />,
            editable: true,
          },
          {
            title: "Ngày ban hành",
            dataIndex: "dateIssued",
            customInput: <DatePicker className="w-100" />,
            editable: true,
          },
          {
            title: "Tác giả",
            dataIndex: "author",
            customInput: <Input />,
            editable: true,
          },
          {
            title: "Trích yếu nội dung",
            dataIndex: "content",
            customInput: <Input.TextArea />,
            editable: true,
          },
          {
            title: "Tờ số",
            dataIndex: "sheetNumber",
            customInput: <InputNumber className="w-100" stringMode />,
            editable: true,
          },
          {
            title: "Thao tác",
            render: (_, __, index, { remove }) => (
              <Tooltip title="Xóa">
                <Button.ConfirmDelete
                  modalConfig={{
                    title: "Xóa tọa độ",
                    content: "Bạn chắc chắn muốn xóa tọa độ này?",
                    onOk: () => remove(index),
                  }}
                  type="link"
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  danger
                  disabled={index === 0}
                />
              </Tooltip>
            ),
          },
        ]}
      />
      <Space className="d-flex justify-content-end mt-3">
        <Button.ConfirmDelete
          modalConfig={{
            title: "Bạn muốn hủy bỏ?",
            content: "Bạn có chắc chắn muổn hủy thao tác này?",
            onOk: onCancel,
          }}
          danger
        >
          Hủy bỏ
        </Button.ConfirmDelete>
        <Button htmlType="submit" type="primary">
          Lưu
        </Button>
      </Space>
    </Form>
  );
};

const PreservationOfRecordDocumentQuickForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreservationOfRecordDocumentQuickFormBased);

export default PreservationOfRecordDocumentQuickForm;
