import { ConfigProvider, message } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Api } from "src/core/api";
import { useDidUpdate, useLoading, useMount, useToggle } from "src/core/hooks";
import PermissionProvider from "src/core/permission/PermissionProvider";
import { findRouteHasPermission } from "src/core/routes/helpers";
import TokenManager from "src/core/utilities/TokenManager";
import useAuth from "src/hooks/modules/useAuth";
import { i18n } from "src/localization";
import viAntLocale from "src/localization/languages/vi/antLocale";
import AccessDenied from "src/pages/AccessDenied";
import AuthHandler from "src/providers/AuthHandler";
import RoleRepository from "src/repositories/RoleRepository";
import { AppRoutes } from "src/routes";
import useScreenRoutes from "src/routes/screenRoutes";
const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function AppRoot() {
  const [antLocale, setAntLocale] = useState(viAntLocale);

  useMount(() => {
    i18n.on("languageChanged", (lang) => {
      switch (lang) {
        case "vi":
          setAntLocale(viAntLocale);
      }
    });
  });

  // Handle auth state and token
  const { isUser, token, logout, fetchUserProfile } = useAuth();
  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    if (isUser) {
      fetchUserProfile();
    }
  }, [isUser]);

  useDidUpdate(() => {
    if (isUser) {
      message.success("Đăng nhập thành công!");
      TokenManager.setToken("base_token", token || "");
    } else {
      TokenManager.setToken("base_token", "");
      message.destroy();
      stopLoading();
    }
  }, [isUser]);

  // Handle permission
  const [permissions, setPermission] = useState<string[]>([]);
  const {
    state: fetchingPermission,
    on: permissionFetching,
    off: permissionFetched,
  } = useToggle(true);

  const getPermissions = async (): Promise<any> => {
    try {
      permissionFetching();
      startLoading();
      const response = await RoleRepository.getPermissionUser().toPromise();
      setPermission(response.data);
      permissionFetched();
      stopLoading();
    } catch (error) {
      stopLoading();
      permissionFetched();
      setPermission([]);
    }
  };

  useEffect(() => {
    getPermissions();
  }, [isUser]);

  // Handle redirect after login
  const { state: isRedirectNeeded, on: needRedirect } = useToggle(false);
  const routes = useScreenRoutes();

  useDidUpdate(() => {
    if (isUser) {
      needRedirect();
    }
  }, [isUser]);

  useDidUpdate(() => {
    if (isRedirectNeeded && permissions.length > 0) {
      const route = findRouteHasPermission(routes, permissions);

      if (route) {
        location.href = route.path;
      }
    }
  }, [permissions]);

  // Handle api error
  useMount(() => {
    const removeInterceptor = Api.addInterceptor({
      response: {
        success(response) {
          if (response.data.error) {
            if (response.data.errorDescription !== "Tài khoản không tồn tại") {
              // message.error(response.data.error.message);
            }
          }
          return response;
        },

        error(error) {
          if (error.request) {
            if (error.request.status === 401) {
              logout();
              return;
            }

            if (error.request.status === 403) {
              message.destroy();
              message.error("Bạn không có quyền truy cập vào tài nguyên này");
              return;
            }

            if (!error.request.response) {
              return;
            }

            const response = JSON.parse(error.request.response);
            if (response.error && response.error.message) {
              if (
                response.error.message === "Worksheet position out of range."
              ) {
                message.error("File import sai định dạng");
              } else if (
                response.error.message !== "Incorrect password." &&
                response.error.message !==
                  "Tên đăng nhập hoặc mật khẩu không đúng" &&
                response.error.message !==
                  "The user account has been locked out due to invalid login attempts. Please wait a while and try again."
              ) {
                message.error(response.error.message);
              }
            }
          }

          return error;
        },
      },
    });

    return removeInterceptor;
  });

  return (
    <ConfigProvider locale={antLocale}>
      {!fetchingPermission && !isRedirectNeeded && (
        <PermissionProvider
          userPermissions={permissions}
          isUser={isUser}
          deniedView={<AccessDenied width="100%" height="100%" />}
        >
          <AuthHandler />
          <AppRoutes />
        </PermissionProvider>
      )}
    </ConfigProvider>
  );
}

export default AppRoot;
