import { message } from "antd";
import { ActionsObservable, combineEpics, Epic } from "redux-observable";
import { catchError } from "rxjs/operators";
import { i18n } from "src/localization";
import accountModule from "src/redux/modules/account";
import administrationModule from "src/redux/modules/administration";
import advancedSearchModule from "src/redux/modules/advancedSearch";
import archiverModule from "src/redux/modules/archives";
import authModule from "src/redux/modules/auth";
import circulationDocumentModule from "src/redux/modules/circulationDocument";
import classifyReportModule from "src/redux/modules/ClassifyReport";
import contractModule from "src/redux/modules/contract";
import dashboardModule from "src/redux/modules/dashboard";
import dataRequestFormModule from "src/redux/modules/dataRequestForm";
import deliveryReportModule from "src/redux/modules/deliveryReport";
import directorysModule from "src/redux/modules/directorys";
import documentModule from "src/redux/modules/document";
import historyReportModule from "src/redux/modules/historyReport";
import identifyReportModule from "src/redux/modules/identifyReport";
import newslesterFormModule from "src/redux/modules/newsletterForm";
import organizationUnitsModule from "src/redux/modules/organizationUnits";
import phongsModule from "src/redux/modules/phongs";
import planReportModule from "src/redux/modules/planReport";
import preservationOfRecordModule from "src/redux/modules/preservationOfRecord";
import profileModule from "src/redux/modules/profile";
import readersModule from "src/redux/modules/readers";
import reportModule from "src/redux/modules/report";
import roleModule from "src/redux/modules/role";
import typeClassifyReport from "src/redux/modules/typeClassifyReport";
import finalReportModule from "./finalReport";

export const rootEpic: Epic = (
  action$: ActionsObservable<any>,
  store$,
  dependencies
) =>
  combineEpics(
    ...authModule.epics,
    ...accountModule.epics,
    ...directorysModule.epics,
    ...administrationModule.epics,
    ...archiverModule.epics,
    ...advancedSearchModule.epics,
    ...preservationOfRecordModule.epics,
    ...reportModule.epics,
    ...profileModule.epics,
    ...documentModule.epics,
    ...phongsModule.epics,
    ...dashboardModule.epics,
    ...organizationUnitsModule.epics,
    ...roleModule.epics,
    ...readersModule.epics,
    ...circulationDocumentModule.epics,
    ...contractModule.epics,
    ...dataRequestFormModule.epics,
    ...deliveryReportModule.epics,
    ...identifyReportModule.epics,
    ...historyReportModule.epics,
    ...classifyReportModule.epics,
    ...planReportModule.epics,
    ...typeClassifyReport.epics,
    ...finalReportModule.epics,
    ...newslesterFormModule.epics
  )(action$, store$, dependencies).pipe(
    catchError((_, source) => {
      message.error(i18n.t("errors:server_error"));
      return source;
    })
  );
