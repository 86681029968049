import env from "src/core/env";
import { Repository } from "src/core/Repository";
import {
  CirculationDocumentDetail,
  CirculationDocumentRequest,
  ListCirculationDocumentRequest,
  ListCirculationDocumentResponse,
} from "src/models/CirculationDocument";

class CirculationDocumentRepository extends Repository<
  CirculationDocumentRequest,
  CirculationDocumentDetail,
  ListCirculationDocumentRequest,
  ListCirculationDocumentResponse
> {
  protected basePath = "api/cms-service/circulation-document";

  constructor() {
    super(env.API_CMS_URL);
  }
}

export default new CirculationDocumentRepository();
