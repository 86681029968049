import { combineReducers } from "redux";
import accountModule from "src/redux/modules/account";
import administrationModule from "src/redux/modules/administration";
import profileSearchModule from "src/redux/modules/advancedSearch";
import archivesModule from "src/redux/modules/archives";
import authModule from "src/redux/modules/auth";
import circulationDocumentModule from "src/redux/modules/circulationDocument";
import classifyReportModule from "src/redux/modules/ClassifyReport";
import contractModule from "src/redux/modules/contract";
import dashboardModule from "src/redux/modules/dashboard";
import dataRequestFormModule from "src/redux/modules/dataRequestForm";
import deliveryReportModule from "src/redux/modules/deliveryReport";
import directorysModule from "src/redux/modules/directorys";
import documentModule from "src/redux/modules/document";
import historyReportModule from "src/redux/modules/historyReport";
import identifyReportModule from "src/redux/modules/identifyReport";
import newsletterFormModule from "src/redux/modules/newsletterForm";
import organizationUnitsModule from "src/redux/modules/organizationUnits";
import { phongAppReducer } from "src/redux/modules/phongApp";
import phongsModule from "src/redux/modules/phongs";
import planReportModule from "src/redux/modules/planReport";
import preservationOfRecordModule from "src/redux/modules/preservationOfRecord";
import profileModule from "src/redux/modules/profile";
import readersModule from "src/redux/modules/readers";
import reportModule from "src/redux/modules/report";
import roleModule from "src/redux/modules/role";
import typeClassifyReport from "src/redux/modules/typeClassifyReport";
import finalReportModule from "./finalReport";

export const rootReducer = combineReducers({
  auth: authModule.reducer,
  account: accountModule.reducer,
  directory: directorysModule.reducer,
  administration: administrationModule.reducer,
  archives: archivesModule.reducer,
  prifileSearch: profileSearchModule.reducer,
  preservationOfRecord: preservationOfRecordModule.reducer,
  report: reportModule.reducer,
  profileModule: profileModule.reducer,
  document: documentModule.reducer,
  phongsModule: phongsModule.reducer,
  dashboard: dashboardModule.reducer,
  organizationUnitsModule: organizationUnitsModule.reducer,
  roleModule: roleModule.reducer,
  readers: readersModule.reducer,
  circulationDocument: circulationDocumentModule.reducer,
  contract: contractModule.reducer,
  dataRequestForm: dataRequestFormModule.reducer,
  deliveryReport: deliveryReportModule.reducer,
  identifyReportModule: identifyReportModule.reducer,
  historyReport: historyReportModule.reducer,
  classifyReport: classifyReportModule.reducer,
  planReport: planReportModule.reducer,
  typeClassifyReport: typeClassifyReport.reducer,
  finalReport: finalReportModule.reducer,
  newsletterForm: newsletterFormModule.reducer,
  phongApp: phongAppReducer,
});
