import { lazy, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Permissions from "src/constants/permessions";
import { MenuItem } from "src/models/layout";
import { PreservationOfRecordOwnerType } from "src/models/PreservationOfRecord";
import routePaths from "src/routes/paths";

// Circulation document
const CirculationDocumentIndexPage = lazy(
  () => import("src/pages/screen/PreservationOfRecord/CirculationDocument")
);

const CirculationDocumentCreatePage = lazy(
  () =>
    import("src/pages/screen/PreservationOfRecord/CirculationDocument/Create")
);

const CirculationDocumentViewPage = lazy(
  () => import("src/pages/screen/PreservationOfRecord/CirculationDocument/View")
);

// Preservation of record
const PreservationOfRecordIndexPage = lazy(
  () =>
    import(
      "src/pages/screen/PreservationOfRecord/components/ListPreservationOfRecorDeadline"
    )
);

const PreservationOfRecordCreatePage = lazy(
  () => import("src/pages/screen/PreservationOfRecord/Create")
);

export default function usePreservationOfRecordRoutesV2() {
  const { t } = useTranslation("pages");

  return useMemo(
    (): MenuItem[] => [
      {
        name: t("preservation_of_record.menu_name"),
        path:
          routePaths.Screen.PreservationOfRecord.DocumentDeadline.File.Index,
        hideChildrenInMenu: true,
        routes: [
          {
            name: "",
            path:
              routePaths.Screen.PreservationOfRecord.DocumentDeadline.File
                .Index,
            routes: [
              // Circulation document
              {
                name: t("preservation_of_record.circulation_document.index"),
                path:
                  routePaths.Screen.PreservationOfRecord.Organization.File
                    .CirculationDocument.Index,
                render: (props) => {
                  return (
                    <CirculationDocumentIndexPage
                      {...props}
                      ownerType={PreservationOfRecordOwnerType.Organization}
                    />
                  );
                },
                permissions:
                  Permissions.Permission.CirculationDocument.ViewList,
              },
              {
                name: t("preservation_of_record.circulation_document.create"),
                path:
                  routePaths.Screen.PreservationOfRecord.Organization.File
                    .CirculationDocument.Create,
                render: (props) => {
                  return (
                    <CirculationDocumentCreatePage
                      {...props}
                      ownerType={PreservationOfRecordOwnerType.Organization}
                    />
                  );
                },
                permissions: Permissions.Permission.CirculationDocument.Create,
              },
              {
                name: t("preservation_of_record.circulation_document.view"),
                path:
                  routePaths.Screen.PreservationOfRecord.Organization.File
                    .CirculationDocument.View,
                render: (props) => (
                  <CirculationDocumentViewPage
                    {...props}
                    ownerType={PreservationOfRecordOwnerType.Organization}
                  />
                ),
                permissions: Permissions.Permission.CirculationDocument.View,
              },
              // Main
              {
                name: t("preservation_of_record.menu_name"),
                path:
                  routePaths.Screen.PreservationOfRecord.DocumentDeadline.File
                    .Index,
                render: (props) => (
                  <PreservationOfRecordIndexPage
                    {...props}
                    ownerType={PreservationOfRecordOwnerType.Organization}
                  />
                ),
                permissions:
                  Permissions.Permission.PreservationOfRecord.ViewList,
              },
              {
                name: t("preservation_of_record.create.page_title"),
                path:
                  routePaths.Screen.PreservationOfRecord.DocumentDeadline.File
                    .Create,
                render: (props) => (
                  <PreservationOfRecordCreatePage
                    {...props}
                    ownerType={PreservationOfRecordOwnerType.Organization}
                  />
                ),
                permissions: Permissions.Permission.PreservationOfRecord.Create,
              },
              {
                name: t("preservation_of_record.detail.page_title", {
                  action: "Chỉnh sửa",
                }),
                path:
                  routePaths.Screen.PreservationOfRecord.DocumentDeadline.File
                    .Detail,
                render: (props) => (
                  <PreservationOfRecordCreatePage
                    {...props}
                    ownerType={PreservationOfRecordOwnerType.Organization}
                  />
                ),
                permissions: [
                  Permissions.Permission.PreservationOfRecord.Edit,
                  Permissions.Permission.PreservationOfRecord.View,
                ],
              },
            ],
          },
        ],
      },
      // {
      //   name: t("preservation_of_record.family"),
      //   path: routePaths.Screen.PreservationOfRecord.Family.Index,
      //   hideChildrenInMenu: true,
      //   routes: [
      //     {
      //       name: "",
      //       path: routePaths.Screen.PreservationOfRecord.Family.Index,
      //       routes: [
      //         // Circulation document
      //         {
      //           name: t("preservation_of_record.circulation_document.index"),
      //           path:
      //             routePaths.Screen.PreservationOfRecord.Family
      //               .CirculationDocument.Index,
      //           render: (props) => {
      //             return (
      //               <CirculationDocumentIndexPage
      //                 {...props}
      //                 ownerType={PreservationOfRecordOwnerType.Family}
      //               />
      //             );
      //           },
      //           permissions:
      //             Permissions.Permission.CirculationDocument.ViewList,
      //         },
      //         {
      //           name: t("preservation_of_record.circulation_document.create"),
      //           path:
      //             routePaths.Screen.PreservationOfRecord.Family
      //               .CirculationDocument.Create,
      //           render: (props) => {
      //             return (
      //               <CirculationDocumentCreatePage
      //                 {...props}
      //                 ownerType={PreservationOfRecordOwnerType.Family}
      //               />
      //             );
      //           },
      //           permissions: Permissions.Permission.CirculationDocument.Create,
      //         },
      //         {
      //           name: t("preservation_of_record.circulation_document.view"),
      //           path:
      //             routePaths.Screen.PreservationOfRecord.Family
      //               .CirculationDocument.View,
      //           render: (props) => (
      //             <CirculationDocumentViewPage
      //               {...props}
      //               ownerType={PreservationOfRecordOwnerType.Family}
      //             />
      //           ),
      //           permissions: Permissions.Permission.CirculationDocument.View,
      //         },
      //         // Main
      //         {
      //           name: t("preservation_of_record.menu_name"),
      //           path: routePaths.Screen.PreservationOfRecord.Family.Index,
      //           render: (props) => (
      //             <PreservationOfRecordIndexPage
      //               {...props}
      //               ownerType={PreservationOfRecordOwnerType.Family}
      //             />
      //           ),
      //           permissions:
      //             Permissions.Permission.PreservationOfRecord.ViewList,
      //         },
      //         {
      //           name: t("preservation_of_record.create.page_title"),
      //           path: routePaths.Screen.PreservationOfRecord.Family.Create,
      //           render: (props) => (
      //             <PreservationOfRecordCreatePage
      //               {...props}
      //               ownerType={PreservationOfRecordOwnerType.Family}
      //             />
      //           ),
      //           permissions: Permissions.Permission.PreservationOfRecord.Create,
      //         },
      //         {
      //           name: t("preservation_of_record.detail.page_title", {
      //             action: "Chỉnh sửa",
      //           }),
      //           path: routePaths.Screen.PreservationOfRecord.Family.Detail,
      //           render: (props) => (
      //             <PreservationOfRecordCreatePage
      //               {...props}
      //               ownerType={PreservationOfRecordOwnerType.Family}
      //             />
      //           ),
      //           permissions: [
      //             Permissions.Permission.PreservationOfRecord.Edit,
      //             Permissions.Permission.PreservationOfRecord.View,
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
    []
  );
}
