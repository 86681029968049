/* eslint-disable prettier/prettier */
import env from "src/core/env";
import { Repository } from "src/core/Repository";
import { AllParentFieldsResponse } from "src/models/directorys/directory";
import {
  createFinalReportRequest,
  getAllFinalReportRequest,
  updateFinalReportRequest,
} from "src/models/phongs/FinalReport";
import { DeleteRequest } from "src/models/Request";

class FinalReportRepository extends Repository {
  protected basePath = "api/cms-service/final-report";

  constructor() {
    super(env.API_CMS_URL);
  }

  getAll(params: getAllFinalReportRequest) {
    return this.request<AllParentFieldsResponse>({
      method: "GET",
      url: "/all",
      params,
    });
  }

  createFinalReport(params: createFinalReportRequest) {
    return this.request({
      method: "POST",
      url: "",
      data: params,
    });
  }

  deleteFinalReport(param: DeleteRequest) {
    return this.request({
      method: "DELETE",
      url: `/${param.id}`,
    });
  }

  updateFinalReport(params: updateFinalReportRequest) {
    return this.request({
      method: "PUT",
      url: "",
      data: params,
    });
  }

  exportFinalReport(id: string) {
    return this.request({
      method: "POST",
      url: `/${id}/export`,
    });
  }
}
export const finalReportRepository = new FinalReportRepository();
